import { Platform, StyleSheet } from 'react-native'
import { black } from 'react-native-paper/lib/typescript/styles/colors'
import { theme } from '../../core/theme'
import cpebColors from '../../utils/colors'

export const itemStyles = StyleSheet.create({
  artTitle: {
    color: '#000000',
    fontSize: 40,
    fontFamily: "'Montserrat', sans-serif",
    fontWeight: 'bold',
    marginVertical: 5,
    marginHorizontal: 5
  },
  artCaption: {
    color: '#000000',
    fontSize: 20,
    fontFamily: "'Montserrat', sans-serif",
    fontWeight: 'bold',
    marginHorizontal: 5
  },
  artLabel: {
    color: '#000000',
    fontSize: 14,
    fontFamily: "'Montserrat', sans-serif",
    marginHorizontal: 5,
    textTransform: 'uppercase'
  },
  artSendLabel: {
    color: '#000000',
    fontSize: 16,
    fontFamily: "'Montserrat', sans-serif",
    textTransform: 'uppercase',
  },
  artSendValue: {
    color: '#000000',
    fontSize: 40,
    fontFamily: "'Montserrat', sans-serif",
    fontWeight: 'bold',
    marginVertical: 5,
  },
  artAcknowledgement: {
    color: '#F7931E',
    fontSize: 36,
    fontFamily: "'Montserrat', sans-serif",
    fontWeight: 'bold',
    textTransform: 'uppercase',
    textAlign: 'center'
  },
  artSuccessMsg: {
    color: '#000000',
    fontSize: 16,
    fontFamily: "'Montserrat', sans-serif",
    marginHorizontal: 5,
    textTransform: 'uppercase'
  },
  artBtn: {
    borderWidth: 1,
    borderColor: '#949494',
    borderRadius: 5,
    padding: 5,
    textAlign: 'center',
    marginVertical: 5
  },
  artText: {
    borderWidth: 1,
    borderColor: '#949494',
    borderRadius: 5,
    padding: 5,
    width: '70%',
    textAlign: 'center',
    marginVertical: 5
  },
  errorMessage: {
    padding: 5,
    borderColor: 'red',
    borderWidth: 2,
    backgroundColor: '#FF3030',
    color: 'white',
    alignItems: 'center',
    width: '70%',
    marginTop: 20
  },
})

export const modalCustomStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  }
}

