import React from 'react'
import {
  TouchableOpacity,
  View,
  ToastAndroid,
  Alert,
  Platform,
  Text,
} from 'react-native'
import {StackNavigationProp} from '@react-navigation/stack'
import {loginStyles as styles} from './styles'
import {RouteName} from '../../navigation/types'

import {AxiosPromise} from 'axios'

import {FormattedMessage} from 'react-intl'
import {compose, Dispatch} from 'redux'
import {connect} from 'react-redux'
import {ScrollView} from 'react-native-gesture-handler'
import {CLEAR_USER, loginUser} from '../../reducers/User/user'
import {clearUserRegister} from '../../reducers/User/register'
import cpebColors from '../../utils/colors'

import {Auth} from '@aws-amplify/auth'
import {ReduxAppState} from '../../utils/types'

import {BackgroundTemplate, Form, Screen} from '../../ReusableComponents'
import * as yup from 'yup'

export interface LoginPageProps {
  navigation: StackNavigationProp<any>
}

export interface StateProps {
  isLoading: boolean
  isRegistered: boolean
  user: any
  isRegisterSuccess: boolean
  isError: boolean
}

export interface DispatchProps {
  loginUser: (username: string, password: string) => any
  onClearRegister: () => any
  onClearUser: () => any
}

export interface UserDetailState {
  value: any
  emailError: string
  passwordError: string
  rememberMe: boolean
}

export type UserLoginDetailsProps = LoginPageProps & StateProps & DispatchProps

const FormSchema = yup.object({
  username: yup
    .string()
    .required('Username is required')
    .matches(/^\s*\S[\s\S]*$/, 'Username is required'),
  password: yup
    .string()
    .required('Password is required')
    .matches(/^(?!\s+$).*/, 'Password is required'),
})
class LoginPage extends React.Component<
  UserLoginDetailsProps,
  UserDetailState
> {
  _isMounted = false
  constructor(props: UserLoginDetailsProps) {
    super(props)
    this.state = {
      value: {},
      emailError: '',
      passwordError: '',
      rememberMe: false,
    }
  }

  componentDidMount() {
    this.props.onClearUser()
  }

  onChangeValue = (key: string, value: string) => {
    const _value = {...this.state.value}
    _value[key] = value
    this.setState({value: _value})
  }

  onLoginPressed = (data: any) => {
    this.props
      .loginUser(data.username, data.password)
      .then(() => {
        // skip
      })
      .catch(() => {
        // error
      })
  }

  componentWillUnmount() {
    this.props.onClearRegister()
  }

  render() {
    return (
      <BackgroundTemplate>
        <Form
          mode="onSubmit"
          formSchema={FormSchema}
          formStyle={styles.formStyle}
          onSubmit={this.onLoginPressed}>
          {this.props.isRegisterSuccess && (
            <View
              style={{
                backgroundColor: '#55AA55',
                padding: 10,
                maxWidth: 400,
                width: '100%',
                marginBottom: 10,
                marginLeft: 20,
              }}>
              <Text style={{color: '#ffff', textAlign: 'center'}}>
                <FormattedMessage id="Login.msg.successRegister" />
              </Text>
            </View>
          )}
          {this.props.isError && (
            <View
              style={{
                backgroundColor: '#FF4B4B',
                padding: 10,
                maxWidth: 400,
                width: '100%',
                marginBottom: 10,
                marginLeft: 20,
              }}>
              <Text style={{color: 'white', textAlign: 'center'}}>
                Username or password is incorrect.
              </Text>
            </View>
          )}
          <Screen minWidth={450}>
            <Form.Field
              style={styles.input}
              formGroupStyle={styles.fieldFormGroupStyle}
              labelStyle={styles.fieldLabelStyle}
              name="username"
              type="textinput"
              placeholder="Username"
              rules={(value: any) => value.replace(/\s/g, '')}
            />
          </Screen>
          <Screen maxWidth={449}>
            <Form.Field
              style={[styles.input, {width: 220, alignSelf: 'center'}]}
              formGroupStyle={[
                styles.fieldFormGroupStyle,
                {alignSelf: 'center'},
              ]}
              labelStyle={styles.fieldLabelStyle}
              name="username"
              type="textinput"
              placeholder="Username"
              rules={(value: any) => value.replace(/\s/g, '')}
            />
          </Screen>
          <Screen minWidth={450}>
            <Form.Field
              style={styles.input}
              formGroupStyle={styles.fieldFormGroupStyle}
              labelStyle={styles.fieldLabelStyle}
              name="password"
              type="password"
              placeholder="Password"
              eyeIconSize={24}
            />
          </Screen>
          <Screen maxWidth={449}>
            <Form.Field
              style={[styles.input, {width: 220, alignSelf: 'center'}]}
              formGroupStyle={[
                styles.fieldFormGroupStyle,
                {alignSelf: 'center'},
              ]}
              labelStyle={styles.fieldLabelStyle}
              name="password"
              type="password"
              placeholder="Password"
              eyeIconSize={24}
            />
          </Screen>
          <Form.Submit
            loading={this.props.isLoading}
            onPress={this.onLoginPressed}
            title="LOG IN"
            style={styles.loginBtn}
            labelStyle={styles.textWhite}
          />
        </Form>
      </BackgroundTemplate>
      // <ScrollView style={styles.scrollView}>
      //   <View style={[styles.loginContainer, Platform.OS === 'web' && styles.loginContainerShadow]}>
      //     {/* <Logo /> */}
      //     <Text style={styles.title}><FormattedMessage id="Login.msg.title" /></Text>
      //     {this.props.isRegisterSuccess && (
      //       <View style={{ backgroundColor: '#55AA55', padding: 10, maxWidth: 250, alignSelf: 'center', width: '100%', marginBottom: 10 }}>
      //         <Text style={{ color: '#ffff', textAlign: 'center' }}><FormattedMessage id='Login.msg.successRegister' /></Text>
      //       </View>
      //     )}
      //     <Form formSchema={FormSchema} formStyle={styles.formGroup} onSubmit={this.onLoginPressed}>
      //       <Form.Field style={[styles.input, { marginBottom: 5 }]} required name='username' type='textinput' placeholder='Username' />
      //       <Form.Field style={styles.input} required name='password' type='password' placeholder='Password' eyeIconSize={24} />
      //       <View style={styles.checkboxContainer}>
      //         {Platform.OS === 'web' ? (
      //           <React.Fragment>
      //             <input type="checkbox"
      //               key={Math.random()}
      //               defaultChecked={this.state.rememberMe}
      //             />
      //             <Text style={styles.linkText}><FormattedMessage id="Login.msg.rememberMe" /></Text>
      //           </React.Fragment>) :
      //           <CheckBox
      //             style={styles.checkbox}
      //             checkBoxColor={cpebColors.labelColor}
      //             onClick={() => {
      //                 // empty
      //              }}
      //             isChecked={this.state.rememberMe}
      //             rightText={'Remember me'}
      //           />
      //         }
      //       </View>
      //       {this.props.isError && (
      //         <View style={{ backgroundColor: 'red', padding: 10, maxWidth: 250, alignSelf: 'center', width: '100%', marginBottom: 10 }}>
      //           <Text style={{ color: '#ffff', textAlign: 'center' }}>Incorrect username or password.</Text>
      //         </View>
      //       )}
      //       <Form.Submit style={styles.button} onPress={this.onLoginPressed} loading={this.props.isLoading} title='Login' mode='contained' />
      //     </Form>
      //   </View>
      // </ScrollView>
    )
  }
}

const mapStateToProps = (state: ReduxAppState) => {
  return {
    user: state.api?.user?.authUser,
    isRegisterSuccess: state.api?.user?.registerUser?.statusText === 'success',
    isLoading: state.api?.user.authUser.loading,
    isError: state.api?.user.authUser.statusText === 'error',
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
  loginUser: (username: string, password: string) =>
    loginUser(dispatch, {username, password}),
  onClearUser: () => dispatch({type: CLEAR_USER}),
  onClearRegister: () => dispatch(clearUserRegister()),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(compose(LoginPage as any))
