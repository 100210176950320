import React, { ReactChildren, ReactElement } from 'react'
import { StyleProp, View, ViewStyle } from 'react-native'
import { ScrollView } from 'react-native-gesture-handler'

import {
    widthPercentageToDP as wp,
    heightPercentageToDP as hp,
    listenOrientationChange as loc,
    removeOrientationListener as rol
} from 'react-native-responsive-screen'

export interface ResponsiveViewProps {
    height?: string | number
    width?: string | number
    viewStyle?: StyleProp<ViewStyle>
    isScroll?: boolean
    children: any
}

class ResponsiveView extends React.Component<ResponsiveViewProps, {}> {
    constructor(props: any) {
        super(props)
    }

    componentDidMount() {
        loc(this)
    }

    componentWillUnMount() {
        rol()
    }

    render() {
        const { height, width, viewStyle, children, isScroll } = this.props
        const ResponsiveViewParent = isScroll ? ScrollView : View
        return (
            <ResponsiveViewParent style={[{ height: height && hp(height), width: width && wp(width) }, viewStyle]}>
                {children}
            </ResponsiveViewParent>
        )
    }
}

export default ResponsiveView