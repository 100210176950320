import resetPasswordReducer, { resetPasswordInitialState, ResetPasswordState } from './resetPassword'

import { combineReducers } from 'redux'

export const resetPwdInitialState: ResetPwdInitialState = {
    resetPassword: resetPasswordInitialState
}

export interface ResetPwdInitialState {
    resetPassword: ResetPasswordState
}

const resetPwdReducer = combineReducers({
    resetPassword: resetPasswordReducer
})

export default resetPwdReducer

