import { StyleSheet } from 'react-native'

export const walletDetailStyles = StyleSheet.create({
    title: {
        textTransform: 'uppercase',
        color: 'black',
        fontSize: 16,
        fontFamily: "'Montserrat', sans-serif",
        marginBottom: 10,
        fontWeight: 'bold'
    },
    activeTitle: {
        textTransform: 'uppercase',
        color: '#F7931E',
        fontSize: 16,
        fontFamily: "'Montserrat', sans-serif",
        marginBottom: 10,
        marginHorizontal: 20
    },
    titleContainer: {
        flexDirection: 'row',
        flexWrap: 'wrap'
        // width: 250,
        // borderRightWidth: 3,
        // borderColor: '#9D9D9D',
        // height: '90%'
    },
    demoContainer: {
        marginHorizontal: 20
    },
    iconStyle: {
        height: 40,
        width: 40,
        tintColor: '#fff',
        marginBottom: 15
    },
    paymentButton: {
        backgroundColor: '#F7931E',
        padding: 10
    },
    border: {
        borderColor: 'black',
        borderWidth: 1
    },
    formStyle: {
        position: 'relative',
        padding: 10,
        borderColor: 'black',
        borderWidth: 1,
        // width: 500,
        paddingHorizontal: 50,
        marginHorizontal: 20,
        marginBottom: 30
    },
    alertMessage: {
        padding: 10,
        borderWidth: 2,
        color: 'white',
        alignItems: 'center',
        marginTop: 20,
        marginBottom: 15
    },
})