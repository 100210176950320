import React, { useState } from 'react'
import { View, StyleSheet, Text, TextInput as Input, Pressable, Image, TouchableOpacity } from 'react-native'
import { ActivityIndicator, HelperText, IconButton, Paragraph, TextInput } from 'react-native-paper'
import { modalCustomStyles, itemStyles as styles } from './styles'
import Modal from 'react-modal'
import { Form } from '../../ReusableComponents'
import { ReduxAppState } from '../../utils/types'
import { compose, Dispatch } from 'redux'
import { connect } from 'react-redux'
import { FormattedMessage, FormattedNumber, injectIntl } from 'react-intl'
import { Items } from '../../reducers/Items'
import { RouteName } from '../../navigation/types'
import { NavigationStackProp } from 'react-navigation-stack'
import * as _ from 'lodash'
import { User } from '../../reducers/User'
import { buyItem } from '../../reducers/Items/buyItem'
import { getFunds } from '../../reducers/Wallet/getFunds'
import { Wallet } from '../../reducers/Wallet'

export interface ComponentProps {
  navigation: NavigationStackProp<any>
  key: string
  showModal: boolean
  isDetailsPage: boolean
  closeModal: () => any
  getItemInfo: (id: string) => void,
  getFunds: (id: string) => any
  buyItem: (data: any, id: string) => any
  intl: any
}

export interface StateProps {
  currentUser?: User
  itemInfo: Items,
  funds: Wallet,
  isLoading: boolean
  imageUrl: string,
  errorMessage: string,
  isLoadingFunds: boolean
}

export interface DispatchProps {
}

export interface BuyModalState {
  isNextPage: boolean
  isSubmit: boolean
  isSuccess: boolean,
  isError: boolean,
  isFundError: boolean,
  noOfShares: string,
  totalValue: number,
  valuePerShare: number,
  networkFee: number
  errorNumberOfShare: string
}

export type BuyModalProps = ComponentProps & StateProps & DispatchProps

Modal.setAppElement('#react-root');

class BuyModalComponent extends React.Component<BuyModalProps, BuyModalState> {
  constructor(props: BuyModalProps) {
    super(props)
    this.state = {
      isSubmit: false,
      isNextPage: false,
      isSuccess: false,
      isError: false,
      isFundError: false,
      noOfShares: '',
      valuePerShare: 0,
      totalValue: 0,
      networkFee: 10,
      errorNumberOfShare: ''
    }
  }

  componentDidMount() {
    this.setState({ valuePerShare: parseInt(this.props.itemInfo?.listedValue, 10) / 10 })
  }

  onSubmit = () => {
    const _data = {
      userId: this.props.currentUser?.id,
      email: this.props.currentUser?.email,
      numberOfShare: Number(this.state.noOfShares),
    }

    this.props.buyItem(_data, this.props.itemInfo?.id)
      .then(() => {
        this.setState({
          isNextPage: false,
          isSubmit: true,
          isSuccess: true
        })
      }).catch(() => {
        this.setState({ isError: true })
      })
  }

  goToListing = () => {
    this.setState({
      isNextPage: false,
      isSubmit: false,
      isSuccess: false
    })

    this.props.closeModal()
    if (this.props.isDetailsPage) {
      this.props.navigation.push(RouteName.ListPage)
    }
  }

  getDetailsPage = (id: string) => {
    this.setState({
      isNextPage: false,
      isSubmit: false,
      isSuccess: false
    })

    if (this.props.isDetailsPage) {
      this.props.closeModal()
    } else {
      this.props.getItemInfo(id)
    }
  }

  onChangeShares = (_value: string) => {
    const _itemInfo = this.props.itemInfo
    const value = _value.replace(/,/g, '')
    if (value === '' || Number(value) > 0) {
      if (/^\d+$/.test(value)) {
        this.setState({ noOfShares: value, errorNumberOfShare: '' })
      } else if (value === '') {
        this.setState({ noOfShares: value, errorNumberOfShare: 'Invalid no. of share' })
      } else {
        this.setState({ errorNumberOfShare: /^\d+$/.test(this.state.noOfShares) ? '' : 'Invalid no. of share' })
      }
      _.debounce(() => {
        const totalValue = this.props.itemInfo?.valuePerShare * parseInt(this.state.noOfShares === '' ? '0' : value, 10)
        this.setState({ totalValue })
      }, 300)()
    } else if (this.state.noOfShares === '') {
      this.setState({ errorNumberOfShare: 'Invalid no. of share' })
    }
  }

  getNextPage = () => {
    if (this.state.noOfShares === '' || this.state.noOfShares === '0') {
      this.setState({ errorNumberOfShare: 'Invalid no. of share' })
      return
    }
    const cognitoId = this.props.currentUser?.id
    this.props.getFunds(cognitoId!)
      .then(() => {
        this.setState({ isNextPage: true })
      }).catch(() => {
        this.setState({ isFundError: true })
      })
  }

  onClose = () => {
    this.setState({
      valuePerShare: 0,
      totalValue: 0,
      isFundError: false,
      isNextPage: false,
      isError: false,
      noOfShares: '',
      errorNumberOfShare: ''
    })
    this.props.closeModal()
  }

  render() {
    const { isNextPage, isSubmit, isSuccess, isFundError, valuePerShare, totalValue, networkFee, noOfShares } = this.state
    const { itemInfo, imageUrl, showModal, currentUser, isLoading, funds, isLoadingFunds, closeModal } = this.props
    return (
      <Modal
        isOpen={showModal}
        onRequestClose={this.onClose}
        style={modalCustomStyles}
      >
        <View style={{ position: 'absolute', right: 0, top: 0, zIndex: 1 }}>
          <IconButton
            icon='close'
            size={20}
            onPress={this.onClose}
          />
        </View>
        {!isSubmit ? (
          <React.Fragment>
            {this.state.isError && <View style={{ alignItems: 'center' }}><View style={styles.errorMessage} ><Text style={{ color: 'white', fontSize: 12, fontFamily: "'Montserrat', sans-serif" }}>{this.props.errorMessage || <FormattedMessage id='Buy.msg.somethingWentWrong' />}</Text></View></View>}
            {isFundError && <View style={{ alignItems: 'center' }}><View style={styles.errorMessage} ><Text style={{ color: 'white', fontSize: 12, fontFamily: "'Montserrat', sans-serif" }}>Unable to retrieve wallet</Text></View></View>}
            {/* Title */}
            <View style={{ marginLeft: 30, marginTop: 10, marginBottom: 5, width: '50%' }} >
              <Text style={styles.artTitle}>{`${itemInfo?.title}`}</Text>
              <Text style={styles.artCaption}>{`${itemInfo?.artist}`}</Text>
              <Text style={styles.artCaption}>{`${itemInfo?.year}`}</Text>
            </View>
            {/* Item Details*/}
            <View style={{ width: 500, height: 400 }}>
              <View style={{ flexDirection: 'row', justifyContent: 'space-evenly' }}>
                {/* First Column Item Details*/}
                <View style={{ flexWrap: 'nowrap', flex: 1, marginTop: 10 }}>
                  {!isNextPage ? (
                    <View style={{ alignItems: 'center' }}>
                      <TextInput
                        style={{ fontSize: 12, height: 40, width: '70%', textTransform: 'uppercase' }}
                        label="No of. Shares"
                        value={this.props.intl.formatNumber(isNaN(Number(this.state.noOfShares)) ? 0 : Number(this.state.noOfShares), { style: 'decimal' })}
                        onChangeText={(value) => this.onChangeShares(value)}
                        mode={'outlined'}
                        keyboardType='numeric'
                        autoComplete={false}
                      />
                      {this.state.errorNumberOfShare && <Text style={{ fontSize: 14, color: 'red' }}>{this.state.errorNumberOfShare}</Text>}
                      <Paragraph style={{ fontSize: 14, color: 'black', marginBottom: 40 }}><Text numberOfLines={1} style={{ fontWeight: 'bold' }}>{itemInfo?.availableShare}</Text>{' share/s remaining'}</Paragraph>
                      <Text style={[styles.artLabel, styles.artText]}><FormattedMessage id='Buy.msg.valuePerShare' /></Text>
                      <Text style={[styles.artCaption, { marginVertical: 15 }]}><FormattedNumber value={Number(process.env.VALUE_PER_SHARE)} format={itemInfo?.currency ?? 'USD'} /></Text>
                      <Text style={[styles.artLabel, styles.artText]}><FormattedMessage id='Buy.msg.totalValue' /></Text>
                      <Text style={[styles.artCaption, { marginVertical: 15 }]}><FormattedNumber style='currency' format={itemInfo?.currency ?? 'USD'} value={totalValue} /></Text>
                      <Form.Submit onPress={this.getNextPage} title='NEXT' style={{ backgroundColor: '#F7931E', padding: 10, borderRadius: 0, width: '70%', marginTop: 35 }} labelStyle={{ color: 'white', fontSize: 25 }} />
                    </View>
                  ) : (
                    <View style={{ marginHorizontal: 20, marginTop: 25 }}>
                      <Text style={[styles.artSendLabel, { width: '60%' }]}><FormattedMessage id='Buy.msg.availableBalance' /></Text>
                      {isLoadingFunds ?
                        <ActivityIndicator animating={true} color='#AFCD37' size='large' />
                        : <Text style={[styles.artSendLabel, { marginTop: 5, marginBottom: 20 }]}><FormattedNumber style='currency' format={itemInfo?.currency ?? 'USD'} value={funds.balance} /></Text>
                      }
                      {/* <Text style={[styles.artSendLabel, { marginTop: 15 }]}><FormattedMessage id='Buy.msg.amount' />{` $${totalValue}`}</Text> */}
                      {/* <Text style={[styles.artSendLabel, { marginVertical: 15 }]}><FormattedMessage id='Buy.msg.networkFee' />{` $${networkFee}`}</Text> */}
                      <Text style={[styles.artSendLabel]}><FormattedMessage id='Buy.msg.totalAmount' /></Text>
                      <Text style={[styles.artSendValue, { marginVertical: 15 }]}><FormattedNumber style='currency' format={itemInfo?.currency ?? 'USD'} value={totalValue} /></Text>
                      <Form.Submit onPress={this.onSubmit} loading={isLoading} title='SEND' style={{ backgroundColor: '#F7931E', padding: 10, borderRadius: 0, marginBottom: 10, width: '80%', marginTop: 10 }} labelStyle={{ color: 'white', fontSize: 25 }} />
                    </View>
                  )}
                </View>
                {/* Second Column Item Details*/}
                <View style={{ flexWrap: 'nowrap', flex: 1 }}>
                  <View style={{ marginBottom: 5 }}>
                    <Text style={[styles.artLabel, { marginVertical: 10 }]}><FormattedMessage id='Buy.msg.value' /></Text>
                    <Text style={styles.artCaption}>{`$${itemInfo?.listedValue}`}</Text>
                    <TouchableOpacity style={{ marginVertical: 10, marginHorizontal: 5 }} onPress={() => this.getDetailsPage(itemInfo.id)}>
                      <Text style={{ fontSize: 15, fontStyle: 'italic', color: '#3FDCFF' }}><FormattedMessage id='Buy.msg.details' /></Text>
                    </TouchableOpacity>
                  </View>
                  <View style={{ height: 270, width: 230 }}>
                    <Image
                      source={{ uri: imageUrl }}
                      style={{ height: '100%', width: '100%' }}
                    />
                  </View>
                </View>
              </View>
            </View>
          </React.Fragment>
        ) : isSuccess ? (
          <View style={{ width: 500, height: 500, alignItems: 'center' }}>
            <View style={{ marginVertical: 10 }}>
              <Text style={[styles.artAcknowledgement]}><FormattedMessage id='Buy.msg.successMsg' /></Text>
            </View>
            {/* Acknowledgement Image */}
            <View style={{ height: 220, width: 170 }}>
              <Image
                source={{ uri: imageUrl }}
                style={{ height: '100%', width: '100%' }}
              />
            </View>
            {/* Acknowledgement Message */}
            <View style={{ marginVertical: 20, alignItems: 'center' }}>
              <Text style={styles.artSuccessMsg}><FormattedMessage id='Buy.msg.congratsMsg' />{` ${currentUser?.name}!`}</Text>
              <Text style={styles.artSuccessMsg}><FormattedMessage id='Buy.msg.successMsg1' />{` ${noOfShares} `}<FormattedMessage id='Buy.msg.successMsg2' /></Text>
              <Text style={[styles.artSendValue, { marginVertical: 15 }]}>{`$${totalValue}`}</Text>
              <TouchableOpacity onPress={this.goToListing}>
                <Text style={[styles.artLabel, styles.artBtn]}><FormattedMessage id='Buy.msg.back' /></Text>
              </TouchableOpacity>
            </View>
          </View>
        ) : null}
      </Modal >
    )
  }
}

const mapStateToProps = (state: ReduxAppState) => {
  const _getItemInfo = state.api?.items?.getItemInfo
  const _authUserInfo = state.api?.user?.authUser
  const _buyItem = state.api?.items?.buyItem
  const _getFunds = state.api?.wallet?.getFunds
  return ({
    itemInfo: _getItemInfo.response,
    imageUrl: _getItemInfo.imageUrl,
    currentUser: _authUserInfo.response,
    isLoading: _buyItem.loading,
    errorMessage: _buyItem.error,
    funds: _getFunds.response,
    isLoadingFunds: _getFunds.loading
  })
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
  buyItem: (data: any, id: string) => buyItem(dispatch, data, id),
  getFunds: (id: string) => getFunds(dispatch, id)
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(compose(BuyModalComponent as any)))