export enum RouteName {
    LoginPage = 'Login',
    HomePage = 'Home',
    AuthPage = 'Auth',
    MainHomePage = 'MainHome',
    DemoPage = 'Demo',
    DashboardPage = 'Dashboard',
    ListingPage = 'Listing',
    ListPage = 'List',
    ProfilePage = 'Profile',
    WalletPage = 'Wallet',
    SettingsPage = 'Settings',
    SignUpPage = 'SignUp',
    ResetPwdPage = 'ResetPwd',
    ItemDetailPage = 'Details',
    PreviousOwnerPage = 'PreviousOwner',
    ActiveListPage = 'ActiveList',
    WelcomePage = 'Welcome'
}

export enum TabName {
    DetailsTab = 'Details',
    PreviousOwnerTab = 'PreviousOwner',
    ActiveListTab = 'ActiveList',
    UpcomingListTab = 'UpcomingList',
    ArtShareTab = 'ArtShare',
    TranHistoryTab = 'TranHistory',
    WalletTab = 'WalletDetail'
}