import React from 'react'

import { View, ScrollView, Text, TouchableOpacity, Dimensions, Image, Platform, Pressable } from 'react-native'
import { StackNavigationProp } from '@react-navigation/stack'

import { listingStyles as styles } from './style'
import { Button, IconButton, Subheading, Title } from 'react-native-paper'

import { connect } from 'react-redux'
import { compose, Dispatch } from 'redux'
import { FormattedMessage } from 'react-intl'
import { ReduxAppState } from '../../utils/types'
import SideNavContentHOC from '../../hoc/SidenavContent'
import HeaderContentHOC from '../../hoc/HeaderContent'
import { Form, ResponsiveView } from '../../ReusableComponents'
import Drag from './drag'

import * as yup from 'yup'

import axios, { AxiosRequestConfig } from 'axios'
import { generateRandomId } from '../../utils'
import { addItem, RESET_ADD_ITEM } from '../../reducers/Items/addItem'
import { RouteName } from '../../navigation/types'
import FooterHOC from '../../hoc/Footer'

export interface ComponentProps {
    navigation: StackNavigationProp<any>
    isRefreshing: boolean
}

export interface DispatchProps {
    addItem: (data: any) => any
    resetAddItem: () => void
}

export interface StateProps {
    isLoading: boolean
    isError: boolean
    isSaving: boolean
}

export interface ListingPageState {
    authenticationDetailsFileId: any
    factSheetFileId: any
    conditionReportId: any
    photoFileId: any
    image: any
    activeTab: string
    submitPurpose: string
    isError: boolean
    isImgCountError: boolean
    files: any,
    selectedFiles: any
}

export enum TabName {
    NewListing = 'NEW',
    Drafts = 'Drafts'
}

export type ListingPageProps = ComponentProps & DispatchProps & StateProps

const FormSchema = yup.object({
    title: yup.string().required('Title is required'),
    artist: yup.string().required('Artist is required'),
    listedValue: yup.string().required('Listed value is required'),
    year: yup.string().required('Year is required'),
    dmt: yup.string().required('Dimensions, Material and Technique is required'),
    shareOnSale: yup.string().required('Share on sale is required'),
    listingDate: yup.string()
        .required('Listing date is required')
        .matches(/^(?:(?:31(\/|-|\.)(?:0?[13578]|1[02]|(?:Jan|Mar|May|Jul|Aug|Oct|Dec)))\1|(?:(?:29|30)(\/|-|\.)(?:0?[1,3-9]|1[0-2]|(?:Jan|Mar|Apr|May|Jun|Jul|Aug|Sep|Oct|Nov|Dec))\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:29(\/|-|\.)(?:0?2|(?:Feb))\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0?[1-9]|1\d|2[0-8])(\/|-|\.)(?:(?:0?[1-9]|(?:Jan|Feb|Mar|Apr|May|Jun|Jul|Aug|Sep))|(?:1[0-2]|(?:Oct|Nov|Dec)))\4(?:(?:1[6-9]|[2-9]\d)?\d{2})$/, 'Not a valid date (dd/mm/yyyy)'),
    saleDate: yup.string()
        .required('Sale Date is required')
        .matches(/^(?:(?:31(\/|-|\.)(?:0?[13578]|1[02]|(?:Jan|Mar|May|Jul|Aug|Oct|Dec)))\1|(?:(?:29|30)(\/|-|\.)(?:0?[1,3-9]|1[0-2]|(?:Jan|Mar|Apr|May|Jun|Jul|Aug|Sep|Oct|Nov|Dec))\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:29(\/|-|\.)(?:0?2|(?:Feb))\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0?[1-9]|1\d|2[0-8])(\/|-|\.)(?:(?:0?[1-9]|(?:Jan|Feb|Mar|Apr|May|Jun|Jul|Aug|Sep))|(?:1[0-2]|(?:Oct|Nov|Dec)))\4(?:(?:1[6-9]|[2-9]\d)?\d{2})$/, 'Not a valid date (dd/mm/yyyy)'),
    displayLocation: yup.string().required('Display location is required'),
    description: yup.string().required('Description is required'),
    previousOwners: yup.string().optional(),
    // listedValue: yup.string().required('listed value is required'),
    // authenticationDetailsFileId: yup.mixed().notOneOf(['', false, undefined, null], 'Authentication Details is required'),
    factSheetFileId: yup.mixed().notOneOf(['', false, undefined, null], 'Fact Sheet is required'),
    conditionReportId: yup.mixed().notOneOf(['', false, undefined, null], 'Condition Report is required')
})

class ListingPage extends React.Component<ListingPageProps, ListingPageState> {
    file: any
    image: any
    selectedImages: any
    constructor(props: ListingPageProps) {
        super(props)
        this.state = {
            authenticationDetailsFileId: {},
            factSheetFileId: {},
            conditionReportId: {},
            photoFileId: {},
            image: undefined,
            submitPurpose: '',
            activeTab: TabName.NewListing,
            isError: false,
            isImgCountError: false,
            files: [],
            selectedFiles: []
        }
        this.file = React.createRef()
        this.selectedImages = React.createRef()
    }

    onSubmit = (data: any, isPosted: number = 1) => {
        this.setState({
            submitPurpose: isPosted ? 'POST' : 'SAVE'
        })
        if (!this.image) {
            return null
        }
        this.setState({ isError: false })
        const _data = {
            itemAttribute: {
                ...data,
                userId: this.props.user?.id,
                isPosted,
                numberOfShare: Math.floor(data.listedValue / Number(process.env.VALUE_PER_SHARE))
            },
        }
        const payload = { ..._data, ...this.state }
        this.props.addItem(payload)
            .then(() => {
                this.setState({
                    submitPurpose: ''
                })
                this.props.navigation.push(RouteName.ListPage)
                setTimeout(() => this.props.resetAddItem(), 10000)
            })
            .catch(() => {
                this.setState({ isError: true })
            })
    }

    onCheckImage = (isPosted: number) => {
        this.setState({
            submitPurpose: isPosted ? 'POST' : 'SAVE'
        })
    }

    onFileChange = (file: any, name: string) => {
        const reader = new FileReader()
        reader.onload = (e) => {
            if (name === 'photoFileId') {
                this.image = e.target?.result
            }

            this.setState({
                [name]: {
                    fileName: file.name,
                    decodedFile: e.target?.result.split(',')[1],
                    type: file.type
                }
            })
        }
        reader.readAsDataURL(file)
    }

    getBase64(file: any, cb: any) {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
            cb(reader.result)
        };
        reader.onerror = (error) => {
            console.log('Error: ', error);
        };
    }

    multipleFileSelectedHandler = (e: any) => {
        this.setState({ isImgCountError: false })
        const imageCount = e.target.files.length + this.state.selectedFiles.length
        if (imageCount >= 11) {
            this.setState({ isImgCountError: true })
        } else {
            if (e.target.files) {
                const filesArray = Array.from(e.target.files).map((image: any) => {
                    this.getBase64(image, (result: any) => {
                        this.setState({
                            files: [...this.state.files,
                            {
                                fileName: image?.name,
                                decodedFile: result.split(',')[1],
                                type: image?.type
                            }]
                        })
                    });
                    return URL.createObjectURL(image)
                })

                this.setState({ selectedFiles: this.state.selectedFiles.concat(filesArray) })
            }
        }

    }

    renderPhotos = (source: any) => {
        return source.map((photo: any) => {
            return (
                <View style={{ borderStyle: 'dashed', borderWidth: 1, borderColor: '#A1A1A1', marginVertical: 10, marginRight: 10 }}>
                    <Image style={{ width: 105, height: 100 }} source={{ uri: photo }} resizeMode='stretch' key={photo} />
                </View>
            )
        });
    };

    onChangeActiveTab = (item: string) => {
        this.setState({
            activeTab: item
        })
    }

    render() {
        return (
            <ScrollView style={styles.demoContainer}>
                {/* Overview */}
                <ResponsiveView width={'80%'}>
                    <View style={{ flexDirection: 'row' }}>
                        <TouchableOpacity onPress={() => this.onChangeActiveTab(TabName.NewListing)}>
                            <Title numberOfLines={1}
                                style={this.state.activeTab === TabName.NewListing ? styles.activeTitle : styles.title}>
                                New Listing
                            </Title>
                        </TouchableOpacity>
                        <TouchableOpacity onPress={() => this.onChangeActiveTab(TabName.Drafts)}>
                            <Title numberOfLines={1}
                                style={this.state.activeTab === TabName.Drafts ? styles.activeTitle : styles.title}>
                                Drafts
                            </Title>
                        </TouchableOpacity>
                    </View>
                    {this.state.isError && <View style={styles.errorMessage} ><Text style={{ color: 'white', fontSize: 25, fontFamily: "'Montserrat', sans-serif" }}>Something went wrong. Please try again</Text></View>}
                    <Form formSchema={FormSchema} formStyle={{ marginBottom: 50 }}>
                        <Form.Field name='title' placeholder='Title' type='textinput' style={[styles.input, { fontSize: 45 }]} placeholderTextColor='#B3B3B3' />
                        <Form.Field name='artist' placeholder='Artist' type='textinput' style={[styles.input, { width: 300 }]} placeholderTextColor='#B3B3B3' />
                        <View style={{ flexDirection: 'row', justifyContent: 'flex-start', flexWrap: 'wrap', marginLeft: 20 }}>
                            {/* <Pressable style={{ position: 'relative', padding: '23%' }} onPress={() => this.file.current?.click()}> */}
                            <View style={{ flexDirection: 'column', width: '50%' }}>
                                <Drag handleDrop={(file: any) => this.onFileChange(file, 'photoFileId')}>
                                    <Pressable onPress={() => this.file.current?.click()}
                                        style={{ borderColor: !this.image && this.state.submitPurpose !== '' ? 'red' : 'black', position: 'absolute', top: 0, right: 0, left: 0, bottom: 0, justifyContent: 'center', borderWidth: 1 }}
                                    >
                                        {this.image ? (
                                            <Image style={{ width: '100%', height: '100%' }} source={{ uri: this.image }} resizeMode='stretch' />
                                        ) : (
                                            <View style={{ alignSelf: 'center' }}>
                                                <IconButton
                                                    icon='cloud-upload-outline'
                                                    size={200}
                                                    style={{ height: 150, marginBottom: 10 }}
                                                />
                                                <Subheading style={{ textAlign: 'center', fontSize: 25 }}>Upload or Drag Photo Here</Subheading>
                                            </View>
                                        )}
                                    </Pressable>
                                </Drag>
                                {this.state.isImgCountError && <View style={styles.imageErrorMsg} ><Text style={{ color: 'white', fontSize: 15, fontFamily: "'Montserrat', sans-serif" }}>The maximum number of images that can be uploaded is 10</Text></View>}
                                <View style={{ flexDirection: 'row', flexWrap: 'wrap' }}>
                                    {this.renderPhotos(this.state.selectedFiles)}
                                    {this.state.selectedFiles.length < 10 &&
                                        <TouchableOpacity onPress={() => this.selectedImages.current?.click()} style={{ backgroundColor: '#C2C2C2', padding: 15, borderRadius: 0, marginRight: 10, marginVertical: 10, borderWidth: 1, borderStyle: 'dashed', borderColor: '#A1A1A1', zIndex: 0 }} >
                                            <IconButton icon='plus' size={40} color='white' onPress={() => this.selectedImages.current?.click()} />
                                        </TouchableOpacity>}
                                </View>
                            </View>

                            {/* </Pressable> */}
                            <View style={{ width: '50%' }}>
                                {Platform.OS === 'web' && <input type="file" name="file" onChange={(file: any) => this.onFileChange(file?.target?.files[0] ?? undefined, 'photoFileId')} ref={this.file} style={{ position: 'fixed', top: -100, display: 'none' }} />}
                                {Platform.OS === 'web' && <input type="file" name="selectedImages" multiple onChange={this.multipleFileSelectedHandler} ref={this.selectedImages} style={{ position: 'fixed', top: -100, display: 'none' }} />}
                                <Form.Field name='listedValue' placeholder='Listed Value' type='number' style={styles.input} placeholderTextColor='#B3B3B3' />
                                <Form.Field name='year' placeholder='Year' type='date' style={styles.input} placeholderTextColor='#B3B3B3' dateFormat='YYYY' />
                                <Form.Field name='dmt' placeholder='Dimensions, Material and Technique' type='textinput' style={styles.input} placeholderTextColor='#B3B3B3' />
                                <Form.Field name='listingDate' placeholder='Listing Date (DD/MM/YYYY)' type='date' style={styles.input} placeholderTextColor='#B3B3B3' />
                                <Form.Field name='saleDate' placeholder='Sale Date (DD/MM/YYYY)' type='date' style={styles.input} placeholderTextColor='#B3B3B3' />
                                <Form.Field name='shareOnSale' placeholder='Share on Sale %' type='textinput' style={styles.input} placeholderTextColor='#B3B3B3' />
                                <Form.Field name='displayLocation' placeholder='Display Location' type='textinput' style={styles.input} placeholderTextColor='#B3B3B3' />
                                <Form.Field name='description' placeholder='Description' type='textinput' style={[styles.input, { height: 200 }]} placeholderTextColor='#B3B3B3' multiline />
                                <Form.Field name='previousOwners' placeholder='Previous Owners' type='textinput' style={[styles.input, { height: 200 }]} placeholderTextColor='#B3B3B3' multiline />
                                {/* <Form.Upload onChangeValue={(data) => this.onFileChange(data, 'photoFileId')} name='photoFileId' buttonStyles={styles.uploadButton} buttonLabelStyle={styles.buttonLabel} buttonLabel='Photo' /> */}
                                <Form.Upload onChangeValue={(data) => this.onFileChange(data, 'authenticationDetailsFileId')} name='authenticationDetailsFileId' buttonStyles={styles.uploadButton} buttonLabelStyle={styles.buttonLabel} buttonLabel='Upload Authentication Details' />
                                <Form.Upload onChangeValue={(data) => this.onFileChange(data, 'conditionReportId')} name='conditionReportId' buttonStyles={styles.uploadButton} buttonLabelStyle={styles.buttonLabel} buttonLabel='Upload Condition Report' />
                                <Form.Upload onChangeValue={(data) => this.onFileChange(data, 'factSheetFileId')} name='factSheetFileId' buttonStyles={styles.uploadButton} buttonLabelStyle={styles.buttonLabel} buttonLabel='Upload Fact Sheet' />
                                <View style={{ flexDirection: 'row', marginLeft: 20, flexWrap: 'wrap' }}>
                                    <Form.Submit optionalFunc={() => this.onCheckImage(1)} loading={this.props.isSaving && this.state.submitPurpose === 'POST'} disabled={(this.props.isSaving && this.state.submitPurpose === 'POST')} onPress={(data: any) => this.onSubmit(data, 1)} title='POST' style={{ backgroundColor: '#F7931E', padding: 10, borderRadius: 0, marginRight: 10, marginBottom: 10 }} labelStyle={{ color: 'white', fontSize: 25 }} />
                                    <Form.Submit optionalFunc={() => this.onCheckImage(0)} loading={this.props.isSaving && this.state.submitPurpose === 'SAVE'} disabled={(this.props.isSaving && this.state.submitPurpose === 'SAVE')} onPress={(data: any) => this.onSubmit(data, 0)} title='SAVE' style={{ backgroundColor: '#000000', padding: 10, borderRadius: 0, marginRight: 10, marginBottom: 10 }} labelStyle={{ color: 'white', fontSize: 25 }} />
                                    {/* <Button style={{ backgroundColor: '#000000', padding: 10, borderRadius: 0, marginRight: 10, marginBottom: 10 }}><Text style={{ color: 'white', fontSize: 25 }}>SAVE</Text></Button> */}
                                    <Button style={{ backgroundColor: '#000000', padding: 10, borderRadius: 0, marginBottom: 10 }}><Text style={{ color: 'white', fontSize: 25 }}>CANCEL</Text></Button>
                                </View>
                            </View>
                        </View>
                    </Form>
                </ResponsiveView>
            </ScrollView>
        )
    }
}

const mapStateToProps = (state: ReduxAppState) => {
    const _authUserInfo = state.api?.user?.authUser
    return ({
        user: _authUserInfo.response,
        isSaving: state.api?.items?.addItem?.loading
    })
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
    addItem: (data: any) => addItem(dispatch, data),
    resetAddItem: () => dispatch({ type: RESET_ADD_ITEM })
})

export default connect(mapStateToProps, mapDispatchToProps)(compose(HeaderContentHOC, SideNavContentHOC, FooterHOC)(ListingPage as any))