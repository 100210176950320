import awsInstance, { methods } from '../../core/axios.config'
import { AxiosError, AxiosResponse, AxiosRequestConfig } from 'axios'
import { Dispatch } from 'redux'
import { Items } from '.'

export const BEGIN_GET_ARTSHARE_LIST = 'API/ITEM/BEGIN_GET_ARTSHARE_LIST'
export const SUCCESS_GET_ARTSHARE_LIST = 'API/ITEM/SUCCESS_GET_ARTSHARE_LIST'
export const ERROR_GET_ARTSHARE_LIST = 'API/ITEM/ERROR_GET_ARTSHARE_LIST'

export const getArtShareListInitialState = {
    response: [],
    loading: false,
}

export interface GetArtShareListState {
    response: Items[]
    status?: number
    statusText?: string
    loading: boolean
    error?: any
}

export interface GetArtShareListActions {
    type: string
    [item: string]: any
}

export const reducer = (newState: GetArtShareListState = getArtShareListInitialState, action: GetArtShareListActions) => {
    switch (action.type) {
        case BEGIN_GET_ARTSHARE_LIST:
            return Object.assign({}, newState, { loading: true })

        case SUCCESS_GET_ARTSHARE_LIST:
            return Object.assign({}, newState, {
                response: action.data,
                loading: false,
                statusText: 'success',
                status: action.status
            })

        case ERROR_GET_ARTSHARE_LIST:
            return Object.assign({}, newState, {
                statusText: 'error',
                loading: false,
                status: action.status
            })
        default:
            return newState
    }
}

export const beginGetArtShareList = () => ({
    type: BEGIN_GET_ARTSHARE_LIST,
})

export const successGetArtShareList = (response: AxiosResponse) => ({
    type: SUCCESS_GET_ARTSHARE_LIST,
    data: response.data.message,
    status: response.status
})

export const errorGetArtShareList = (error: AxiosError) => ({
    type: ERROR_GET_ARTSHARE_LIST,
    status: error.response?.status,
})

export const getArtShareList = (dispatch: Dispatch) => {
    const config: AxiosRequestConfig = {
        method: methods.GET,
        url: `/get/item-list?isPosted=0&id=0`,
    }
    dispatch(beginGetArtShareList())
    return awsInstance(config)
        .then((response: AxiosResponse) => {
            dispatch(successGetArtShareList(response))
            return response
        })
        .catch((error: AxiosError) => {
            dispatch(errorGetArtShareList(error))
            return new Promise<AxiosResponse>((resolve, reject) => { reject(error) })
        })

}

export default reducer