import React from 'react'
import { Carousel } from 'react-responsive-carousel'


const HomeComponent = (props: any) => {
    return (
        <div id='HomeComponent'>
            <Carousel showStatus={false} showThumbs={false} className='Carousel' dynamicHeight>
                <img src="landingpage/carousel-1.png" />
                <img src="landingpage/carousel-2.png" />
                <img src="landingpage/carousel-3.png" />
                <img src="landingpage/carousel-4.png" />
            </Carousel>
            <p className='description mt-5'><span className='primary'>ArtShare</span> is a unique online platform aiming to enable art collectors and enthusiasts from diverse backgrounds and all walks of life to co-own valuable pieces of Philippine and Asian art masterpieces by offering co-ownership opportunities through crowdfunding.</p>
        </div>
    )
}

export default HomeComponent