import React, {useEffect, useState} from 'react'
import {Dispatch} from 'redux'
import {
  View,
  Button,
  ScrollView,
  TextInput,
  Text,
  Image,
  Platform,
} from 'react-native'
import * as yup from 'yup'
import {debounce, isError, max} from 'lodash'
import {signUpStyles as styles} from './styles'

import {FormattedMessage, injectIntl} from 'react-intl'
import {connect} from 'react-redux'
import {ConfigValue, ReduxAppState} from '../../utils/types'
import {
  ActivityIndicator,
  Avatar,
  Checkbox,
  HelperText,
  Modal,
  Paragraph,
  Portal,
  Title,
} from 'react-native-paper'
import Logo from '../Login/components/Logo'
import {StackNavigationProp} from '@react-navigation/stack'
import {RouteName} from '../../navigation/types'
import * as _ from 'lodash'

import {registerUser, clearUserRegister} from '../../reducers/User/register'

import {Auth} from '@aws-amplify/auth'

import {
  BackgroundTemplate,
  Form,
  ResponsiveView,
  Screen,
} from '../../ReusableComponents/'
import {createWallet} from '../../reducers/Wallet/createWallet'
import Field from '../../ReusableComponents/Form/Field'

const FormSchema = yup.object({
  name: yup.string().required('Name is required'),
  email: yup
    .string()
    .required('Email is required')
    .email('Invalid email format')
    .min(16, 'Email must be between 16 and 40 characters long')
    .max(40, 'Email must be between 16 and 40 characters long'),
  verifyEmail: yup
    .string()
    .when('email', (_email, schema) => {
      return schema.test({
        test: () => Boolean(_email),
        message: 'Email field must have a value to verify',
      })
    })
    .oneOf([yup.ref('email'), null], "Email don't match")
    .required('Verify email is required'),
  // password: yup.string()
  //     .required('Password is required')
  //     .matches(/[A-Z]/, 'Password should have atleast 1 uppercase')
  //     .matches(/[a-z]/, 'Password should have atleast 1 lowercase')
  //     .matches(/[0-9]/, 'Password should have atleast 1 number')
  //     .min(8, 'Minimum length should be 8 character'),
  // verifyPassword: yup.string().oneOf([yup.ref('password'), null], "Passwords don't match").required('Please verify your password'),
  country: yup.string().required('country is required'),
})

export interface DispatchProps {
  onRegister: (data: any) => any
  onClearRegister: () => any
  onCreateWallet: (data: any) => any
}

export interface StateProps {
  config: ConfigValue
  isError: boolean
  isLoading: boolean
  errorMessage: string | undefined
}

export interface OwnProps {
  intl: any
  navigation: StackNavigationProp<any>
}

export interface RegisterState {
  checkbox: CheckBox
  isLoading: boolean
  resetData: any
  country: string
  email: string
}

export type RegisterStateValue =
  | 'checkbox'
  | 'isShowValidIds'
  | 'isLoading'
  | 'userType'
  | 'country'
  | 'region'
  | 'province'
  | 'cityMunicipality'
  | 'barangay'

export type RegisterProps = DispatchProps & StateProps & OwnProps

class RegisterComponent extends React.Component<RegisterProps, RegisterState> {
  constructor(props: RegisterProps) {
    super(props)
    this.state = {
      checkbox: {
        privacy: 'unchecked',
        terms: 'unchecked',
      },
      isLoading: false,
      country: 'Philippines',
      resetData: {},
      email: '',
    }
  }

  componentDidMount() {
    this.props.onClearRegister()
  }

  onSubmit = (data: any) => {
    this.props
      .onRegister(data)
      .then((resp: any) => {
        this.props.onCreateWallet({
          cognitoId: resp.userSub,
          balance: 0,
          email: resp.user.username,
          currency: 'USD',
        })
        this.props.navigation.push(RouteName.LoginPage)
        setTimeout(() => this.props.onClearRegister(), 10000)
      })
      .catch(() => {
        // error
      })
  }

  onChangeCheckbox = (key: 'privacy' | 'terms') => {
    const _cb = {...this.state.checkbox}
    _cb[key] = _cb[key] === 'unchecked' ? 'checked' : 'unchecked'
    this.setState({checkbox: _cb})
  }

  clearFields = (key: RegisterStateValue) => {
    let fieldsToClear = {}
    if (key === 'country') {
      fieldsToClear = {
        region: '',
        province: '',
        cityMunicipality: '',
        barangay: '',
      }
    }

    return fieldsToClear
  }

  onChangeValue = (key: RegisterStateValue, value: any) => {
    if (this.state.country === 'Philippines' || value === 'Philippines') {
      const _newState: any = {}
      _newState[key] = value
      const fieldsToClear = this.clearFields(key)
      this.setState({..._newState, ...fieldsToClear, resetData: fieldsToClear})
    }
  }

  fetchDropDowns = (picker: string) => {
    let _ddl: any[] = []
    const {country} = this.props.config
    if (picker === 'country') {
      _ddl = country.map((item: any) => ({
        label: item.name,
        value: item.name,
      }))
    }
    return _ddl
  }

  renderTermOfUse = () => (
    <React.Fragment>
      <Text style={{fontWeight: 'bold', fontSize: 20}}>Terms of Use</Text>
      <Paragraph
        style={{
          fontWeight: '400',
          fontSize: 15,
          textAlign: 'justify',
        }}>
        These Terms of Use (“Terms”) govern your use of the websites and/or
        mobile applications provided by ArtShare (Tokenized Art) (“Platforms”).
        By accessing and using the Platforms, you agree that you have read,
        understood and accepted the Terms including any additional terms and
        conditions and any policies referenced herein, available on the
        Platforms. If you do not agree or fall within the Terms, please do not
        use the Platforms. The Platforms may be used by natural and juridical
        persons. Where applicable, these Terms shall be subject to
        country-specific provisions as set out herein. Users below the age of 18
        must obtain consent from a parent or legal guardian, who shall agree to
        take responsibility for your actions and any charges associated with
        your use of the Platforms and/or purchase of Goods and engagement of
        medical Services. ArtShare reserves the right to change or modify these
        Terms at any time. You will be deemed to have agreed to the amended
        Terms by your continued use of the Platforms following the date on which
        the amended Terms are posted.
      </Paragraph>
    </React.Fragment>
  )

  // onFileChange = (file: any) => {
  //     const reader = new FileReader()
  //     this.file = file
  //     reader.onload = (e) => {
  //         this.image = e.target?.result
  //     }
  //     reader.readAsDataURL(file)
  // }

  render() {
    const props = this.props
    return (
      <BackgroundTemplate>
        <Form
          formSchema={FormSchema}
          formStyle={[styles.formStyle]}
          resetData={this.state.resetData}
          onSubmit={this.onSubmit}>
          {this.props.isError && (
            <View style={styles.errorMessage}>
              <Text>
                {this.props.errorMessage || (
                  <FormattedMessage id="SignUp.msg.somethingWentWrong" />
                )}
              </Text>
            </View>
          )}
          <Screen minWidth={450}>
            <Form.Field
              style={styles.input}
              formGroupStyle={styles.fieldFormGroupStyle}
              labelStyle={styles.fieldLabelStyle}
              name="name"
              label={props.intl.formatMessage({id: 'SignUp.msg.name'})}
              type="textinput"
            />
          </Screen>
          <Screen maxWidth={449}>
            <Form.Field
              style={[styles.input, {width: 290, padding: 0}]}
              formGroupStyle={styles.fieldFormGroupStyle}
              labelStyle={[styles.fieldLabelStyle, {fontSize: 16}]}
              name="name"
              label={props.intl.formatMessage({id: 'SignUp.msg.name'})}
              type="textinput"
            />
          </Screen>

          <Screen minWidth={450}>
            <Form.Field
              style={styles.input}
              formGroupStyle={styles.fieldFormGroupStyle}
              labelStyle={styles.fieldLabelStyle}
              name="email"
              label={props.intl.formatMessage({id: 'SignUp.msg.email'})}
              type="textinput"
              rules={(value: any) => value.replace(/\s/g, '')}
              onChangeValue={(text: string) => this.setState({email: text})}
            />
          </Screen>
          <Screen maxWidth={449}>
            <Form.Field
              style={[styles.input, {width: 290, padding: 0}]}
              formGroupStyle={styles.fieldFormGroupStyle}
              labelStyle={[styles.fieldLabelStyle, {fontSize: 16}]}
              name="email"
              label={props.intl.formatMessage({id: 'SignUp.msg.email'})}
              type="textinput"
              rules={(value: any) => value.replace(/\s/g, '')}
              onChangeValue={(text: string) => this.setState({email: text})}
            />
          </Screen>

          <Screen minWidth={450}>
            <Form.Field
              style={styles.input}
              formGroupStyle={styles.fieldFormGroupStyle}
              labelStyle={styles.fieldLabelStyle}
              name="verifyEmail"
              label={props.intl.formatMessage({id: 'SignUp.msg.verifyEmail'})}
              type="textinput"
              rules={(value: any) => value.replace(/\s/g, '')}
            />
          </Screen>
          <Screen maxWidth={449}>
            <Form.Field
              style={[styles.input, {width: 290, padding: 0}]}
              formGroupStyle={styles.fieldFormGroupStyle}
              labelStyle={[styles.fieldLabelStyle, {fontSize: 16}]}
              name="verifyEmail"
              label={props.intl.formatMessage({id: 'SignUp.msg.verifyEmail'})}
              type="textinput"
              rules={(value: any) => value.replace(/\s/g, '')}
            />
          </Screen>

          <Screen minWidth={450}>
            <Form.Dropdown
              style={styles.input}
              formGroupStyle={styles.fieldFormGroupStyle}
              labelStyle={styles.fieldLabelStyle}
              name="country"
              label={props.intl.formatMessage({id: 'SignUp.msg.country'})}
              data={this.fetchDropDowns('country')}
              onChangeValue={data => this.onChangeValue('country', data)}
              defaultValue="Philippines"
            />
          </Screen>
          <Screen maxWidth={449}>
            <Form.Dropdown
              style={[styles.input, {padding: 0, width: 290}]}
              formGroupStyle={styles.fieldFormGroupStyle}
              labelStyle={[styles.fieldLabelStyle, {fontSize: 16}]}
              name="country"
              label={props.intl.formatMessage({id: 'SignUp.msg.country'})}
              data={this.fetchDropDowns('country')}
              onChangeValue={data => this.onChangeValue('country', data)}
              defaultValue="Philippines"
            />
          </Screen>

          <Screen.Desktop
            style={{marginLeft: 16, paddingHorizontal: '3%', width: '75%'}}>
            {this.renderTermOfUse()}
          </Screen.Desktop>
          <Screen.Tablet
            style={{marginLeft: 16, paddingHorizontal: '3%', width: '65%'}}>
            {this.renderTermOfUse()}
          </Screen.Tablet>
          <Screen.Mobile
            style={{marginLeft: 15, paddingHorizontal: '3%', width: '94%'}}>
            {this.renderTermOfUse()}
          </Screen.Mobile>
          <Form.Submit
            loading={this.props.isLoading}
            onPress={this.onSubmit}
            title="Sign up"
            style={styles.registerBtn}
            labelStyle={styles.textWhite}
          />
        </Form>
      </BackgroundTemplate>
    )
  }
}

export interface CheckBox {
  privacy: 'unchecked' | 'checked' | 'indeterminate'
  terms: 'unchecked' | 'checked' | 'indeterminate'
}

const mapStateToProps = (state: ReduxAppState) => {
  return {
    config: state.config,
    isLoading: state.api.user.registerUser.loading,
    isError: state.api?.user?.registerUser?.statusText === 'error',
    errorMessage: state.api?.user?.registerUser?.error,
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
  onRegister: (data: any) => registerUser(dispatch, data),
  onClearRegister: () => dispatch(clearUserRegister()),
  onCreateWallet: (data: any) => createWallet(dispatch, data),
})

export default injectIntl(
  connect(mapStateToProps, mapDispatchToProps)(RegisterComponent),
)
