import { Platform, StyleSheet } from 'react-native'
import cpebColors from '../../utils/colors'

export const itemStyles = StyleSheet.create({
    title: {
        textTransform: 'uppercase',
        color: '#808080',
        fontSize: 16,
        fontFamily: "'Montserrat', sans-serif",
        marginBottom: 10,
        marginHorizontal: 20
    },
    activeTitle: {
        textTransform: 'uppercase',
        color: '#F7931E',
        fontSize: 16,
        fontFamily: "'Montserrat', sans-serif",
        marginBottom: 10,
        marginHorizontal: 20
    },
    titleContainer: {
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    artTitle: {
        color: '#000000',
        fontSize: 45,
        fontFamily: "'Montserrat', sans-serif",
        fontWeight: 'bold',
        marginVertical: 10,
        marginHorizontal: 20
    },
    artCaption: {
        fontStyle: 'italic',
        color: '#000000',
        fontSize: 45,
        fontFamily: "'Montserrat', sans-serif",
        fontWeight: 'bold',
        marginVertical: 10,
        marginHorizontal: 20
    },
    imageContainer: {
        // maxHeight: 100,
        // width: '100%',
        // height: '100%'
    },
    image: {
        flex: 1,
        resizeMode: 'cover'
    },
    demoContainer: {
        marginHorizontal: 20
    },
    graphBox: {
        width: '100%',
        height: Platform.OS === 'web' ? 300 : 230,
        marginBottom: 15
    },
    box: {
        width: '50%',
        height: 200
    },
    inner: {
        flex: 1,
        backgroundColor: '#fff'
    },
    innerText: {
        fontSize: 20,
        color: '#062838',
        padding: 5
    },
    speedoMeter: {
        alignItems: 'center',
        paddingTop: 20
    },
    boxContainer: {
        backgroundColor: cpebColors.white,
        padding: 20,
        flexDirection: 'row',
        marginTop: 20,
    },
    boxContainerChild: {
        color: cpebColors.labelColor,
        textAlignVertical: 'center'
    },
    boxContainerChildRight: {
        fontSize: 30
    },
    boxContainerChildLeft: {
        flex: .8,
        fontSize: 15
    },
    container: {
        flexDirection: 'row'
    },
    greenBtn: {
        backgroundColor: '#AFCD37',
        borderRadius: 5,
        alignSelf: 'center',
        width: '100%',
        fontSize: 20,
        marginVertical: 15,
        padding: 15,
        flexWrap: 'nowrap'
    },
    greenBtn2: {
        paddingVertical: 20,
        paddingHorizontal: 0,
        alignItems: 'center',
        flex: 1
    },
    greenBtnLabel: {
        alignSelf: 'center',
        fontSize: 15,
        color: cpebColors.white
    },
    historicalFilter: {
        flexDirection: 'row-reverse',
        marginBottom: 10,
        marginTop: 10
    },
    historicalFilterItem: {
        textAlign: 'center',
        padding: 5,
        backgroundColor: cpebColors.white,
        borderRadius: 3
    },

    historicalFilterSelected: {
        backgroundColor: '#003849',
        color: '#B0CA38'
    },
    iconStyle: {
        height: 40,
        width: 40,
        tintColor: '#fff',
        marginBottom: 15
    },
    imageGallery: {
        width: '40%',
        height: 'auto'
    }
})