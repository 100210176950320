import { Platform, StyleSheet } from 'react-native'
import cpebColors from '../../utils/colors'


export const resetPwdStyles = StyleSheet.create({
    scrollView: {
        backgroundColor: Platform.OS === 'web' ? '#F0F2F5' : '#fff'
    },
    title: {
        alignSelf: 'center',
        fontSize: 20,
        marginTop: 10,
        marginBottom: 15,
        fontWeight: 'bold'
    },
    formStyle: {
        alignSelf: 'center',
        width: '100%',
        backgroundColor: '#fff',
        padding: '2%'
    },
    formShadow: {
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 5,
        },
        shadowOpacity: 0.34,
        shadowRadius: 6.27,
        elevation: 10,
        marginTop: 100,
    },
    formGroup: {
        marginTop: 10,
        marginHorizontal: 20,
    },
    formGroup2: {
        marginVertical: 5,
        marginHorizontal: 5,
        alignItems: 'center'
    },
    input: {
        backgroundColor: 'white',
        borderColor: 'black',
        paddingHorizontal: 10,
        fontSize: 13,
        borderWidth: 1,
        borderRadius: 0,
        fontFamily: "'Montserrat', sans-serif",
        fontWeight: '800',
        width: 400,
        marginBottom: 13,
        padding: 13
    },
    button: {
        width: 300,
        backgroundColor: '#1E5732',
        borderRadius: 20,
        borderWidth: 1,
        marginTop: 10
    },
    text: {
        fontSize: 14,
        lineHeight: 14,
        fontFamily: "'Montserrat', sans-serif",
        marginBottom: 5
    },
    errorContainer: {
        alignItems: 'center',
        marginBottom: 10
    },
    requirementsContainer: {
        marginBottom: 12,
        marginHorizontal: 25,
    },
    instructionText: {
        color: cpebColors.labelColor,
        fontWeight: '700',
        padding: 10,
        textAlign: 'center'
    },
    errorColor: {
        color: 'red'
    },
    successColor: {
        color: 'green'
    },
    plainColor: {
        color: cpebColors.labelColor,
    },
    alignSelfCenter: {
        alignSelf: 'center'
    },
    requiredIcon: {
        color: 'red'
    },
    errorBorder: {
        borderColor: 'red'
    },
    textCenter: {
        textAlign: 'center'
    },
    resetPwdContainer: {
        alignSelf: 'center',
        maxWidth: 500,
        width: '100%',
        backgroundColor: '#fff',
    },
    resetPwdContainerShadow: {
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 5,
        },
        shadowOpacity: 0.34,
        shadowRadius: 6.27,
        elevation: 10,
        marginTop: 100,
    },
    registerBtn: {
        marginBottom: 20,
        marginTop: 20,
        marginLeft: 20,
        width: 200,
        backgroundColor: '#F7931E'
    },
    textWhite: {
        color: 'white',
        fontSize: 15
    },
})
