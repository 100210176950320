import { StackNavigationProp } from '@react-navigation/stack'
import React from 'react'
import { FormattedMessage, FormattedNumber, injectIntl } from 'react-intl'
import { ScrollView, View, Text, Image, Platform } from 'react-native'
import { RadioButton, Subheading, Title, TextInput, Button } from 'react-native-paper'
import { connect } from 'react-redux'
import { compose, Dispatch } from 'redux'
import { ReduxAppState } from '../../utils/types'
import { walletDetailStyles as styles } from './styles'
import { Form, ResponsiveView, Screen } from '../../ReusableComponents'
import * as yup from 'yup'
import * as _ from 'lodash'
import { User } from '../../reducers/User'
import { getFunds } from '../../reducers/Wallet/getFunds'
import { Wallet } from '../../reducers/Wallet'
import Modal from 'react-modal'
import { modalCustomStyles } from '../Buy/styles'
import { CLEAR_UPDATE_WALLET, updateWallet, UpdateWalletState } from '../../reducers/Wallet/updateWallet'
import { TouchableOpacity } from 'react-native-gesture-handler'
export interface ComponentProps {
  navigation: StackNavigationProp<any>
  isRefreshing: boolean
}

export interface DispatchProps {
  onGetFunds: (id: string) => any
  onAddFunds: (id: string, data: any) => any
  clearUpdateWallet: () => any
}

export interface StateProps {
  isLoading: boolean
  isError: boolean
  user: User
  wallet: Wallet
  updateWalletLoading: boolean
  updateWallet: UpdateWalletState
  walletError: boolean
}

export interface WalletDetailPageState {
  selectedPaymentType: string
  newValue: string
  isOpenPaymentModal: boolean
  funds: string
  isNextPage: boolean
}

export type WalletDetailPageProps = ComponentProps & DispatchProps & StateProps

Modal.setAppElement('#react-root')

const FormSchema = yup.object({
  username: yup.string().required('username is required'),
  password: yup.string().required('password is required'),
})

class WalletDetailComponent extends React.Component<
  WalletDetailPageProps,
  WalletDetailPageState
> {
  constructor(props: WalletDetailPageProps) {
    super(props)
    this.state = {
      selectedPaymentType: '',
      newValue: '',
      isOpenPaymentModal: false,
      funds: '',
      isNextPage: false
    }
  }

  componentDidMount() {
    this.props.onGetFunds(this.props.user.sub)
    this.props.clearUpdateWallet()
  }

  onSelectPayment = (value: string) => {
    this.setState({ selectedPaymentType: value })
  }

  onToggleModal = (isOpen: boolean) => {
    this.setState({ isOpenPaymentModal: isOpen })
  }

  onDisplayCreditDebit = () => (
    <React.Fragment>
      <View style={{ flexDirection: 'row', padding: 10 }}>
        <RadioButton value='creditdebit' color='black' />
        <Text style={{ fontSize: 18, textAlignVertical: 'center', marginTop: 7.5, fontFamily: "'Montserrat', sans-serif" }}>
          <FormattedMessage id='WalletDetail.msg.creditDebit' />
        </Text>
      </View>
      {Platform.OS === 'web' && (
        <View style={{ padding: 10 }}>
          <Image source={require('../../assets/card.jpg')} style={{ width: 120, height: 40 }} resizeMode='stretch' />
        </View>
      )}
    </React.Fragment>
  )

  onDisplayGcash = () => (
    <View style={{ flexDirection: 'row', padding: 10 }}>
      <RadioButton value='gcash' color='black' />
      <Image source={require('../../assets/gcash.jpg')} style={{ width: 120, height: 40 }} resizeMode='stretch' />
    </View>
  )

  onAddFund = () => {
    this.props.onAddFunds(
      this.props.wallet.id,
      {
        balance: Number(this.props.wallet.balance) + Number(this.state.funds),
        amt: Number(this.state.funds),
        userId: this.props.user.sub,
        email: this.props.user.email,
        isUpdate: true
      })
      .then(async () => {
        await this.props.onGetFunds(this.props.user.sub)
        this.onToggleModal(false)
        this.setState({ selectedPaymentType: '', isNextPage: false })
        setTimeout(this.props.clearUpdateWallet, 5000)
      })
      .catch(() => {
        // error
      })
  }

  renderCreditDebitForm = () => {
    return (
      <Form formSchema={FormSchema} formStyle={styles.formStyle}>
        <Button style={{ position: 'absolute', right: 0 }}
          onPress={() => {
            this.onSelectPayment('')
            this.onPrevPage()
          }}
          labelStyle={{ fontWeight: 'bold', fontSize: 20, color: 'black' }}>X</Button>
        <RadioButton.Group value={this.state.selectedPaymentType} onValueChange={this.onSelectPayment}>
          {this.state.selectedPaymentType === 'gcash' && (
            <View style={{ marginTop: 40, marginBottom: 20 }}>
              {this.onDisplayGcash()}
            </View>
          )}
          {this.state.selectedPaymentType === 'creditdebit' && (
            <View style={{ justifyContent: 'space-between', flexDirection: 'row', width: '100%', marginTop: 40, marginBottom: 20 }}>
              {this.onDisplayCreditDebit()}
            </View>
          )}
        </RadioButton.Group>

        {/* Temporary Design */}
        {this.state.selectedPaymentType === 'creditdebit' ? (
          <React.Fragment>
            <TextInput style={{ marginTop: 20 }} label='Cardholder&apos;s Name' />
            <TextInput style={{ marginTop: 20 }} label='Card Number' />
            <View style={{ flexDirection: 'row', justifyContent: 'space-between', marginTop: 20 }}>
              <TextInput style={{ width: '45%' }} label='CVC' />
              <TextInput style={{ width: '45%' }} label='Expiry Date' />
            </View>
          </React.Fragment>
        ) : (
          <TextInput style={{ marginTop: 20 }} label='Mobile number' />
        )}


        <View style={{ marginVertical: 40 }}>
          <Text style={styles.title}>
            <FormattedMessage id="WalletDetail.msg.accountToBeDeposited" />
          </Text>
          {/* <Text style={{ fontSize: 30, fontWeight: '600' }}>
            <FormattedNumber format={this.props.wallet?.currency} value={Number(this.props.wallet?.balance)} />
          </Text> */}
          <TextInput keyboardType='numeric' autoComplete onChangeText={(text) => this.setState({ funds: Number.isNaN(Number(text)) ? '0' : text })} value={this.state.funds} />
        </View>
        <Text style={{ fontWeight: '600', marginBottom: 3 }}>Additional charges may apply</Text>
        <Button loading={this.props.updateWalletLoading} disabled={true || this.props.updateWalletLoading} onPress={() => this.onAddFund()} style={[styles.paymentButton, { marginBottom: 60 }]} color='white'><FormattedMessage id='WalletDetail.msg.deposit' /></Button>
      </Form>
    )
  }

  componentWillUnmount() {
    this.props.clearUpdateWallet()
  }

  onNextPage = () => {
    this.setState({ isNextPage: true })
  }

  onPrevPage = () => {
    this.setState({ isNextPage: false })
  }

  onDisplayBalanceMobile = () => (
    <Screen maxWidth={987} style={{ marginTop: this.state.selectedPaymentType && this.state.isNextPage ? 25 : 0 }}>
      <View style={{ alignItems: !this.state.isNextPage || this.state.selectedPaymentType === 'gcash' ? 'center' : 'flex-start' }}>
        <Title numberOfLines={1} style={styles.title}>
          <FormattedMessage id="WalletDetail.msg.balance" />
        </Title>
        <Text style={{ fontSize: 30, fontWeight: '600' }}>
          <FormattedNumber format={this.props.wallet?.currency} value={Number(this.props.wallet?.balance ?? 0)} />
        </Text>
      </View>
    </Screen>
  )

  render() {
    return (
      <React.Fragment>
        {/* Overview */}
        <Modal
          isOpen={this.state.isOpenPaymentModal}
          onRequestClose={() => this.onToggleModal(false)}
          style={modalCustomStyles}
        >
          <View>
            {this.props.updateWallet?.statusText === 'error' && (
              <View style={{ alignItems: 'center', width: '100%' }}>
                <View style={[styles.alertMessage, { borderColor: 'red', backgroundColor: '#FF3030' }]} >
                  <Text style={{ color: 'white', fontSize: 16, fontFamily: "'Montserrat', sans-serif" }}>
                    <FormattedMessage id='WalletDetail.msg.somethingWentWrong' />
                  </Text>
                </View>
              </View>
            )}
            <Title style={{ textAlign: 'center', marginBottom: 15 }}>
              <FormattedMessage id='WalletDetail.msg.payment' />
            </Title>
            <TextInput keyboardType='numeric' autoComplete label='Funds' onChangeText={(text) => this.setState({ funds: Number.isNaN(Number(text)) ? '0' : text })} value={this.state.funds} />
            <Button loading={this.props.updateWalletLoading} color='white' style={[styles.paymentButton, { marginVertical: 20 }]} onPress={this.onAddFund}>
              <FormattedMessage id='WalletDetail.msg.addFund' />
            </Button>
          </View>
        </Modal>
        {this.props.walletError ? (
          <View style={{ alignItems: 'center', width: '100%' }}>
            <View style={[styles.alertMessage, { borderColor: 'red', backgroundColor: '#FF3030' }]} >
              <Text style={{ color: 'white', fontSize: 16, fontFamily: "'Montserrat', sans-serif" }}>
                <FormattedMessage id='WalletDetail.msg.walletError' />
              </Text>
            </View>
          </View>
        ) : (
          <View style={styles.titleContainer}>
            <View style={{ paddingTop: 30, marginHorizontal: 20 }}>
              {this.props.updateWallet?.statusText === 'success' && (
                <View>
                  <View style={[styles.alertMessage, { borderColor: '#5CC858', backgroundColor: '#5CC858' }]} >
                    <Text style={{ color: 'white', fontSize: 16, fontFamily: "'Montserrat', sans-serif" }}>
                      <FormattedMessage id='WalletDetail.msg.updateSuccessfully' />
                    </Text>
                  </View>
                </View>
              )}
              {this.state.isNextPage ? (
                <Screen maxWidth={987} style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                  <TouchableOpacity onPress={this.onPrevPage} style={{ alignSelf: 'flex-start' }}>
                    <Text style={{ fontWeight: 'bold', fontSize: 18 }}>{'< Back'}</Text>
                  </TouchableOpacity>
                  {this.state.selectedPaymentType === 'creditdebit' ? <View style={{ width: 120, height: 40, marginRight: 10 }}>
                    <Image source={require('../../assets/card.jpg')} style={{ width: '100%', height: '100%' }} resizeMode='stretch' />
                  </View> : <View />}
                </Screen>)
                : this.onDisplayBalanceMobile()}
              <Screen minWidth={988}>
                <Title numberOfLines={1} style={styles.title}>
                  <FormattedMessage id="WalletDetail.msg.balance" />
                </Title>
                <Text style={{ fontSize: 30, fontWeight: '600' }}>
                  <FormattedNumber format={this.props.wallet?.currency} value={Number(this.props.wallet?.balance ?? 0)} />
                </Text>
              </Screen>
              {false && (
                <React.Fragment>
                  <RadioButton.Group value={this.state.selectedPaymentType} onValueChange={this.onSelectPayment}>
                    <Screen maxWidth={987}>
                      {this.state.isNextPage ? <View /> : (
                        <React.Fragment>
                          <ResponsiveView width="80" viewStyle={[styles.border, { marginTop: 10, justifyContent: 'space-between', flexDirection: 'row', flexWrap: 'wrap' }]}>
                            {this.onDisplayCreditDebit()}
                          </ResponsiveView>
                          <ResponsiveView width="80" viewStyle={[styles.border, { marginTop: 10, marginBottom: 20 }]}>
                            {this.onDisplayGcash()}
                          </ResponsiveView>
                        </React.Fragment>
                      )}
                    </Screen>
                    <Screen minWidth={988}>
                      <View style={[styles.border, { marginTop: 10, width: 550, justifyContent: 'space-between', flexDirection: 'row', flexWrap: 'wrap' }]}>
                        {this.onDisplayCreditDebit()}
                      </View>
                      <View style={[styles.border, { marginTop: 10, width: 550, marginBottom: 20 }]}>
                        {this.onDisplayGcash()}
                      </View>
                    </Screen>
                  </RadioButton.Group>

                  {this.state.selectedPaymentType && (
                    <React.Fragment>
                      <Screen maxWidth={987}>
                        {this.state.isNextPage ? (
                          <ResponsiveView width="90">
                            {this.state.selectedPaymentType === 'creditdebit' ? (
                              <React.Fragment>
                                <TextInput style={{ marginTop: 20 }} label='Cardholders name' />
                                <TextInput style={{ marginTop: 20 }} label='Card Number' />
                                <View style={{ flexDirection: 'row', justifyContent: 'space-between', marginTop: 20 }}>
                                  <TextInput style={{ width: '45%' }} label='CVC' />
                                  <TextInput style={{ width: '45%' }} label='Expiry Date' />
                                </View>
                              </React.Fragment>
                            ) : (
                              <TextInput style={{ marginTop: 20 }} label='Mobile number' />
                            )}
                            {/* {this.renderCreditDebitForm()} */}
                          </ResponsiveView>
                        ) : <View />}
                      </Screen>
                    </React.Fragment>
                  )}
                  <Screen maxWidth={987}>
                    {this.state.isNextPage ? (
                      <React.Fragment>
                        {this.state.selectedPaymentType && this.state.isNextPage && (
                          <View style={{ marginVertical: 40 }}>
                            <Text style={styles.title}>
                              <FormattedMessage id="WalletDetail.msg.accountToBeDeposited" />
                            </Text>
                            {/* <Text style={{ fontSize: 30, fontWeight: '600' }}>
                        <FormattedNumber format={this.props.wallet?.currency} value={Number(this.props.wallet?.balance)} />
                      </Text> */}
                            <TextInput keyboardType='numeric' autoComplete onChangeText={(text) => this.setState({ funds: Number.isNaN(Number(text)) ? '0' : text })} value={this.state.funds} />
                          </View>
                        )}
                        <Text style={{ marginTop: 50, fontWeight: '600', marginBottom: 3 }}>Additional charges may apply</Text>
                        <Button loading={this.props.updateWalletLoading} disabled={true || this.props.updateWalletLoading} onPress={() => this.onAddFund()} style={[styles.paymentButton, { marginBottom: 60 }]} color='white'><FormattedMessage id='WalletDetail.msg.deposit' /></Button>
                      </React.Fragment>
                    ) : (
                      <Button disabled={!this.state.selectedPaymentType} onPress={this.onNextPage} color='white' style={{ alignSelf: 'flex-end', backgroundColor: this.state.selectedPaymentType ? '#F7931E' : '#DBDBDB', paddingHorizontal: 15, paddingVertical: 10, marginTop: 50 }}>Next</Button>
                    )}
                  </Screen>
                </React.Fragment>
              )}
            </View>
            {false && this.state.selectedPaymentType && (
              <React.Fragment>
                <Screen minWidth={988}>
                  {this.renderCreditDebitForm()}
                </Screen>
              </React.Fragment>
            )}
          </View>
        )}
        <View>
          <Title style={{ fontSize: 40, marginTop: 20, marginLeft: 15.5 }}>COMING SOON....</Title>
        </View>
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state: ReduxAppState) => {
  return {
    user: state.api.user.authUser.response,
    wallet: state.api.wallet.getFunds.response,
    walletError: state.api.wallet?.getFunds?.statusText === 'error',
    updateWalletLoading: state.api.wallet?.updateWallet?.loading,
    updateWallet: state.api.wallet?.updateWallet
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
  onGetFunds: (id: string) => getFunds(dispatch, id),
  onAddFunds: (id: string, data: any) => updateWallet(dispatch, id, data),
  clearUpdateWallet: () => dispatch({ type: CLEAR_UPDATE_WALLET })
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(compose(injectIntl(WalletDetailComponent as any)))
