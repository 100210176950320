import React from 'react'

import { View, Image, ScrollView, ToastAndroid, Text, TouchableOpacity } from 'react-native'

import { Avatar, Button, Title, Menu, Divider } from 'react-native-paper';

import { HeaderStyles as styles } from './styles'
import { DrawerNavigationProp } from '@react-navigation/drawer';
import Icon from 'react-native-vector-icons/Ionicons'

import cpebColors from '../utils/colors'
import { FormattedMessage } from 'react-intl';
import { Screen } from '../ReusableComponents';
import { ReduxAppState } from '../utils/types';
import { connect } from 'react-redux';
import { User } from '../reducers/User';
import { RouteName } from '../navigation/types';
import { updateMenu } from '../reducers/Menu/changeMenu'
import { Dispatch } from 'redux';
import { AxiosPromise } from 'axios';
import { retrieveUser } from '../reducers/User/user';

export interface HeaderContentProps {
    navigation: DrawerNavigationProp<any>
}

export interface DispatchProps {
    updateMenu: (menu: string) => any,
    getUserInfo: () => any
}

export interface StateProps {
    currentUser?: User,
}

export interface HeaderContentState {
    isVisible: boolean
    imageError: boolean
}

export type HeaderContentDetailsProps = HeaderContentProps & DispatchProps & StateProps

const ProfileDropdown = (props: any) => {
    return (
        <React.Fragment>
            <TouchableOpacity style={{ flexDirection: 'row' }} onPress={props.navigateToProfile}>
                {!props.profilePicThumbnail ? (
                    <Avatar.Text size={40} label={props.initials} style={styles.profileIcon} />
                ) : (
                    <Image
                        style={{ width: 40, height: 40, borderRadius: 80, borderColor: '#F5BF32', borderWidth: 1, marginRight: 10 }}
                        source={{ uri: !props.imageError ? props.profilePicThumbnail : props.profilePicUrl }}
                        onError={() => props.onImageError()}
                        resizeMode='contain' />
                )}

                <View style={{ alignSelf: 'center' }}>
                    <Title style={styles.greetingsText}>{props.name}</Title>
                </View>
                {/* <Icon name="caret-down" size={18} style={styles.caretDownIcon} /> */}
            </TouchableOpacity>
        </React.Fragment>
    )
}

const HeaderContentHOC = (WrappedComponent: any) => {
    class HeaderContent extends React.Component<HeaderContentDetailsProps, HeaderContentState> {
        constructor(props: HeaderContentDetailsProps) {
            super(props);
            this.state = {
                isVisible: false,
                imageError: false
            };
        }

        componentDidMount() {
            this.props.getUserInfo()
        }

        getInitial = (name: any) => {
            return `${name.charAt(0).toUpperCase()}`
        }

        capitalize = (str: any) => {
            const firstWord = str.split(" ")[0]
            return firstWord.charAt(0).toUpperCase() + firstWord.slice(1);
        }

        onImageError = () => {
            this.setState({ imageError: true })
        }

        openMenu = () => {
            this.setState({ isVisible: true })
        }

        closeMenu = () => {
            this.setState({ isVisible: false })
        }

        navigateToProfile = () => {
            this.props.navigation.navigate(RouteName.ProfilePage)
            this.props.updateMenu(RouteName.ProfilePage)
            this.closeMenu()
        }

        getHeader = () => (
            <React.Fragment>
                <Screen maxWidth={991}>
                    <TouchableOpacity onPress={() => this.props.navigation.toggleDrawer()}>
                        <Image
                            source={require('../assets/menu.png')}
                            style={{ height: 30, width: 30 }}
                        />
                    </TouchableOpacity>
                </Screen>
                <View style={{ flex: 1, height: 2.5, backgroundColor: '#9D9D9D', marginHorizontal: 20 }} />
                <View style={{ flexDirection: 'row' }}>
                    <Image
                        source={require('../assets/mutual-art2.png')}
                        style={{ height: 50, width: 50 }}
                        resizeMode='stretch'
                    />
                    <View style={{ marginLeft: 10 }}>
                        <Text style={styles.titleText}>
                            <FormattedMessage id='HeaderContent.msg.title' />
                        </Text>
                    </View>
                </View>
            </React.Fragment>
        )

        render() {
            const { currentUser } = this.props
            return (
                <React.Fragment>
                    <View style={styles.headerParent}>
                        <Screen minWidth={375} style={{ flexDirection: 'row', alignItems: 'center', marginBottom: 20 }}>
                            {this.getHeader()}
                        </Screen>
                        <Screen maxWidth={374} style={{ marginBottom: 30 }}>
                            {this.getHeader()}
                        </Screen>
                        <Screen minWidth={991}>
                            <View style={styles.rightSideParent}>
                                <Menu
                                    visible={this.state.isVisible}
                                    onDismiss={this.closeMenu}
                                    anchor={
                                        <ProfileDropdown
                                            profilePicThumbnail={currentUser?.profilePicThumbnail}
                                            profilePicUrl={currentUser?.profilePicUrl}
                                            imageError={this.state.imageError}
                                            initials={this.getInitial(currentUser?.name)}
                                            name={this.capitalize(currentUser?.name)}
                                            onImageError={this.onImageError}
                                            openMenu={this.openMenu}
                                            navigateToProfile={this.navigateToProfile}
                                        />}
                                    style={{ marginTop: 40, width: '10%' }}>
                                    {/* <Menu.Item titleStyle={{ fontSize: 12, fontFamily: "'Montserrat', sans-serif" }} title="Profile" onPress={() => this.navigateToProfile()} /> */}
                                </Menu>

                            </View>
                        </Screen>
                    </View>
                    <WrappedComponent {...this.props} />
                </React.Fragment>
            )
        }
    }

    const mapStateToProps = (state: ReduxAppState) => {
        const _authUserInfo = state.api?.user?.authUser
        return ({
            currentUser: _authUserInfo.response
        })
    }

    const mapDispatchToProps = (dispatch: Dispatch) => ({
        updateMenu: (activeItem: string) => dispatch(updateMenu(activeItem)),
        getUserInfo: () => retrieveUser(dispatch)
    })

    return connect(mapStateToProps, mapDispatchToProps)(HeaderContent)
}

export default HeaderContentHOC
