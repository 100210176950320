import { StyleSheet } from 'react-native'
import cpebColors from '../../utils/colors'

export default StyleSheet.create({
    deskTopFooter: {
        bottom: 10,
        position: 'absolute',
        marginLeft: '54%',
        flex: 1
    },
    mobileFooter: {
        position: 'absolute',
        bottom: 10,
        alignSelf: 'center',
        flex: 1
    },
})