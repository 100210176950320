import resetPasswordReducer, { resetPwdInitialState, ResetPwdInitialState } from './ResetPassword'
import authUserReducer, { acctInfoInitialState, AccountInfoInitialState } from './User'
import itemReducer, { itemInitialState, ItemInitialState } from './Items'
import menuReducer, { menuInitialState, MenuInitialState } from './Menu'
import walletReducer, { walletInitialState, WalletInitialState } from './Wallet'
import auditReducer, { auditInitialState, AuditInitialState } from './Audit'
import modalReducer, { modalInitialState, ModalInitialState } from './Modal'

import { combineReducers } from 'redux'

export const apiInitialState: ApiInitialState = {
    user: acctInfoInitialState,
    resetPwd: resetPwdInitialState,
    menu: menuInitialState,
    items: itemInitialState,
    wallet: walletInitialState,
    audit: auditInitialState,
    modal: modalInitialState
}

export interface ApiInitialState {
    user: AccountInfoInitialState,
    resetPwd: ResetPwdInitialState,
    menu: MenuInitialState,
    items: ItemInitialState,
    wallet: WalletInitialState,
    audit: AuditInitialState,
    modal: ModalInitialState
}

const reducer = combineReducers({
    user: authUserReducer,
    resetPwd: resetPasswordReducer,
    menu: menuReducer,
    items: itemReducer,
    wallet: walletReducer,
    audit: auditReducer,
    modal: modalReducer
})

export default reducer