import React from 'react'
import { isMobile } from '../../../ReusableComponents/Screens/Mobile'
import { isTablet } from '../../../ReusableComponents/Screens/Tablet'
import { RouteName } from '../../../navigation/types'
import { useMediaQuery } from 'react-responsive'

export const isDesktop = () => useMediaQuery({ query: '(min-width: 992px)' })

const HowItWorksComponent = (props: any) => {
  return (
    <div id="HowItWorksComponent" className="HowItWorks">
      <div className="hiwContainer">
        {/* <div className="title opacity-0">
                    <h1>HOW IT</h1>
                    <h1>WORKS</h1>
                    <p>Our approach is
                        simple and accessible
                        to everyone. </p>
                </div>
                <div className="hiwContainer__subtitle opacity-0">
                    <p>Our in-house art experts and connoisseurs have carefully curated the
selection and conducted extensive due diligence on the artworks to offer you iconic pieces of art. Once they locate a
masterpiece and come
into agreement with owners to purchase such pieces,  crowdfunding campaigns are initiated to acquire these artworks.</p>
                </div> */}
        <div className="hiwContainer__section">
          <div className="hiwContainer__header">
            <div>
              <h1 className="hiwContainer__title">HOW IT</h1>
              <h1 className="hiwContainer__title">WORKS</h1>
            </div>
            <div className='d-flex justify-content-end'>
            <p className=''>Our approach is
simple and accessible
to everyone. </p>
            </div>
          </div>
          <div className="hiwContainer__subheader">
            <p className="hiwContainer__description text-right">
              Our in-house art experts and connoisseurs have carefully curated
              the selection and conducted extensive due diligence on the
              artworks to offer you iconic pieces of art.
            </p>
            <p className="hiwcontainer__description text-right">
            Once they locate a
              masterpiece and come into agreement with owners to purchase such
              pieces, crowdfunding campaigns are initiated to acquire these
              artworks.
            </p>
          </div>
        </div>
        {isDesktop() ? (
          <img className='hiwContainer__img' src="landingpage/hiw-photo-3.jpg" />
        ) : (
          <img className='hiwContainer__img' src="landingpage/hiw-photo.png" style={{ objectFit: 'fill' }} />
        )}
        {/* <img className='hiwContainer__img' src="landingpage/hiw-photo.png" /> */}

        <div className="description">
          <h3>Here’s how it works for you:</h3>
          <div className="exploreContainer d-flex justify-content-center align-items-center">
            <img src="landingpage/search-icon.png" className="search-icon" />
            <div className="exploreContainer__wrapper mt-3 d-flex flex-column justify-content-center">
              <p className="exploreContainer__title">EXPLORE OUR COLLECTION</p>
              <p>
                Browse through our expert-curated collection of Philippine and
                Asian artists.
              </p>
            </div>
          </div>
          <div className="exploreContainer d-flex justify-content-center align-items-center">
            <img src="landingpage/photoframe.png" className="icon" />
            <div className="exploreContainer__wrapper mt-3 d-flex flex-column justify-content-center">
              <p className="exploreContainer__title">CHOOSE YOUR ART PIECE</p>
              <p>
                Once you've determined your interest in participating in a
                specific art piece and the percentage of ownership you wish to
                take on, you can own a fraction of the artwork on this platform.{' '}
              </p>
            </div>
          </div>
          <div className="exploreContainer d-flex justify-content-center align-items-center">
            <img src="landingpage/token.png" className="icon" />
            <div className="exploreContainer__wrapper mt-3 d-flex flex-column justify-content-center mb-5">
              <p className="exploreContainer__title">PURCHASE ART FRACTION</p>
              <p>
                Once the artwork is fully funded, we acquire the piece on behalf
                of the co-owners.
              </p>
            </div>
          </div>
        </div>
        <img src="landingpage/wave.png" className="wave" />
        <img src='landingpage/whitepaint.png' className='hiwContainer__whitepaint' />
      </div>
      <div className="structure mx-5 mt-5">
        <div className="structure__descriptionContainer">
          <h3 className="structure__title">
            At ArtShare, we offer a simple and straightforward fee structure:
          </h3>
          <h1 className="structure__subtitle mt-4">1.5% FEE PER YEAR</h1>
          <p className="structure__description">
            Paid in the form of equity in the artwork: This covers management
            fees, secure storage, insurance costs, marketing and administrative
            costs, professional and appraisal fees. As a co-owner, you should not
            have to worry about these costs on your own and we are committed to
            making the co-ownership experience as hassle-free as possible
          </p>
          <h1 className="structure__subtitle mt-4">20% OF FUTURE PROFIT</h1>
          <p className="structure__description mb-5">
            This aligns your interests with ArtShare in seeking the most
            attractive price for the art co-ownership. We believe that when our
            partners profit, we profit as well, and we are fully dedicated to
            maximizing the value of our art masterpieces.
          </p>
        </div>
        {isDesktop() ? (
          <div className="gray-container structure__desktop mx-3 d-flex justify-content-evenly">
            <div className='w-50 d-flex flex-column gap-3'>
              <h3 className="mt-3">Ownership</h3>
              <p>
                Once artwork is acquired, each of the co-owner receives a fraction
                of the masterpiece represented by an ownership certificate.
              </p>
              <h3>Hold</h3>
              <p>
                ArtShare will hold the artwork for a period of 3 to 10 years,
                during which time the value of the piece is expected to increase.
                At the end of the holding period, we may sell the piece through
                auction or private sale and a pro-rata share in the net proceeds
                after fees is distributed to the co-owners based on their
                proportional share in ownership.
              </p>
              <h3>Flexibility</h3>
              <p>
                If a co-owner wishes to sell their share before the holding period
                ends, they may do so after the hold out period of at least 120
                days from the end of the primary offer period, through ArtShare's
                secondary market. (This will become available soon.)
              </p>
            </div>
            <img
              src="landingpage/brushes.png"
              className="structure__image m-0"
            />
          </div>
        ) : (
          <div className="gray-container mobile px-3">
            <img
              src="landingpage/brushes-mobile.png"
              className="structure__image"
            />
            <h3 className="mt-3">Ownership</h3>
            <p>
              Once artwork is acquired, each of the co-owner receives a fraction
              of the masterpiece represented by an ownership certificate.
            </p>
            <h3>Hold</h3>
            <p>
              ArtShare will hold the artwork for a period of 3 to 10 years,
              during which time the value of the piece is expected to increase.
              At the end of the holding period, we may sell the piece through
              auction or private sale and a pro-rata share in the net proceeds
              after fees is distributed to the co-owners based on their
              proportional share in ownership.
            </p>
            <h3>Flexibility</h3>
            <p>
              If a co-owner wishes to sell their share before the holding period
              ends, they may do so after the hold out period of at least 120
              days from the end of the primary offer period, through ArtShare's
              secondary market. (This will become available soon.)
            </p>
          </div>
        )}

        <div className="signupLoginContainer py-3 mb-3">
          <div className='textContainer'>
            <img src="landingpage/account-icon.png" />
            <div>
              <p>Ready to start your art collection journey?</p>
              <p>Sign up or log in to our platform today.</p>
            </div>
          </div>
          <div className="buttonContainer mt-2">
            <button className="" onClick={() => props.navigation.navigate(RouteName.SignUpPage)}>Sign up</button>
            <button className="" onClick={() => props.navigation.navigate(RouteName.LoginPage)}>Login</button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default HowItWorksComponent
