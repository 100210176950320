import { RouteName } from '../navigation/types'

const config = {
    screens: {
        [RouteName.AuthPage]: {
            screens: {
                [RouteName.WelcomePage]: '/',
                [RouteName.LoginPage]: 'login',
                [RouteName.SignUpPage]: 'signup',
            }
        },
        [RouteName.MainHomePage]: {
            screens: {
                [RouteName.DashboardPage]: 'dashboard',
                [RouteName.ResetPwdPage]: 'resetpwd',
                [RouteName.ProfilePage]: 'profile',
                [RouteName.SettingsPage]: 'settings',
                [RouteName.ItemDetailPage]: 'details',
                [RouteName.ListPage]: 'list',
                [RouteName.ActiveListPage]: 'activelist'
            }
        }
    },
}

const linking = {
    prefixes: ["cpebs://app"],
    config
}

export default linking