import getArtShareListReducer, { getArtShareListInitialState, GetArtShareListState } from './getArtShareList'
import getItemListReducer, { getItemListInitialState, GetItemListState } from './getItems'
import getItemInfoReducer, { getItemInfoInitialState, GetItemInfoState } from './getItemInfo'
import addItemReducer, { addItemInitialState, AddItemState } from './addItem'
import buyItemReducer, { buyItemInitialState, BuyItemState } from './buyItem'
import deleteItemReducer, { deleteItemInitialState, DeleteItemState } from './deleteItem'
import { combineReducers } from 'redux'

export interface Items {
    id: string
    artist: string
    description: string
    displayLocation: string
    dmt: string
    listedValue: string
    listingDate: string
    shareOnSale: string
    title: string
    year: string
    numberOfShare: number
    valuePerShare: number
    [p: string]: any
}

export const itemInitialState: ItemInitialState = {
    getArtShareList: getArtShareListInitialState,
    getItemList: getItemListInitialState,
    getItemInfo: getItemInfoInitialState,
    addItem: addItemInitialState,
    buyItem: buyItemInitialState,
    deleteItem: deleteItemInitialState
}

export interface ItemInitialState {
    getArtShareList: GetArtShareListState
    getItemList: GetItemListState
    getItemInfo: GetItemInfoState
    addItem: AddItemState
    buyItem: BuyItemState
    deleteItem: DeleteItemState
}

const itemReducer = combineReducers({
    getArtShareList: getArtShareListReducer,
    getItemList: getItemListReducer,
    getItemInfo: getItemInfoReducer,
    addItem: addItemReducer,
    buyItem: buyItemReducer,
    deleteItem: deleteItemReducer
})

export default itemReducer

