import React, { useEffect, useState } from 'react'
import { useController } from 'react-hook-form'
import { Text, TextInput, View } from 'react-native'
import { HelperText } from 'react-native-paper'
import styles from './styles'
import { RequiredIcon } from './Form'
import { Picker } from '@react-native-picker/picker';
import { generateRandomId } from '../../utils'
export interface DropdownValue {
    label: string
    value: any
}

export interface OwnProps {
    name: string
    label?: string
    control?: any
    defaultValue?: string | undefined
    required?: boolean
    error?: string | undefined
    style?: any
    labelStyle?: any
    errorStyle?: any
    formGroupStyle?: any
    containerStyle?: any
    data: DropdownValue[]
    onChangeValue?: (value: string) => void
    shouldHaveSelect?: boolean,
    placeholder?: string
}

export type DropdownComponentProps = OwnProps

const DropdownComponent = (props: OwnProps) => {
    const { name,
        label,
        control,
        defaultValue,
        required,
        error,
        errorStyle,
        style,
        labelStyle,
        formGroupStyle,
        containerStyle,
        onChangeValue,
        shouldHaveSelect,
        placeholder,
        data
    } = props

    if (!control) {
        return <Text>Error</Text>
    }

    const { field, formState: { errors } } = useController({
        control,
        defaultValue,
        name,
        rules: {
            required
        },
        shouldUnregister: true,
    })

    // useEffect(() => {
    //     if (onChangeValue && shouldHaveSelect && !defaultValue && data && data.length > 0) {
    //         onChangeValue(data[0].value)
    //         console.log('selected')
    //     }
    //     console.log('wew')
    // }, [data])

    const [showDropDown, setShowDropDown] = useState(false);

    const _onChangeValue = (value: string) => {
        field.onChange(value)
        if (onChangeValue) onChangeValue(value)
    }
    const [selectedLanguage, setSelectedLanguage] = useState();

    const maxHeight = data.length * 50

    const isError = errors && errors.hasOwnProperty(name)
    return control && (
        <View style={[styles.marginBottomSm, containerStyle]}>
            <View style={[styles.formGroup, formGroupStyle]}>
                {label && <Text style={[styles.fields, labelStyle]}>{label}{required && <RequiredIcon />}</Text>}
                <View>
                    <View style={{ flexDirection: 'row', position: 'relative' }}>
                        <Picker
                            style={[styles.input, style, isError && { borderColor: 'red' }]}
                            selectedValue={field.value}
                            onValueChange={(itemValue, itemIndex) =>
                                _onChangeValue(itemValue)
                            }>
                            <Picker.Item label={placeholder} value='' />
                            {data.map(item => <Picker.Item key={generateRandomId()} label={item.label} value={item.value} />)}
                        </Picker>
                        {/* {type === 'date' ? (
                            <TextInputMask
                                placeholder={props.placeholder}
                                type={'datetime'}
                                options={{
                                    format: props.dateFormat ?? 'dd/mm/yyyy'
                                }}
                                value={field.value}
                                onChangeText={_onChangeValue}
                                style={[styles.input, style, { paddingRight: isPasswordField ? 40 : 0}, isError && { borderColor: 'red' }]}
                                keyboardType={keyboardType}
                                placeholderTextColor={placeholderTextColor}
                            />
                        ) :
                        <TextInput multiline={multiline} placeholderTextColor={placeholderTextColor} placeholder={props.placeholder} value={field.value} onChangeText={_onChangeValue} secureTextEntry={isShowPassword} style={[styles.input, style, { paddingRight: isPasswordField ? 40 : 0}, isError && { borderColor: 'red' }]} keyboardType={keyboardType} />}
                        {isPasswordField && <IconButton
                            icon={eyeIcon}
                            onPress={() => setShowPassword(!isShowPassword)}
                            style={{ position: 'absolute', right: 0 }}
                            size={props.eyeIconSize || 17.5}
                        />} */}
                    </View>
                    {isError &&
                        <HelperText visible type='error' style={[styles.errorStyle, errorStyle]}>
                            {errors[name].message}
                        </HelperText>
                    }
                </View>
            </View>
        </View>
    )
}

export default DropdownComponent