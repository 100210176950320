import * as _ from 'lodash'
import React from 'react'

import { View, ScrollView, Text, TouchableOpacity, Dimensions, Image, Platform, Pressable } from 'react-native'
import { StackNavigationProp } from '@react-navigation/stack'

import { dashboardStyles as styles } from './style'
import { Button, Caption, Headline, HelperText, IconButton, List, Paragraph, Subheading, Title } from 'react-native-paper'

import { connect } from 'react-redux'
import { compose, Dispatch } from 'redux'
import { FormattedMessage } from 'react-intl'
import { ConfigValue, ReduxAppState } from '../../utils/types'
import SideNavContentHOC from '../../hoc/SidenavContent'
import HeaderContentHOC from '../../hoc/HeaderContent'
import { Form, ResponsiveView, Screen } from '../../ReusableComponents'
import * as yup from 'yup'
import { Draggable } from '../../ReusableComponents'
import { retrieveUser } from '../../reducers/User/user'
import { CLEAR_UPDATE_USER, updateUser } from '../../reducers/User/updateUser'
import { AxiosPromise } from 'axios'
import { RouteName } from '../../navigation/types'
import CheckBox from 'react-native-check-box'
import { KYCModal, SuccessModal } from './ProfileModals'

export interface ComponentProps {
    navigation: StackNavigationProp<any>
    isRefreshing: boolean
}

export interface DispatchProps {
    getUserInfo: () => any
    updateUserInfo: (id: string, data: any) => AxiosPromise,
    clearUpdateUser: () => any
}

export interface StateProps {
    config: ConfigValue
    user: any
    updateUserLoading: boolean
    updateUser: any
}

export interface ProfilePageState {
    profilePicId: any
    image: any
    isError: boolean
    isPublic: boolean
    isLoading: boolean
    currentDate: Date
    files: any
    selectedFiles: any
    region: string
    province: string
    cityMunicipality: string
    barangay: string
    resetData: any
    isOpenKYC: boolean
    isSuccessKYC: boolean | undefined
    idFront: any,
    idBack: any,
    idHold: any,
    data: any
    withoutExpirationDate: boolean
}

export type ProfilePageProps = ComponentProps & DispatchProps & StateProps

// const FormSchema = yup.object({
//     name: yup.string().required('Name is required'),
//     lastname: yup.string().required('Lastname is required'),
//     idFront: yup.mixed().required(),
//     idBack: yup.mixed().required(),
//     idHold: yup.mixed().required(),

//     dateOfBirth: yup.string().required('Date of Birth is required'),
//     gender: yup.string().required('Gender is required'),
//     nationality: yup.string().required('Nationality is required'),
//     residence: yup.string().required('Residence is required'),

//     cityMunicipality: yup.string().required('City/Municipality is required'),
//     street: yup.string().required('Street name is required'),
//     barangay: yup.string().required('Barangay is required'),
//     province: yup.string().required('Province is required'),
//     region: yup.string().required('Region is required'),
//     idType: yup.string().required('Governement ID Type is required'),
//     idNumber: yup.string().required('ID Number is required'),
//     expirationDate: yup.string().required('Expiration Date is required'),

//     nickname: yup.string().optional(),
//     mobileNo: yup.string()
//         .required('Mobile Number is required')
//         .matches(/^(09|\+639)\d{9}$/, 'Not a valid mobile number'),
// })

const schemaKYC = {
    name: yup.string().required('Name is required'),
    lastname: yup.string().required('Lastname is required'),
    idFront: yup.mixed().required(),
    idBack: yup.mixed().required(),
    idHold: yup.mixed().required(),

    dateOfBirth: yup.string().required('Date of Birth is required'),
    gender: yup.string().required('Gender is required'),
    nationality: yup.string().required('Nationality is required'),
    residence: yup.string().required('Residence is required'),

    cityMunicipality: yup.string().required('City/Municipality is required'),
    street: yup.string().required('Street name is required'),
    barangay: yup.string().required('Barangay is required'),
    province: yup.string().required('Province is required'),
    region: yup.string().required('Region is required'),
    idType: yup.string().required('Governement ID Type is required'),
    idNumber: yup.string().required('ID Number is required'),
    // expirationDate: yup.string().required('Expiration Date is required'),
}

const schemaDoneKYC = {
    gender: yup.string().optional(),
    nationality: yup.string().optional(),
    residence: yup.string().optional(),
    nickname: yup.string().optional(),
    mobileNo: yup.string()
        .required('Mobile Number is required')
        .matches(/^(09|\+639)\d{9}$/, 'Not a valid mobile number'),
}

class ProfilePage extends React.Component<ProfilePageProps, ProfilePageState> {
    idFront: any
    idBack: any
    idHold: any
    image: any
    topPage: any
    constructor(props: ProfilePageProps) {
        super(props)
        this.state = {
            profilePicId: {},
            image: undefined,
            isError: false,
            isPublic: false,
            isLoading: false,
            currentDate: new Date(),
            files: [],
            selectedFiles: [],
            region: '',
            province: '',
            cityMunicipality: '',
            barangay: '',
            resetData: {},
            isOpenKYC: false,
            isSuccessKYC: false,
            idFront: {},
            idBack: {},
            idHold: {},
            data: undefined,
            withoutExpirationDate: false,
        }

        this.idFront = React.createRef()
        this.idBack = React.createRef()
        this.idHold = React.createRef()
        this.topPage = React.createRef()
    }

    componentDidMount() {
        this.props.getUserInfo()
        this.props.clearUpdateUser()
        if (this.props.user.hasOwnProperty('isPublic')) {
            this.setState({
                isPublic: this.props.user?.isPublic === 1 ? true : false
            })
        }
    }

    onHandleKYC = (isSuccess: boolean) => {
        this.setState({ isSuccessKYC: isSuccess, isOpenKYC: false })
    }

    onCloseKYC = () => {
        this.setState({ isOpenKYC: false })
    }

    onSubmit = (data: any) => {
        this.setState({ isError: false })
        const _data = {
            userAttributes: {
                ...data,
                userId: this.props.user?.id,
                isPublic: this.state.isPublic ? 1 : 0,
                expirationDate: data.expirationDate
            },
        }

        const payload = { ..._data, ...this.state }
        if (!this.image) {
            delete payload['profilePicId']
        }

        if (this.props.user?.isDoneKYC) {
            this.onUpdateUser(payload)
        } else {
            this.setState({ isOpenKYC: true, data: payload })
        }

    }

    onUpdateUser = (payload: any) => {
        this.setState({ isLoading: true })
        this.props.updateUserInfo(this.props.user?.id, payload)
            .then(async () => {
                await this.props.getUserInfo()
                    .then(() => {
                        if (this.props.user.hasOwnProperty('isPublic')) {
                            this.setState({
                                isPublic: this.props.user?.isPublic === 1 ? true : false
                            })
                        }
                    })

                this.setState({ isLoading: false, isSuccessKYC: !this.props.user?.isDoneKYC, isOpenKYC: false })
                this.topPage.scrollTo({ x: 0, y: 0, animated: true })
                setTimeout(this.props.clearUpdateUser, 5000)
            })
            .catch(() => {
                this.setState({ isLoading: false, isSuccessKYC: undefined, isOpenKYC: false })
            })
    }

    fetchDropDowns = (picker: string) => {
        let _ddl: any[] = []
        const { country, gender, nationality, locations } = this.props.config
        if (picker === 'country') {
            _ddl = country.map((item: any) => ({
                label: item.name,
                value: item.name
            }))
        } else if (picker === 'region') {

            _.forIn(locations, (value: string, key: string) => {
                const _temp = {
                    label: key,
                    value: key
                }
                _ddl.push(_temp)
            })
            return _ddl
        } else if (picker === 'gender') {
            _ddl = gender.map((item: any) => ({
                label: item.label,
                value: item.value
            }))
        } else if (picker === 'nationality') {
            _ddl = nationality.map((item: any) => ({
                label: item.nationality,
                value: item.nationality
            }))
        } else if (picker === 'province' && Boolean(this.state.region)) {

            _.forIn(locations[String(this.state.region)]['province_list'], (value: string, key: string) => {
                const _temp = {
                    label: key,
                    value: key
                }
                _ddl.push(_temp)
            })
            return _ddl
        } else if (picker === 'cityMunicipality' && Boolean(this.state.region) && Boolean(this.state.province)) {
            _.forIn(locations[String(this.state.region)]['province_list'][this.state.province]['municipality_list'], (value: string, key: string) => {
                const _temp = {
                    label: key,
                    value: key
                }
                _ddl.push(_temp)
            })
            return _ddl
        } else if (picker === 'barangay' && Boolean(this.state.province) && Boolean(this.state.cityMunicipality)) {
            const _barangay = locations[String(this.state.region)]['province_list'][this.state.province]['municipality_list'][this.state.cityMunicipality]['barangay_list']
            if (Array.isArray(_barangay)) {
                _ddl = _barangay.map((item: any) => ({
                    label: item,
                    value: item
                }))
            }
        } else if (picker === 'residence') {
            _ddl = nationality.map((item: any) => ({
                label: item.en_short_name,
                value: item.en_short_name
            }))
        } return _ddl

    }

    onFileChange = (file: any, name: string, onChange?: (value: any) => any) => {
        const reader = new FileReader()
        reader.onload = (e) => {
            if (name === 'profilePicId') {
                this.image = e.target?.result
            }

            const _value = {
                fileName: file.name,
                decodedFile: e.target?.result.split(',')[1],
                type: file.type
            }

            this.setState({
                [name]: _value
            })
            if (onChange) {
                onChange(_value)
            }
        }
        reader.readAsDataURL(file)
    }

    onChangeCheckbox = () => {
        this.setState({ isPublic: !this.state.isPublic })
    }

    onCancel = () => {
        this.props.navigation.navigate(RouteName.DashboardPage)
    }

    render() {
        const { user } = this.props
        const { name, lastname, dateOfBirth, gender, nationality, residence, nickname, mobileNo, ccy, timeZone, language, bio } = user
        let formSchema: any = user?.isDoneKYC ? schemaDoneKYC : schemaKYC
        if (!user?.isDoneKYC && !this.state.withoutExpirationDate) {
            formSchema = { ...formSchema, expirationDate: yup.string().required('Expiration Date is required') }
        }
        return (
            <ScrollView ref={ref => (this.topPage = ref)}>
                <ResponsiveView width={'80%'} viewStyle={{ alignSelf: 'center' }}>
                    <Form formSchema={yup.object(formSchema)} formStyle={{ marginBottom: 50 }}>
                        {this.props.updateUser?.statusText && (
                            <View style={{ marginHorizontal: '10%' }}>
                                {this.props.updateUser?.statusText === 'success' ? (
                                    <View style={[styles.alertMessage, { borderColor: '#5CC858', backgroundColor: '#5CC858' }]} >
                                        <Text style={{ color: 'white', fontSize: 16, fontFamily: "'Montserrat', sans-serif" }}>
                                            <FormattedMessage id='Profile.msg.updateSuccessfully' />
                                        </Text>
                                    </View>
                                ) : (
                                    <View style={[styles.alertMessage, { borderColor: 'red', backgroundColor: '#FF3030' }]} >
                                        <Text style={{ color: 'white', fontSize: 16, fontFamily: "'Montserrat', sans-serif" }}>
                                            <FormattedMessage id='Profile.msg.somethingWentWrong' />
                                        </Text>
                                    </View>
                                )}
                            </View>
                        )}

                        <View style={{}}>
                            <View style={{ marginBottom: '2%', alignSelf: 'center' }} >
                                <Pressable onPress={() => this.idFront.current?.click()} style={{ flexDirection: 'row' }} >
                                    <View style={{ borderRadius: 80, borderColor: '#F5BF32', overflow: 'hidden', borderWidth: 3 }}>
                                        {this.image ? (
                                            <Image style={{ width: 120, height: 120, borderRadius: 80 }} source={{ uri: this.image }} resizeMode='contain' />
                                        ) : this.props.user?.profilePicThumbnail ? (
                                            <Image style={{ width: 120, height: 120, borderRadius: 80 }} source={{ uri: this.props.user?.profilePicThumbnail }} resizeMode='contain' />
                                        ) : (
                                            <Image style={{ width: 120, height: 120, borderRadius: 80 }} source={require('../../assets/user-profile.png')} resizeMode='contain' />
                                        )}
                                    </View>
                                    <IconButton onPress={() => this.idFront.current?.click()} icon={'camera'} color={'#FFFFFF'} style={{ width: 30, height: 30, borderRadius: 80, borderColor: '#F5BF32', borderWidth: 2, marginLeft: '-20%', marginTop: '55%', backgroundColor: '#F5BF32' }} />
                                </Pressable>
                            </View>

                            {Platform.OS === 'web' && <input type="file" name="file" onChange={(file: any) => this.onFileChange(file?.target?.files[0] ?? undefined, 'profilePicId')} ref={this.idFront} style={{ position: 'fixed', display: 'none' }} />}

                            <View style={{ flexDirection: 'row', flexWrap: 'wrap', alignSelf: 'center' }}>
                                <Form.Field disabled={user?.isDoneKYC} name='name' defaultValue={name} placeholder='Name' type='textinput' style={[styles.input]} placeholderTextColor='#B3B3B3' />
                                <Form.Field disabled={user?.isDoneKYC} name='lastname' defaultValue={lastname} placeholder='Last name' type='textinput' style={[styles.input]} placeholderTextColor='#B3B3B3' />
                                <Form.Field disabled={user?.isDoneKYC} name='dateOfBirth' defaultValue={dateOfBirth} placeholder='Date of Birth (DD/MM/YYYY)' type='date' style={[styles.input]} placeholderTextColor='#B3B3B3' />
                            </View>

                            <View style={{ flexDirection: 'row', flexWrap: 'wrap', alignSelf: 'center' }}>
                                <Form.Dropdown
                                    name='gender'
                                    style={[styles.input]}
                                    placeholder='Gender'
                                    defaultValue={gender}
                                    data={this.fetchDropDowns('gender')} />
                                <Form.Dropdown
                                    name='nationality'
                                    style={[styles.input]}
                                    placeholder='Nationality'
                                    defaultValue={nationality}
                                    data={this.fetchDropDowns('nationality')} />
                                <Form.Dropdown
                                    name='residence'
                                    style={[styles.input]}
                                    placeholder='Residence'
                                    defaultValue={residence}
                                    data={this.fetchDropDowns('residence')} />
                            </View>
                            {user?.isDoneKYC ? (
                                <React.Fragment>
                                    <View style={{ flexDirection: 'row', flexWrap: 'wrap', alignSelf: 'center' }}>
                                        <Form.Field name='nickname' defaultValue={nickname} placeholder='Nickname' type='textinput' style={[styles.input]} placeholderTextColor='#B3B3B3' />
                                        <Form.Field name='mobileNo' defaultValue={mobileNo} placeholder='Mobile Number' type='textinput' style={[styles.input]} placeholderTextColor='#B3B3B3' />
                                        <View style={{ width: 330 }} />
                                    </View>

                                    <View style={{ flexDirection: 'row', flexWrap: 'wrap', alignSelf: 'center' }}>
                                        <Form.Dropdown
                                            name='ccy'
                                            style={[styles.input]}
                                            placeholder='Currency'
                                            defaultValue={ccy}
                                            data={this.fetchDropDowns('gender')} />
                                        <Form.Dropdown
                                            name='timeZone'
                                            style={[styles.input]}
                                            placeholder='Time Zone'
                                            defaultValue={timeZone}
                                            data={this.fetchDropDowns('gender')} />
                                        <Form.Dropdown
                                            name='language'
                                            style={[styles.input]}
                                            placeholder='Language'
                                            defaultValue={language}
                                            data={this.fetchDropDowns('gender')} />
                                    </View>

                                    <View>
                                        <Screen minWidth={1200} style={{ alignItems: 'center' }}>
                                            <Form.Field name='bio' defaultValue={bio} multiline={true} placeholder='Your bio' type='textinput' style={[styles.input, { width: 950, height: 100 }]} placeholderTextColor='#B3B3B3' />
                                        </Screen>
                                        <Screen minWidth={992} maxWidth={1199}>
                                            <Form.Field name='bio' defaultValue={bio} multiline={true} placeholder='Your bio' type='textinput' style={[styles.input, { width: 950, height: 100 }]} placeholderTextColor='#B3B3B3' />
                                        </Screen>
                                        <Screen.Tablet>
                                            <Form.Field name='bio' defaultValue={bio} multiline={true} placeholder='Your bio' type='textinput' style={[styles.input, { width: 750, height: 100 }]} placeholderTextColor='#B3B3B3' />
                                        </Screen.Tablet>
                                        <Screen.Mobile>
                                            <Form.Field name='bio' defaultValue={bio} multiline={true} placeholder='Your bio' type='textinput' style={[styles.input, { width: 300, height: 100 }]} placeholderTextColor='#B3B3B3' />
                                        </Screen.Mobile>
                                        {Platform.OS === 'web' ? (
                                            <View style={{ flexDirection: 'row', marginLeft: '2%', alignSelf: 'center' }}>
                                                <input type="checkbox"
                                                    key={Math.random()}
                                                    defaultChecked={this.state.isPublic}
                                                    onChange={() => this.onChangeCheckbox()}
                                                />
                                                <Text style={{ fontFamily: "'Montserrat', sans-serif" }}>Show my portfolio to other users</Text>
                                            </View>) :
                                            <CheckBox
                                                onClick={() => {
                                                    // empty
                                                }}
                                                isChecked={this.state.isPublic}
                                                rightText={'Show my portfolio to other users'}
                                            />
                                        }
                                    </View>
                                </React.Fragment>
                            ) : (
                                <React.Fragment>
                                    <Screen minWidth={800} style={{ flexDirection: 'row', flexWrap: 'wrap', alignSelf: 'center' }}>
                                        <Form.Field multiline name='street' defaultValue={nickname} placeholder='Unit/House No./Apartment No. Street Name' type='textinput' style={[styles.input, { width: 620 }]} placeholderTextColor='#B3B3B3' />
                                        <Form.Field multiline name='barangay' defaultValue={nickname} placeholder='Barangay' type='textinput' style={[styles.input]} placeholderTextColor='#B3B3B3' />
                                    </Screen>

                                    <Screen maxWidth={799} style={{ flexDirection: 'row', flexWrap: 'wrap', alignSelf: 'center' }}>
                                        <Form.Field multiline name='street' defaultValue={nickname} placeholder='Unit/House No./Apartment No. Street Name' type='textinput' style={[styles.input]} placeholderTextColor='#B3B3B3' />
                                        <Form.Field multiline name='barangay' defaultValue={nickname} placeholder='Barangay' type='textinput' style={[styles.input]} placeholderTextColor='#B3B3B3' />
                                    </Screen>
                                    <View style={{ flexDirection: 'row', flexWrap: 'wrap', alignSelf: 'center' }}>
                                        <Form.Field multiline name='cityMunicipality' defaultValue={nickname} placeholder='City/Municipality' type='textinput' style={[styles.input]} placeholderTextColor='#B3B3B3' />
                                        <Form.Field multiline name='province' defaultValue={nickname} placeholder='Province' type='textinput' style={[styles.input]} placeholderTextColor='#B3B3B3' />
                                        <Form.Field multiline name='region' defaultValue={nickname} placeholder='Region' type='textinput' style={[styles.input]} placeholderTextColor='#B3B3B3' />
                                    </View>

                                    <View style={{ flexDirection: 'row', flexWrap: 'wrap', alignSelf: 'center' }}>
                                        <Form.Field name='idType' defaultValue={nickname} placeholder='Government ID type' type='textinput' style={[styles.input]} placeholderTextColor='#B3B3B3' />
                                        <Form.Field name='idNumber' defaultValue={mobileNo} placeholder='ID Number' type='textinput' style={[styles.input]} placeholderTextColor='#B3B3B3' />
                                        <View>
                                            <Form.Field disabled={this.state.withoutExpirationDate} name='expirationDate' placeholder='Expiration Date (DD/MM/YYYY)' type='date' style={[styles.input]} placeholderTextColor='#B3B3B3' />
                                            {Platform.OS === 'web' ? (
                                                <View style={{ flexDirection: 'row', marginLeft: '1.2rem', marginBottom: '1rem' }}>
                                                    <input type="checkbox"
                                                        defaultChecked={this.state.withoutExpirationDate}
                                                        onChange={() => this.setState({ withoutExpirationDate: !this.state.withoutExpirationDate })}
                                                    />
                                                    <Text style={{ fontFamily: "'Montserrat', sans-serif", marginLeft: '.3rem' }}>without expiration date</Text>
                                                </View>) :
                                                <CheckBox
                                                    onClick={() => {
                                                        this.setState({ withoutExpirationDate: !this.state.withoutExpirationDate })
                                                    }}
                                                    isChecked={this.state.withoutExpirationDate}
                                                    rightText={'without expiration date'}
                                                />
                                            }
                                        </View>
                                    </View>

                                    {/* Not supported on mobile*/}
                                    <View style={{ flexDirection: 'row', flexWrap: 'wrap', alignSelf: 'center', gap: '2.5rem' }}>
                                        <Form.CustomField name='idFront' customField={(value, onChange, error) => {
                                            return (<React.Fragment>{Platform.OS === 'web' && <input type="file" name="file" onChange={(file: any) => this.onFileChange(file?.target?.files[0] ?? undefined, 'idFront', onChange)} ref={this.idFront} style={{ position: 'fixed', top: -100, display: 'none' }} />}
                                                <Pressable onPress={() => this.idFront.current?.click()}><Draggable handleDrop={(file: any) => this.onFileChange(file, 'idFront', onChange)} style={{ borderRadius: 8, padding: 10, borderColor: error ? 'red' : '#D4DADF', borderWidth: 1, width: 290, height: 250, placeContent: 'center' }}>
                                                    <Text style={{ textAlign: 'center' }}>Upload Government ID (Front)</Text>
                                                    <Caption style={{ textAlign: 'center' }}>{value?.fileName}</Caption>
                                                </Draggable>
                                                </Pressable>
                                            </React.Fragment>
                                            )
                                        }} />
                                        <Form.CustomField name='idBack' customField={(value, onChange, error) => {
                                            return (<React.Fragment>
                                                {Platform.OS === 'web' && <input type="file" name="file" onChange={(file: any) => this.onFileChange(file?.target?.files[0] ?? undefined, 'idBack', onChange)} ref={this.idBack} style={{ position: 'fixed', top: -100, display: 'none' }} />}
                                                <Pressable onPress={() => this.idBack.current?.click()}><Draggable handleDrop={(file: any) => this.onFileChange(file, 'idBack', onChange)} style={{ borderRadius: 8, padding: 10, borderColor: error ? 'red' : '#D4DADF', borderWidth: 1, width: 290, height: 250, placeContent: 'center' }}>
                                                    <Text style={{ textAlign: 'center' }}>Upload Government ID (Back)</Text>
                                                    <Caption style={{ textAlign: 'center' }}>{value?.fileName}</Caption>
                                                </Draggable>
                                                </Pressable>
                                            </React.Fragment>
                                            )
                                        }} />
                                        <Form.CustomField name='idHold' customField={(value, onChange, error) => {
                                            return (<React.Fragment>
                                                {Platform.OS === 'web' && <input type="file" name="file" onChange={(file: any) => this.onFileChange(file?.target?.files[0] ?? undefined, 'idHold', onChange)} ref={this.idHold} style={{ position: 'fixed', top: -100, display: 'none' }} />}
                                                <Pressable onPress={() => this.idHold.current?.click()}><Draggable handleDrop={(file: any) => this.onFileChange(file, 'idHold', onChange)} style={{ borderRadius: 8, padding: 10, borderColor: error ? 'red' : '#D4DADF', borderWidth: 1, width: 290, height: 250, placeContent: 'center' }}>
                                                    <Text style={{ textAlign: 'center' }}>Upload photo holding your ID</Text>
                                                    <Caption style={{ textAlign: 'center' }}>{value?.fileName}</Caption>
                                                </Draggable>
                                                </Pressable>
                                            </React.Fragment>
                                            )
                                        }} />
                                    </View>
                                </React.Fragment>
                            )}

                            <View style={{ flexDirection: 'row', flexWrap: 'wrap', marginTop: '5%', alignSelf: 'center' }}>
                                <Form.Submit loading={this.state.isLoading} disabled={this.state.isLoading} onPress={(data: any) => this.onSubmit(data)} title='SAVE' style={{ backgroundColor: '#F7931E', padding: 5, borderRadius: 0, marginRight: 10, marginBottom: 10 }} labelStyle={{ color: 'white', fontSize: 12 }} />
                                <Button onPress={() => this.onCancel()} disabled={this.state.isLoading} style={{ backgroundColor: '#000000', padding: 5, borderRadius: 0, marginBottom: 10 }}><Text style={{ color: 'white', fontSize: 12 }}>CANCEL</Text></Button>
                            </View>

                        </View>
                    </Form>
                </ResponsiveView>
                <SuccessModal onHandleKYC={this.onHandleKYC} isOpen={this.state.isSuccessKYC} />
                <KYCModal isLoading={this.state.isLoading} data={this.state.data} onSubmit={this.onUpdateUser} onHandleKYC={this.onHandleKYC} isOpen={this.state.isOpenKYC} onRequestClose={this.onCloseKYC} />
            </ScrollView >
        )
    }
}

const mapStateToProps = (state: ReduxAppState) => {
    const _authUserInfo = state.api?.user?.authUser
    return ({
        config: state.config,
        user: _authUserInfo.response,
        updateUser: state.api.user?.updateUser,
        updateUserLoading: state.api.user?.updateUser?.loading,
    })
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
    getUserInfo: (() => retrieveUser(dispatch)),
    updateUserInfo: ((id: string, data: any) => updateUser(dispatch, id, data)),
    clearUpdateUser: () => dispatch({ type: CLEAR_UPDATE_USER })
})

export default connect(mapStateToProps, mapDispatchToProps)(compose(HeaderContentHOC, SideNavContentHOC)(ProfilePage as any))