import {StackNavigationProp} from '@react-navigation/stack'
import {AxiosPromise, AxiosResponse} from 'axios'
import React from 'react'
import {FormattedMessage, FormattedNumber, injectIntl} from 'react-intl'
import {Image, Linking, Pressable, Text, View} from 'react-native'
import {
  ActivityIndicator,
  Button,
  Headline,
  IconButton,
  Paragraph,
  Title,
} from 'react-native-paper'
import {connect} from 'react-redux'
import {compose, Dispatch} from 'redux'
import {RouteName} from '../../navigation/types'
import {Items} from '../../reducers/Items'
import {getArtShareList} from '../../reducers/Items/getArtShareList'
import {getItemInfo} from '../../reducers/Items/getItemInfo'
import {User} from '../../reducers/User'
import {
  getPortfolioOfUser,
  GetPortfolioOfUserState,
} from '../../reducers/User/getPortfolioOfUser'
import {generateRandomId} from '../../utils'
import {ReduxAppState} from '../../utils/types'
import Buy from '../Buy/Buy'
import {artShareStyles as styles, modalCustomStyles} from './styles'
import {isEqual} from 'lodash'
import {ResponsiveView, Screen} from '../../ReusableComponents'
import {useMediaQuery} from 'react-responsive'
import {TouchableOpacity} from 'react-native-gesture-handler'
import Modal from 'react-modal'
import {updateShowModal} from '../../reducers/Modal/showModal'

export interface ComponentProps {
  navigation: StackNavigationProp<any>
}

export interface DispatchProps {
  getArtShareList: () => any
  getItemInfo: (id: string) => AxiosPromise
  getPortolioItem: (userId: string) => any
  updateShowModal: () => any
}

export interface StateProps {
  user: User
  portfolioOfUser: GetPortfolioOfUserState
  buyItem: any
  openModal: boolean
}

export interface ArtSharePageState {
  items: any
  isBuy: boolean
}

const TableView = (props: any) => {
  const isDesktop = useMediaQuery({query: '(min-width: 987px)'})
  return (
    <ResponsiveView width={isDesktop ? '75%' : '100%'}>
      {props.children}
    </ResponsiveView>
  )
}

export type ArtSharePageProps = ComponentProps & DispatchProps & StateProps

class ArtShareComponent extends React.Component<
  ArtSharePageProps,
  ArtSharePageState
> {
  constructor(props: ArtSharePageProps) {
    super(props)
    this.state = {
      items: [],
      isBuy: false,
    }
  }

  componentDidMount() {
    this.props.getPortolioItem(this.props.user?.sub)
  }

  componentDidUpdate(prevProps: ArtSharePageProps) {
    if (!isEqual(prevProps.buyItem, this.props.buyItem)) {
      this.props.getPortolioItem(this.props.user?.sub)
    }
  }

  navigateScreen = () => {
    this.props.navigation.navigate(RouteName.ItemDetailPage)
  }

  getItemInfo = (item: string) => {
    this.props.getItemInfo(item)
    this.navigateScreen()
    this.closeModal()
  }

  getInitial = (name: string) => {
    const nameSplit = name.split(' ')
    return nameSplit
      .map(a => a[0])
      .reduce((prev, curr) => (prev += curr))
      .toUpperCase()
  }

  onBuyItem = (item: string) => {
    this.props.getItemInfo(item)
    this.setState({
      isBuy: true,
    })
  }

  closeModal = () => {
    this.setState({isBuy: false})
  }

  onSubmit = () => {
    this.setState({isBuy: false})
  }

  getShares(numberOfShare: number, totalShare: number) {
    return numberOfShare / totalShare
  }

  onClose = () => {
    this.props.updateShowModal()
  }

  render() {
    const {isBuy} = this.state
    const {portfolioOfUser} = this.props
    return (
      <React.Fragment>
        {portfolioOfUser?.statusText === 'error' && (
          <Text>
            <FormattedMessage id="ArtShare.msg.somethingWentWrong" />
          </Text>
        )}
        <Screen minWidth={769} style={{width: '100%'}}>
          <TableView>
            <View key={generateRandomId()} style={styles.tableHeader}>
              <View style={[styles.rowHeader, {width: '35%', maxWidth: 400}]}>
                <View style={[styles.rowTextContainer]}>
                  <Headline
                    style={[
                      styles.normalText,
                      styles.boldText,
                      styles.upperCaseText,
                      {fontSize: 18, marginLeft: 8},
                    ]}>
                    <FormattedMessage id="ArtShare.msg.artwork" />
                  </Headline>
                </View>
              </View>
              <View style={styles.rowHeader}>
                <View style={[styles.rowTextContainer]}>
                  <Headline
                    style={[
                      styles.normalText,
                      styles.boldText,
                      styles.upperCaseText,
                      {textAlign: 'center', fontSize: 18},
                    ]}>
                    Listing Value
                  </Headline>
                </View>
              </View>
              <View style={styles.rowHeader}>
                <View style={[styles.rowTextContainer]}>
                  <Headline
                    style={[
                      styles.normalText,
                      styles.boldText,
                      styles.upperCaseText,
                      {textAlign: 'center', fontSize: 18},
                    ]}>
                    Shares
                  </Headline>
                </View>
              </View>
              <View style={{width: '25%'}}></View>
            </View>
          </TableView>
          <TableView>
            {portfolioOfUser?.loading ? (
              <ActivityIndicator
                animating={true}
                color="#AFCD37"
                size="large"
              />
            ) : (
              portfolioOfUser?.response?.map((item: any) => {
                return (
                  <Pressable
                    onPress={() => this.getItemInfo(item.id)}
                    key={generateRandomId()}
                    style={styles.table}>
                    <View
                      style={[
                        styles.row,
                        {width: '35%', maxWidth: 400, height: 140},
                      ]}>
                      <View
                        style={[
                          styles.row,
                          {width: '100%', height: '100%', position: 'relative'},
                        ]}>
                        <View style={{flexDirection: 'row'}}>
                          <View style={{height: 80, width: 80}}>
                            <Image
                              source={{uri: item?.imageUrl}}
                              style={{height: '100%', width: '100%'}}
                            />
                            <Text
                              style={[
                                styles.normalText,
                                styles.boldText,
                                {
                                  textAlign: 'center',
                                  fontSize: 16,
                                  marginTop: 5,
                                },
                              ]}>
                              {this.getInitial(item?.artist ?? '')}
                            </Text>
                          </View>
                          <View style={{marginLeft: 8, width: 180}}>
                            <Title
                              numberOfLines={2}
                              style={[
                                styles.boldText,
                                {fontSize: 18, flexShrink: 1, lineHeight: 25},
                              ]}>
                              {item?.title}
                            </Title>
                            <Text
                              numberOfLines={2}
                              style={[
                                styles.italicText,
                                {fontSize: 16, flexShrink: 1},
                              ]}>{`${item?.artist}, ${item?.year}`}</Text>
                          </View>
                        </View>
                      </View>
                      <View
                        style={{borderRightWidth: 1, borderColor: '#9D9D9D'}}
                      />
                    </View>
                    <View style={styles.row}>
                      <View style={styles.rowTextContainer}>
                        <Paragraph
                          style={[
                            styles.boldText,
                            {textAlign: 'center', fontSize: 18},
                          ]}>
                          <FormattedNumber
                            format="USD"
                            value={Number(item.listedValue)}
                          />
                        </Paragraph>
                      </View>
                      <View
                        style={{borderRightWidth: 1, borderColor: '#9D9D9D'}}
                      />
                    </View>
                    <View style={styles.row}>
                      <View style={styles.rowTextContainer}>
                        <Text
                          style={[
                            styles.boldText,
                            {textAlign: 'center', fontSize: 18},
                          ]}>
                          <FormattedNumber
                            format="USD"
                            value={item.portfolio.totalValue}
                          />
                          (
                          <FormattedNumber
                            format="percent"
                            style="percent"
                            maximumFractionDigits={2}
                            value={this.getShares(
                              item.portfolio.numberOfShare,
                              item.numberOfShare,
                            )}
                          />
                          )
                          {/* <Text style={{ color: 'grey', fontFamily: "'Montserrat', sans-serif", fontSize: 18, textAlign: 'center' }}>{` (${this.getShares(item.portfolio.numberOfShare, item.numberOfShare)}%)`}</Text> */}
                        </Text>
                      </View>
                      <View
                        style={{borderRightWidth: 1, borderColor: '#9D9D9D'}}
                      />
                    </View>
                    <View style={[styles.row, {width: '25%'}]}>
                      <View
                        style={{
                          alignSelf: 'center',
                          flexDirection: 'row',
                          flexWrap: 'wrap',
                          width: '100%',
                          alignItems: 'center',
                          justifyContent: 'space-evenly',
                        }}>
                        <View style={{width: 120}}>
                          <Button
                            onPress={() => this.getItemInfo(item.id)}
                            mode="outlined"
                            labelStyle={{fontSize: 10}}
                            color="black">
                            DETAILS
                          </Button>
                        </View>
                        <View style={{width: 120}}>
                          <Button
                            onPress={() => this.onBuyItem(item.id)}
                            mode="contained"
                            color="#F7931E"
                            labelStyle={{color: 'white', fontSize: 10}}>
                            Add Share
                          </Button>
                        </View>
                      </View>
                    </View>
                  </Pressable>
                )
              })
            )}
          </TableView>
        </Screen>
        <Screen maxWidth={768}>
          <ResponsiveView width={'90%'}>
            {portfolioOfUser?.loading ? (
              <ActivityIndicator
                animating={true}
                color="#AFCD37"
                size="large"
              />
            ) : (
              portfolioOfUser?.response?.map((item: any) => {
                return (
                  <TouchableOpacity
                    key={generateRandomId()}
                    onPress={() => this.getItemInfo(item?.id)}>
                    <View
                      style={[
                        styles.row,
                        {
                          width: '100%',
                          justifyContent: 'space-between',
                          flexWrap: 'wrap',
                          borderWidth: 1,
                          marginBottom: 10,
                        },
                      ]}>
                      <View style={{flexDirection: 'row'}}>
                        <View style={{height: 80, width: 80}}>
                          <View style={{height: '90%', width: '90%'}}>
                            <Image
                              source={{uri: item?.imageUrl}}
                              style={{height: '100%', width: '100%'}}
                            />
                          </View>
                          <View>
                            <Text
                              style={[
                                styles.normalText,
                                styles.boldText,
                                {
                                  textAlign: 'center',
                                  fontSize: 16,
                                  marginTop: 5,
                                },
                              ]}>
                              {this.getInitial(item?.artist ?? '')}
                            </Text>
                          </View>
                        </View>
                        <View style={{marginLeft: 8}}>
                          <Title style={[styles.boldText, {fontSize: 20}]}>
                            {item?.title}
                          </Title>
                          <Text
                            style={[
                              styles.italicText,
                              {fontSize: 17},
                            ]}>{`${item?.artist}, ${item?.year}`}</Text>
                        </View>
                      </View>
                      <Screen minWidth={375}>
                        <Text style={{fontWeight: 'bold'}}>
                          <FormattedMessage id="ActiveList.msg.listValue" />
                        </Text>
                        <Text>
                          <FormattedNumber
                            format={item?.currency ?? 'USD'}
                            value={item?.listedValue}
                          />
                        </Text>
                        <Text style={{fontWeight: 'bold'}}>Shares</Text>
                        <Text>{` (${this.getShares(
                          item.portfolio.numberOfShare,
                          item.numberOfShare,
                        )}%)`}</Text>
                      </Screen>
                    </View>
                  </TouchableOpacity>
                )
              })
            )}
          </ResponsiveView>
        </Screen>
        <Buy
          showModal={isBuy}
          closeModal={this.closeModal}
          getItemInfo={this.getItemInfo}
          navigation={this.props.navigation}
          isDetailsPage={false}
        />

        {/* Beta Modal  */}
        <Modal
          isOpen={this.props.openModal}
          onRequestClose={this.onClose}
          style={modalCustomStyles}>
          <View style={{position: 'absolute', right: 0, top: 0, zIndex: 1}}>
            <IconButton icon="close" size={20} onPress={this.onClose} />
          </View>
          <View style={{width: 480, height: 250, alignItems: 'center'}}>
            <View
              style={{
                flexDirection: 'column',
                margin: 20,
                alignItems: 'center',
              }}>
              <Text
                style={[
                  styles.modalText,
                  {
                    marginBottom: 40,
                    marginTop: 20,
                    fontSize: 18,
                    fontWeight: 'bold',
                  },
                ]}>{`Hello there!`}</Text>
              <Text
                style={[
                  styles.modalText,
                ]}>{`ArtShare is on beta and some features are currently limited.`}</Text>
              <Text style={styles.modalText}>
                {`Please email us at `}
                <TouchableOpacity
                  onPress={() =>
                    Linking.openURL('mailto:support@artmutual.io')
                  }>
                  <Text style={[styles.modalText, {fontWeight: 'bold'}]}>
                    support@artmutual.io
                  </Text>
                </TouchableOpacity>
                <Text style={styles.modalText}>{` for inquiries. `}</Text>
              </Text>
            </View>
            <View style={{flexDirection: 'row', marginTop: 40}}>
              <Button
                onPress={() => this.onClose()}
                mode="contained"
                color="#F7931E"
                labelStyle={{
                  fontSize: 12,
                  color: '#FFFFFF',
                  fontFamily: "'Montserrat', sans-serif",
                }}
                style={{margin: 5}}>
                I Understand
              </Button>
            </View>
          </View>
        </Modal>
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state: ReduxAppState) => {
  return {
    portfolioOfUser: state.api?.user.getPortfolioOfUser,
    user: state.api?.user?.authUser?.response,
    buyItem: state?.api?.items?.buyItem?.response,
    openModal: state.api?.modal?.activeModal?.showModal,
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
  getArtShareList: () => getArtShareList(dispatch),
  getItemInfo: (id: string) => getItemInfo(dispatch, id),
  getPortolioItem: (userId: string) => getPortfolioOfUser(dispatch, userId),
  updateShowModal: () => dispatch(updateShowModal()),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(compose(injectIntl(ArtShareComponent as any)))
