import React from 'react'
import { Text, TouchableOpacity } from 'react-native'
import { IconButton } from 'react-native-paper'
import { SideNavStyles as styles } from '../styles'

export const MenuItem = ({ ...props }) => {
  const { title, activeMenuItem, icon, onPress } = props
  return (
    <TouchableOpacity
      style={styles.menu}
      {...props}>
      <IconButton onPress={onPress} icon={icon} size={25} color={activeMenuItem ? '#000000' : '#9D9D9D'} />
      <Text style={activeMenuItem ? styles.activeMenuText : styles.menuText}> {title} </Text>
    </TouchableOpacity>
  )
}

export default MenuItem