import { Platform, StyleSheet } from 'react-native'
import { black } from 'react-native-paper/lib/typescript/styles/colors'
import { theme } from '../../core/theme'
import cpebColors from '../../utils/colors'

export const termStyles = StyleSheet.create({
  paragraphTitle: {
    marginVertical: 10,
    color: '#F8AC60',
    fontSize: 20,
    fontFamily: "'Montserrat', sans-serif",
    fontWeight: '700'
  },
  paragraphContainer: {
    padding: 10
  },
  text: {
    textAlign: 'justify',
    fontFamily: "'Montserrat', sans-serif",
    fontSize: 16,
    marginVertical: 10
  }
})

export const modalCustomStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  }
}

