import React from 'react'

import ImageGallery from 'react-image-gallery'
import ReactImageMagnify from 'react-image-magnify'
import 'react-image-gallery/styles/css/image-gallery.css'

export interface ComponentProps {
    otherImages: any
}

export interface StateProps {
}

export interface DispatchProps {
}

export interface ImageGalleryState {
}

export type ImageGalleryProps = ComponentProps & StateProps & DispatchProps

class MyImageGallery extends React.Component<ImageGalleryProps, ImageGalleryState> {
    constructor(props: ImageGalleryProps) {
        super(props)

    }

    myGalleryRenderItem(item: any) {
        return (
            <ReactImageMagnify
                {...{
                    smallImage: {
                        alt: "No Image",
                        isFluidWidth: true,
                        src: item.original
                    },
                    largeImage: {
                        src: item.original,
                        width: 1426,
                        height: 2000
                    },
                    enlargedImagePortalId: "myPortal"
                }}
            />
        );
    }

    render() {
        const properties = {
            useBrowserFullscreen: true,
            showPlayButton: false,
            renderItem: this.myGalleryRenderItem.bind(this),
            items: this.props.otherImages
        };

        return <ImageGallery {...properties} />;
    }

}

export default MyImageGallery