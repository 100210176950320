import React from 'react'
import { tranHistoryStyles as styles } from './styles'
import { View, Text, Dimensions, ScrollView } from 'react-native'
import { FormattedMessage, injectIntl } from 'react-intl'
import { ReduxAppState } from '../../utils/types'
import { compose, Dispatch } from 'redux'
import { connect } from 'react-redux'
import { generateRandomId } from '../../utils'
import { ActivityIndicator, Button, Headline, IconButton, TextInput } from 'react-native-paper'
import { getAuditLogs } from '../../reducers/Audit/getAuditLogs'
import { Form } from '../../ReusableComponents'
import Table from './Table/Table'
import { Audit } from '../../reducers/Audit'
import { User } from '../../reducers/User'
import moment from 'moment';

export interface ComponentProps {
    isRefreshing: boolean
    intl: any
}

export interface DispatchProps {
    getAuditLogs: (userId: string, userType: number) => any
}

export interface StateProps {
    currentUser?: User
    auditLogs: Audit[]
    isLoading: boolean
    isError: boolean
    userType: string | number
}

export interface TranHistoryPageState {
}

export type TranHistoryPageProps = ComponentProps & DispatchProps & StateProps

class TranHistoryComponent extends React.Component<TranHistoryPageProps, TranHistoryPageState> {
    constructor(props: TranHistoryPageProps) {
        super(props)
        this.state = {
            searchString: '',
            value: ''
        }
    }

    componentDidMount() {
        this.props.getAuditLogs(this.props.currentUser?.sub, Number(this.props.currentUser?.userType))
    }

    getStatusValue(status: number) {
        let statusDscp = '';
        let color = '#000000';
        switch (status) {
            case 0:
                color = '#2EF72A';
                statusDscp = 'Completed';
                break;
            case 1:
                color = '#F11B1B';
                statusDscp = 'Failed';
                break;
            case 2:
                color = '#F41C1C';
                statusDscp = 'Cancelled';
                break;
            case 3:
                color = '#C77A07';
                statusDscp = 'Pending';
                break;
            default:
                color = '#000000';
                statusDscp = 'Unknown';
                break;
        }

        return { statusDscp, color }
    }

    getHeader = () => {
        const { intl, userType } = this.props
        const headerData = [
            intl.formatMessage({ id: 'TranHistory.msg.tranDate' }),
            intl.formatMessage({ id: 'TranHistory.msg.tranType' }),
            intl.formatMessage({ id: 'TranHistory.msg.tranId' }),
            intl.formatMessage({ id: 'TranHistory.msg.email' }),
            intl.formatMessage({ id: 'TranHistory.msg.amount' }),
            intl.formatMessage({ id: 'TranHistory.msg.balance' }),
            intl.formatMessage({ id: 'TranHistory.msg.operation' }),
            intl.formatMessage({ id: 'TranHistory.msg.status' })
        ]

        if (userType !== '0') {
            delete headerData[3]
        }
        return headerData
    }

    getRowCell = () => {
        const { auditLogs } = this.props
        const rowData = auditLogs?.length > 0 && auditLogs.map((auditLog: Audit) => {
            return [
                moment(auditLog.tranDate).format('MMMM DD YYYY h:mm:ss a'),
                auditLog.tranType,
                auditLog.tranId,
                auditLog.email,
                (Math.round(auditLog.amt * 100) / 100).toFixed(2),
                (Math.round(auditLog.balance * 100) / 100).toFixed(2),
                auditLog.operation,
                this.getStatusValue(auditLog.tranStatus)['statusDscp'],
                this.getStatusValue(auditLog.tranStatus)['color']
            ]
        })

        return rowData
    }

    render() {
        this.getRowCell()
        const { isLoading, isError } = this.props
        return (
            <React.Fragment>
                {isError && <Text><FormattedMessage id='ArtShare.msg.somethingWentWrong' /></Text>}
                <View style={{ flexDirection: 'row', justifyContent: 'space-between', marginTop: 10 }}>
                    <View style={{ flexDirection: 'row' }}>
                        <View style={{ marginHorizontal: 5 }}>
                            <TextInput
                                style={styles.input}
                                placeholder="Period"
                                onChangeText={(searchString) => console.log(searchString)}
                                mode={'outlined'}
                                autoComplete={false}
                            />
                        </View>
                        <View style={{ marginHorizontal: 5 }}>
                            <TextInput
                                style={styles.input}
                                placeholder="From"
                                onChangeText={(searchString) => console.log(searchString)}
                                mode={'outlined'}
                                autoComplete={false}
                            />
                        </View>
                        <View style={{ marginHorizontal: 5 }}>
                            <TextInput
                                style={styles.input}
                                placeholder="To"
                                onChangeText={(searchString) => console.log(searchString)}
                                mode={'outlined'}
                                autoComplete={false}
                            />
                        </View>
                        <View style={{ marginHorizontal: 5 }}>
                            <TextInput
                                style={styles.input}
                                placeholder="Type"
                                onChangeText={(searchString) => console.log(searchString)}
                                mode={'outlined'}
                                autoComplete={false}
                            />
                        </View>
                        <View style={{ marginHorizontal: 10, marginTop: 5, flexDirection: 'row' }}>
                            <Button mode='outlined' style={{ height: 35, width: '60%' }} color='black' >Search</Button>
                            <Button mode='outlined' style={{ height: 35, width: '60%', marginLeft: 10 }} color='black' >Clear All</Button>
                        </View>

                    </View>
                </View>
                <ScrollView style={{ marginTop: 10 }}>
                    {isError && <Text><FormattedMessage id='Home.msg.somethingWentWrong' /></Text>}
                    {isLoading && !isError ? <ActivityIndicator animating={true} color='#AFCD37' size='large' /> : (
                        <View style={{ marginHorizontal: 20, maxWidth: 1500, alignSelf: 'center', width: '100%' }}>
                            <Table
                                headerData={this.getHeader()}
                                tableData={this.getRowCell()}
                                headerCellStyle={styles.tableHeader}
                                headerCellTextStyle={[styles.normalText, styles.boldText, styles.basicLabel]}
                                rowCellStyle={styles.tableCol}
                                userType={this.props.userType} />
                        </View>
                    )}
                </ScrollView>
            </React.Fragment>
        )
    }
}


const mapStateToProps = (state: ReduxAppState) => {
    const _getAuditLogs = state.api?.audit?.getAuditLogs
    const _authUserInfo = state.api?.user?.authUser
    return ({
        auditLogs: _getAuditLogs.response,
        isLoading: _getAuditLogs.loading,
        isError: _getAuditLogs.statusText === 'error',
        userType: _authUserInfo?.response?.hasOwnProperty('custom:user_type') ? _authUserInfo?.response['custom:user_type'] : '1',
        currentUser: _authUserInfo.response
    })
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
    getAuditLogs: (userId: string, userType: number) => getAuditLogs(dispatch, userId, userType)
})

export default connect(mapStateToProps, mapDispatchToProps)(compose(injectIntl(TranHistoryComponent as any)))