import React from 'react'

import {
  NavigationContainer,
  DarkTheme as DefaultTheme,
  Theme,
} from '@react-navigation/native'
import { createStackNavigator } from '@react-navigation/stack'
import { createDrawerNavigator } from '@react-navigation/drawer'

import DrawerContent from './DrawerContent'
import { AuthStackScreen } from './screens/AuthStackScreen'
import { MainStackScreen } from './screens/MainStackScreen'

import { RouteName } from './types'

import { connect } from 'react-redux'
import { Dispatch } from 'redux'
import { Platform, Text, View } from 'react-native'

import linking from '../utils/linking'
import { ReduxAppState } from '../utils/types'
import Auth from '@aws-amplify/auth'
import AuthDrawerContent from './AuthDrawerContent'
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs'
import Dashboard from '../components/Dashboard/Dashboard'
import List from '../components/Listing/List'
import Wallet from '../components/Wallet/Wallet'
import Settings from '../components/Settings/Settings'
import { IconButton } from 'react-native-paper'
import IdleTimerContainer from './IdleTimerContainer'

const Drawer = createDrawerNavigator()
const Tab = createBottomTabNavigator()

export interface NavigationThemeProps {
  theme: Theme
}

export interface NavigationProps {
  isSignedIn?: boolean
  isLoading: boolean
  hasToken?: boolean
}

export interface StateProps {
  user: any
}

export interface DispatchProps { }

export interface NavPageState {
  isForceChangePwd: string
}

export type NavContentProps = NavigationThemeProps &
  StateProps &
  NavigationProps &
  DispatchProps

class Navigation extends React.Component<NavContentProps, NavPageState> {
  constructor(props: NavContentProps) {
    super(props)
    this.state = {
      isForceChangePwd: '0',
    }
  }

  render() {
    const { theme, isLoading, hasToken, isSignedIn, user } = this.props
    const isWeb = Platform.OS === 'web'
    return (
      <React.Fragment>
        {isLoading && hasToken ? (
          <SplashScreen />
        ) : (
          <NavigationContainer theme={theme} linking={linking}>
            {isSignedIn ? isWeb ? (
              <React.Fragment>
                <IdleTimerContainer />
                <Drawer.Navigator
                  drawerContent={props => <DrawerContent {...props} />}>
                  <Drawer.Screen name={RouteName.MainHomePage} children={() => <MainStackScreen isResetPwd={user['custom:force_change_pwd']?.toString()} />} />
                </Drawer.Navigator>
              </React.Fragment>
            ) : (
              <Tab.Navigator>
                <Tab.Screen
                  name="Portfolio"
                  // component={Dashboard}
                  options={{
                    tabBarIcon: () => <IconButton icon="briefcase-outline" />,
                  }}
                  children={() => <MainStackScreen defaultPage={RouteName.DashboardPage} isResetPwd={user['custom:force_change_pwd']?.toString()} />}
                />
                <Tab.Screen
                  name="Listing"
                  // component={List}
                  options={{
                    tabBarIcon: () => (
                      <IconButton icon="image-multiple-outline" />
                    ),
                  }}
                  children={() => <MainStackScreen defaultPage={RouteName.ListPage} isResetPwd={user['custom:force_change_pwd']?.toString()} />}
                />
                <Tab.Screen
                  name="Wallet"
                  // component={Wallet}
                  options={{
                    tabBarIcon: () => <IconButton icon="wallet-outline" />,
                  }}
                  children={() => <MainStackScreen defaultPage={RouteName.WalletPage} isResetPwd={user['custom:force_change_pwd']?.toString()} />}
                />
                <Tab.Screen
                  name="Settings"
                  // component={Settings}
                  options={{
                    tabBarIcon: () => <IconButton icon="cog-outline" />,
                  }}
                  children={() => <MainStackScreen defaultPage={RouteName.SettingsPage} isResetPwd={user['custom:force_change_pwd']?.toString()} />}
                />
              </Tab.Navigator>
            ) : (
              <Drawer.Navigator
                drawerContent={props => <AuthDrawerContent {...props} />}>
                <Drawer.Screen
                  name={RouteName.AuthPage}
                  children={() => <AuthStackScreen />}
                />
              </Drawer.Navigator>
            )}
          </NavigationContainer>
        )}
      </React.Fragment>
    )
  }
}

const SplashScreen = () => {
  return (
    <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
      <Text>Loading...</Text>
    </View>
  )
}

const mapStateToProps = (state: ReduxAppState) => {
  const _authUserInfo = state.api?.user?.authUser
  return {
    isSignedIn: _authUserInfo.isSignedIn,
    isLoading: _authUserInfo.loading,
    hasToken: _authUserInfo.hasToken,
    user: _authUserInfo.response,
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => ({})

export default connect(mapStateToProps, mapDispatchToProps)(Navigation)
