import { Platform, StyleSheet } from 'react-native'
import cpebColors from '../../utils/colors'

export const welcomeStyles = StyleSheet.create({
    notifIcon: {
        backgroundColor: '#FFFFFF',
        borderColor: '#F7931E',
        borderWidth: 1,
        borderRadius: 40,
        alignSelf: 'center',
        alignItems: 'center',
        width: 40,
        height: 40,
    },
    headerTitle: {
        fontFamily: "'Montserrat', sans-serif",
        fontSize: 28,
        marginTop: -15
    },
    headerImg2: {
        width: 50,
        height: 50,
        marginHorizontal: 15
    },
    headerImg: {
        width: 200,
        height: 110,
        margin: 0,
        alignSelf: 'center'
    },
    headerView: {
        flexDirection: 'row',
        alignSelf: 'center'
    },
    button: {
        marginBottom: 20,
        marginTop: 40,
        backgroundColor: '#FFFFFF',
        borderColor: '#ff9933',
        borderWidth: 1,
        paddingHorizontal: 5,
        textTransform: 'uppercase'
    },
    buttonNav: {
        backgroundColor: '#FFFFFF',
        borderColor: '#ff9933',
        borderWidth: 1,
        paddingVertical: 5,
        minWidth: 100,
        textAlign: 'center',
        borderRadius: 5
    },
    textWhite: {
        color: '#000000',
        fontSize: 20,
        fontFamily: "'Montserrat', sans-serif",
        fontWeight: 'bold'
    },
    tabTitle: {
        fontFamily: "'Montserrat-Bold', sans-serif",
        fontSize: 16,
        textTransform: 'uppercase',
    },
    mobileTabTitle: {
        fontFamily: "'Montserrat-Bold', sans-serif",
        fontSize: 14,
        textTransform: 'uppercase',
    },
    paragraphText: {
        color: '#4d4d4d',
        fontFamily: "'Montserrat', sans-serif",
        fontSize: 18,
        padding: 3,
        marginLeft: 5,
        textAlign: 'justify'
    },
    homeView: {
        marginTop: 30
    },
    largeText: {
        color: '#4D4D4D',
        fontWeight: 'bold',
        fontFamily: "'Montserrat', sans-serif",
        textTransform: 'uppercase',
        lineHeight: 90
    },
    mediumText: {
        fontWeight: 'bold',
        fontFamily: "'Montserrat', sans-serif",
        fontSize: 16
    },
    smallText: {
        fontWeight: 'bold',
        fontFamily: "'Montserrat', sans-serif",
        fontSize: 14
    },
    pricingView: {
        flexDirection: 'row',
        marginVertical: 12
    },
    pricingIcon: {
        width: 35,
        height: 35,
        marginRight: 10
    },
    pricingText: {
        fontWeight: 'bold',
        fontFamily: "'Montserrat', sans-serif",
        fontSize: 20,
        flexWrap: 'wrap',
        flexShrink: 1
    },
    homeImageAuth: {
        fontStyle: 'italic',
        fontFamily: "'Montserrat', sans-serif",
        bottom: 0,
        right: 0,
        position: 'absolute',
        marginBottom: -20
    },
    contactIcon: {
        borderRadius: 50,
        borderWidth: 1,
        margin: 5
    }
})