import React, { useState } from 'react'
import { View, StyleSheet, Text, TextInput as Input, Pressable, Image, TouchableOpacity, Platform } from 'react-native'
import { ActivityIndicator, HelperText, IconButton, Paragraph, TextInput, Title } from 'react-native-paper'
import { modalCustomStyles, termStyles as styles } from './styles'
import Modal from 'react-modal'
import { Form, ResponsiveView } from '../../ReusableComponents'
import { ReduxAppState } from '../../utils/types'
import { compose, Dispatch } from 'redux'
import { connect } from 'react-redux'
import { NavigationStackProp } from 'react-navigation-stack'
import * as _ from 'lodash'
import { FormattedMessage } from 'react-intl'

export interface ComponentProps {
  navigation: NavigationStackProp<any>
  showModal: boolean
  closeModal: () => any
}

export interface StateProps {
}

export interface DispatchProps {
}

export interface TermsModalState {
}

export type TermsModalProps = ComponentProps & StateProps & DispatchProps

Modal.setAppElement('#react-root');

class TermsModalComponent extends React.Component<TermsModalProps, TermsModalState> {
  constructor(props: TermsModalProps) {
    super(props)
  }

  onClose = () => {
    this.props.closeModal()
  }

  render() {
    const { showModal } = this.props
    return (
      <Modal
        isOpen={showModal}
        onRequestClose={this.onClose}
        style={modalCustomStyles}
      >
        <View style={{ position: 'absolute', right: 0, top: 0, zIndex: 1 }}>
          <IconButton
            icon='close'
            size={25}
            onPress={this.onClose}
          />
        </View>
        {/* Terms of Use */}
        <ResponsiveView height={'95%'} width={Platform.OS === 'web' ? '80%' : '95%'}>
          <View style={{ flexDirection: 'row', alignSelf: 'center', marginVertical: 20 }}>
            <Image style={{ width: 80, height: 80, marginHorizontal: 20 }} source={require('../../assets/mutual-art2.png')} resizeMode='stretch' />
            <Title style={{ marginTop: '5%', fontWeight: 'bold', fontSize: 40, fontFamily: "'Montserrat', sans-serif" }}>ART MUTUAL</Title>
          </View>
          <View style={styles.paragraphContainer}>
            <Title style={{ textAlign: 'center', fontSize: 24, fontWeight: 'bold', margin: 10, fontFamily: "'Montserrat', sans-serif" }}><FormattedMessage id='Terms.msg.term.title' /></Title>
            <Paragraph style={styles.text}><FormattedMessage id='Terms.msg.term.prgrph1' /></Paragraph>
            <Paragraph style={styles.text}><FormattedMessage id='Terms.msg.term.prgrph2' /></Paragraph>
            <Paragraph style={styles.text}><FormattedMessage id='Terms.msg.term.prgrph3' /></Paragraph>
            <Paragraph style={styles.text}><FormattedMessage id='Terms.msg.term.prgrph4' /></Paragraph>
          </View>
          <View style={styles.paragraphContainer}>
            <Title style={styles.paragraphTitle}><FormattedMessage id='Terms.msg.termI.title' /></Title>
            <Paragraph style={styles.text}><FormattedMessage id='Terms.msg.termI.prgrph1' /></Paragraph>
          </View>
          <View style={styles.paragraphContainer}>
            <Title style={styles.paragraphTitle}><FormattedMessage id='Terms.msg.termII.title' /></Title>
            <Paragraph style={styles.text}><FormattedMessage id='Terms.msg.termII.prgrph1' /></Paragraph>
            <Paragraph style={styles.text}><FormattedMessage id='Terms.msg.termII.prgrph2' /></Paragraph>
            <Paragraph style={styles.text}><FormattedMessage id='Terms.msg.termII.prgrph3' /></Paragraph>
            <Paragraph style={styles.text}><FormattedMessage id='Terms.msg.termII.prgrph4' /></Paragraph>
            <Paragraph style={styles.text}><FormattedMessage id='Terms.msg.termII.prgrph5' /></Paragraph>
            <Paragraph style={styles.text}><FormattedMessage id='Terms.msg.termII.prgrph6' /></Paragraph>
            <Paragraph style={styles.text}><FormattedMessage id='Terms.msg.termII.prgrph7' /></Paragraph>
          </View>
          <View style={styles.paragraphContainer}>
            <Title style={styles.paragraphTitle}><FormattedMessage id='Terms.msg.termIII.title' /></Title>
            <Paragraph style={styles.text}><FormattedMessage id='Terms.msg.termIII.prgrph1' /></Paragraph>
            <Paragraph style={styles.text}><FormattedMessage id='Terms.msg.termIII.prgrph2' /></Paragraph>
          </View>
          <View style={styles.paragraphContainer}>
            <Title style={styles.paragraphTitle}><FormattedMessage id='Terms.msg.termIV.title' /></Title>
            <Paragraph style={styles.text}><FormattedMessage id='Terms.msg.termIV.prgrph1' /></Paragraph>
          </View>
          <View style={styles.paragraphContainer}>
            <Title style={styles.paragraphTitle}><FormattedMessage id='Terms.msg.termV.title' /></Title>
            <Paragraph style={styles.text}><FormattedMessage id='Terms.msg.termV.prgrph1' /></Paragraph>
            <Paragraph style={styles.text}><FormattedMessage id='Terms.msg.termV.prgrph2' /></Paragraph>
            <Paragraph style={styles.text}><FormattedMessage id='Terms.msg.termV.prgrph3' /></Paragraph>
            <Paragraph style={styles.text}><FormattedMessage id='Terms.msg.termV.prgrph4' /></Paragraph>
            <Paragraph style={styles.text}><FormattedMessage id='Terms.msg.termV.prgrph5' /></Paragraph>
          </View>
          <View style={styles.paragraphContainer}>
            <Title style={styles.paragraphTitle}><FormattedMessage id='Terms.msg.termVI.title' /></Title>
            <Paragraph style={styles.text}><FormattedMessage id='Terms.msg.termVI.prgrph1' /></Paragraph>
            <Paragraph style={styles.text}><FormattedMessage id='Terms.msg.termVI.prgrph2' /></Paragraph>
            <Paragraph style={styles.text}><FormattedMessage id='Terms.msg.termVI.prgrph3' /></Paragraph>
          </View>
          <View style={styles.paragraphContainer}>
            <Title style={styles.paragraphTitle}><FormattedMessage id='Terms.msg.termVII.title' /></Title>
            <Paragraph style={styles.text}><FormattedMessage id='Terms.msg.termVII.prgrph1' /></Paragraph>
          </View>
          <View style={styles.paragraphContainer}>
            <Title style={styles.paragraphTitle}><FormattedMessage id='Terms.msg.termVIII.title' /></Title>
            <Paragraph style={styles.text}><FormattedMessage id='Terms.msg.termVIII.prgrph1' /></Paragraph>
            <Paragraph style={styles.text}><FormattedMessage id='Terms.msg.termVIII.prgrph2' /></Paragraph>
            <Paragraph style={styles.text}><FormattedMessage id='Terms.msg.termVIII.prgrph3' /></Paragraph>
          </View>
          <View style={styles.paragraphContainer}>
            <Title style={styles.paragraphTitle}><FormattedMessage id='Terms.msg.termIX.title' /></Title>
            <Paragraph style={styles.text}><FormattedMessage id='Terms.msg.termIX.prgrph1' /></Paragraph>
          </View>
          <View style={styles.paragraphContainer}>
            <Title style={styles.paragraphTitle}><FormattedMessage id='Terms.msg.termX.title' /></Title>
            <Paragraph style={styles.text}><FormattedMessage id='Terms.msg.termX.prgrph1' /></Paragraph>
          </View>
          <View style={styles.paragraphContainer}>
            <Title style={styles.paragraphTitle}><FormattedMessage id='Terms.msg.termXI.title' /></Title>
            <Paragraph style={styles.text}><FormattedMessage id='Terms.msg.termXI.prgrph1' /></Paragraph>
          </View>
          <View style={styles.paragraphContainer}>
            <Title style={styles.paragraphTitle}><FormattedMessage id='Terms.msg.termXII.title' /></Title>
            <Paragraph style={styles.text}><FormattedMessage id='Terms.msg.termXII.prgrph1' /></Paragraph>
          </View>
          <View style={styles.paragraphContainer}>
            <Title style={styles.paragraphTitle}><FormattedMessage id='Terms.msg.termXIII.title' /></Title>
            <Paragraph style={styles.text}><FormattedMessage id='Terms.msg.termXIII.prgrph1' /></Paragraph>
          </View>
          <View style={styles.paragraphContainer}>
            <Title style={styles.paragraphTitle}><FormattedMessage id='Terms.msg.termXIV.title' /></Title>
            <Paragraph style={styles.text}><FormattedMessage id='Terms.msg.termXIV.prgrph1' /></Paragraph>
            <Paragraph style={styles.text}><FormattedMessage id='Terms.msg.termXIV.prgrph1' /></Paragraph>
          </View>
        </ResponsiveView>
      </Modal >
    )
  }
}

export default compose(TermsModalComponent as any)