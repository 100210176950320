import React from 'react'
import { upcomingList as styles } from './styles'
import { View, Text, Image } from 'react-native'
import { FormattedMessage, FormattedNumber, injectIntl } from 'react-intl'
import { RouteName } from '../../navigation/types'
import { StackNavigationProp } from '@react-navigation/stack'
import { ActivityIndicator, Button, Headline, Paragraph, Title } from 'react-native-paper'
import { ReduxAppState } from '../../utils/types'
import { compose, Dispatch } from 'redux'
import { connect } from 'react-redux'
import { getItemList, GetItemListState } from '../../reducers/Items/getItems'
import { getItemInfo } from '../../reducers/Items/getItemInfo'
import { Items } from '../../reducers/Items'
import { AxiosPromise, AxiosResponse } from 'axios'
import { generateRandomId } from '../../utils'
import { addItem, RESET_ADD_ITEM } from '../../reducers/Items/addItem'

export interface ComponentProps {
    navigation: StackNavigationProp<any>
}

export interface DispatchProps {
    getItemList: (isPosted: number) => any
    getItemInfo: (id: string) => AxiosPromise
}

export interface StateProps {
    isAddItemSuccess: boolean
    userType: string | number
    activeList: GetItemListState
}

export interface UpcomingListPageState {
    items: any
    isLoading: boolean
}

export type UpcomingListPageProps = ComponentProps & DispatchProps & StateProps

class UpcomingListComponent extends React.Component<UpcomingListPageProps, UpcomingListPageState>{
    constructor(props: UpcomingListPageProps) {
        super(props)
        this.state = {
            items: [],
            isLoading: false
        }
    }

    componentDidMount() {
        this.setState({ isLoading: true })
        this.props.getItemList(0)
    }

    componentDidUpdate(prevProps: UpcomingListPageProps) {
        if (this.props.isAddItemSuccess !== prevProps.isAddItemSuccess && this.props.isAddItemSuccess) {
            this.props.getItemList(0)
        }
    }

    // getItemList() {
    //     this.props.getItemList(0)
    //         .then(async (response: AxiosResponse) => {
    //             const _items = response.data.message?.data.map(async (data: any) => {
    //                 const _response = await this.props.getItemInfo(data.id)
    //                 const imageUrl = JSON.parse(_response.data.message)?.imageUrl
    //                 // console.log({...data, imageUrl})
    //                 return Promise.resolve({ ...data, imageUrl })
    //             })
    //             Promise.all(_items)
    //                 .then(items => this.setState({ items, isLoading: false }))
    //                 .catch(() => this.setState({ isLoading: false }))
    //             // console.log(items)
    //             // this.setState({ items })
    //         })
    //         .catch(() => this.setState({ isLoading: false }))
    // }

    navigateScreen = () => {
        this.props.navigation.push(RouteName.ItemDetailPage)
    }

    getItemInfo = (item: string) => {
        this.props.getItemInfo(item)
        this.navigateScreen()
    }

    getInitial = (name: string) => {
        if (!name) {
            return
        }
        const nameSplit = name.split(' ')
        return nameSplit.map(a => a[0]).reduce((prev, curr) => prev += curr).toUpperCase()
    }

    render() {
        const { activeList } = this.props
        return (
            <React.Fragment>
                {activeList?.statusText === 'error' && <Text><FormattedMessage id='ActiveList.msg.somethingWentWrong' /></Text>}
                <View>
                    <View style={{ flexDirection: 'row', paddingBottom: 10 }}>
                        <View style={{ width: '30%' }}>
                            <Headline style={[styles.normalText, styles.boldText, styles.upperCaseText, { fontSize: 18, marginLeft: 8 }]}><FormattedMessage id='ActiveList.msg.artwork' /></Headline>
                        </View>
                        <View style={{ width: '20%' }}>
                            <Headline style={[styles.normalText, styles.boldText, styles.upperCaseText, { fontSize: 18, marginLeft: 8 }]}><FormattedMessage id='ActiveList.msg.listValue' /></Headline>
                        </View>
                        <View style={{ width: '10%' }}>
                            <Headline style={[styles.normalText, styles.boldText, styles.upperCaseText, { fontSize: 18, marginLeft: 8 }]}><FormattedMessage id='ActiveList.msg.saleDate' /></Headline>
                        </View>
                        <View style={{ width: '20%' }}>

                        </View>
                    </View>
                    <View>
                        {activeList?.loading ? (
                            <ActivityIndicator animating={true} color='#AFCD37' size='large' />
                        ) : activeList?.response?.map((result: any) => {
                            const item = result?.data
                            return (
                                <View key={generateRandomId()} style={styles.table}>
                                    <View style={styles.imageContainer}>
                                        <View style={{ flexDirection: 'row' }}>
                                            <View style={{ height: 80, width: 80 }}>
                                                <Image
                                                    source={{ uri: result?.imageUrl }}
                                                    style={{ height: '100%', width: '100%' }}
                                                />
                                                <Text style={[styles.normalText, styles.boldText, { textAlign: 'center', fontSize: 16, marginTop: 5 }]}>{this.getInitial(item?.artist ?? '')}</Text>
                                            </View>
                                            <View style={{ marginLeft: 8 }}>
                                                <Title style={[styles.boldText, { fontSize: 20 }]}>{item?.title}</Title>
                                                <Text style={[styles.italicText, { fontSize: 17 }]}>{`${item?.artist}, ${item?.year}`}</Text>
                                            </View>
                                        </View>
                                        <View style={{ borderRightWidth: 1, borderColor: 'black' }} />
                                    </View>
                                    <View style={styles.row}>
                                        <View style={styles.rowTextContainer}>
                                            <Paragraph style={[styles.boldText, { textAlign: 'center', fontSize: 20 }]}><FormattedNumber format='USD' value={Number(item?.listedValue)} /></Paragraph>
                                        </View>
                                        <View style={{ borderRightWidth: 1, borderColor: 'black' }} />
                                    </View>
                                    <View style={styles.row}>
                                        <View style={styles.rowTextContainer}>
                                            <Paragraph style={[styles.boldText, { textAlign: 'center', fontSize: 20 }]}>{item?.saleDate}</Paragraph>
                                        </View>
                                        <View style={{ borderRightWidth: 1, borderColor: 'black' }} />
                                    </View>
                                    <View style={{ flexDirection: 'row', padding: 10 }}>
                                        <View style={styles.rowBtnContainer}>
                                            <Button onPress={() => this.getItemInfo(item?.id)} mode='outlined' style={{ height: 35, marginRight: 10, width: '60%' }} color='black' >DETAILS</Button>
                                            <Button mode='contained' style={{ width: '60%' }} color='#F7931E' labelStyle={{ color: 'white' }}>{this.props.userType === '0' ? 'DELETE' : 'BUY'}</Button>
                                        </View>
                                    </View>
                                </View>
                            )
                        })}
                    </View>
                </View>
            </React.Fragment>
        )
    }
}


const mapStateToProps = (state: ReduxAppState) => {
    return ({
        activeList: state.api?.items?.getItemList,
        isAddItemSuccess: state.api?.items?.addItem?.statusText === 'success',
        userType: state.api?.user?.authUser?.response?.hasOwnProperty('custom:user_type') ? state.api?.user?.authUser?.response['custom:user_type'] : '1'
    })
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
    getItemList: ((isPosted: number) => getItemList(dispatch, isPosted)),
    getItemInfo: ((id: string) => getItemInfo(dispatch, id))
})

export default connect(mapStateToProps, mapDispatchToProps)(compose(injectIntl(UpcomingListComponent as any)))
