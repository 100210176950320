import React from 'react'

import { View, Image, ScrollView, ToastAndroid, TouchableOpacity, StyleSheet, Text, Dimensions } from 'react-native'

import { Avatar, Button, IconButton, Title } from 'react-native-paper';

import { SideNavStyles as styles } from './styles'
import { DrawerNavigationProp } from '@react-navigation/drawer';
import Icon from 'react-native-vector-icons/Ionicons';

import artColors from '../utils/colors'
import { FormattedMessage } from 'react-intl';
import { updateMenu } from '../reducers/Menu/changeMenu'
import { logOutUser } from '../reducers/User/user'
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { ReduxAppState } from '../utils/types';
import { RouteName } from '../navigation/types';
import MenuItem from './components/MenuItem'
import { Screen } from '../ReusableComponents';

const window = Dimensions.get("window");
const screen = Dimensions.get("screen");

export interface SideNavContentProps {
    navigation: DrawerNavigationProp<any>,
    activeItem: string,
}

export interface DispatchProps {
    updateMenu: (menu: string) => any,
    logOutUser: () => any
}

export interface SideNavContentState {
    dimensions: Dimension
}

export interface Dimension {
    window: any
    screen: any
}

export type SideNavContentDetailsProps = SideNavContentProps & DispatchProps

const SideNavContentHOC = (WrappedComponent: any) => {
    class SideNavContent extends React.Component<SideNavContentDetailsProps, SideNavContentState> {
        dimensionsSubscription: any;
        constructor(props: SideNavContentDetailsProps) {
            super(props);
            this.state = {
                dimensions: {
                    window,
                    screen
                }
            };
        }

        // tslint:disable-next-line:no-shadowed-variable
        onChange = ({ window, screen }: { window: any, screen: any }) => {
            this.setState({ dimensions: { window, screen } })
        }

        componentDidMount() {
            this.dimensionsSubscription = Dimensions.addEventListener("change", this.onChange)
        }

        componentWillUnmount() {
            this.dimensionsSubscription?.remove()
        }

        onChangeActiveItem = (item: string) => {
            this.props.updateMenu(item)
            this.props.navigation.navigate(item)
        }

        doLogOut = () => {
            this.props.logOutUser()
        }

        render() {
            const { navigation, activeItem } = this.props
            return (
                <View style={styles.container}>
                    <Screen.Desktop>
                        <View style={{ width: 210, borderRightWidth: 3, borderColor: '#9D9D9D' }}>
                            <MenuItem
                                title={'Portfolio'}
                                icon={'briefcase-outline'}
                                onPress={() => this.onChangeActiveItem(RouteName.DashboardPage)}
                                activeMenuItem={activeItem === RouteName.DashboardPage}
                            />
                            <MenuItem
                                title={'Listing'}
                                icon={'image-multiple-outline'}
                                onPress={() => this.onChangeActiveItem(RouteName.ListPage)}
                                activeMenuItem={activeItem === RouteName.ListPage}
                            />
                            <MenuItem
                                title={'Wallet'}
                                icon={'wallet-outline'}
                                onPress={() => this.onChangeActiveItem(RouteName.WalletPage)}
                                activeMenuItem={activeItem === RouteName.WalletPage}
                            />
                            <View style={{ flexDirection: 'row', alignItems: 'center', marginTop: 20 }}>
                                <View style={{ flex: 1, height: 1, backgroundColor: '#9D9D9D', marginHorizontal: 80 }} />
                            </View>
                            <MenuItem
                                title={'Settings'}
                                icon={'cog-outline'}
                                onPress={() => this.onChangeActiveItem(RouteName.SettingsPage)}
                                activeMenuItem={activeItem === RouteName.SettingsPage}
                            />
                            <View style={{ alignSelf: 'center', marginTop: 70, marginBottom: 10 }}>
                                <TouchableOpacity style={{ flexDirection: 'row' }}
                                    onPress={() => { this.doLogOut() }}>
                                    <Image
                                        source={require('../assets/logout.png')}
                                        style={{ height: 20, width: 20, marginHorizontal: 10 }}
                                        resizeMode="contain"
                                    />
                                    <Text style={styles.menuText}><FormattedMessage id='HeaderContent.msg.logout' /></Text>
                                </TouchableOpacity>
                            </View>
                        </View >
                    </Screen.Desktop>
                    <View style={{ flex: 1, height: Dimensions.get('window').height - 160 }}>
                        <WrappedComponent {...this.props} />
                    </View>
                </View >
            )
        }
    }

    const mapStateToProps = (state: ReduxAppState) => {
        const _menu = state.api?.menu?.activeMenuItem
        return ({
            activeItem: _menu.activeItem
        })
    }

    const mapDispatchToProps = (dispatch: Dispatch) => ({
        updateMenu: (activeItem: string) => dispatch(updateMenu(activeItem)),
        logOutUser: () => logOutUser(dispatch)
    })

    return connect(mapStateToProps, mapDispatchToProps)(SideNavContent)

}

export default SideNavContentHOC
