import React from 'react'
import Screen from './Screen'
import { useMediaQuery } from 'react-responsive'


export interface DesktopProps {
    children: JSX.Element | JSX.Element[]
    style?: any
}

export const isDesktop = () => useMediaQuery({ query: '(min-width: 992px)' })

const DesktopComponent = (props: DesktopProps) => {
    return (
        <Screen minWidth={992} style={props.style}>
            {props.children}
        </Screen>
    )
}


export default DesktopComponent
