import React, { useEffect } from 'react'
import { useController } from 'react-hook-form'

export interface CustomFieldProps {
    name: string
    control?: any
    defaultValue?: any
    required?: boolean
    type?: string
    customField: (value: any, onChange: (value: any) => void, error?: any) => React.ReactElement | React.ReactElement[]
}

const CustomField = (props: CustomFieldProps) => {
    const { name,
        control,
        defaultValue,
        required,
        customField,
    } = props

    const { field, formState: { errors } } = useController({
        control,
        defaultValue,
        name,
        rules: {
            required
        },
    })

    useEffect(() => {
        if (!field.value) {
            field.value = defaultValue
        }
    })

    const _onChangeValue = (value: any) => {
        field.onChange(value)
    }

    const errorMessage = errors && errors.hasOwnProperty(name) && errors[name]?.message

    return control && customField(field.value, _onChangeValue, errorMessage)
}

export default CustomField