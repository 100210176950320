import React from 'react'

import { View, Image, ScrollView, Text } from 'react-native'

import { FooterStyles as styles } from './styles'
import { DrawerNavigationProp } from '@react-navigation/drawer';
import Icon from 'react-native-vector-icons/Ionicons'

import cpebColors from '../utils/colors'
import { FormattedMessage } from 'react-intl';
import Terms from '../components/Terms/Terms';
import { TouchableOpacity } from 'react-native-gesture-handler';

export interface OwnProps {
    navigation: DrawerNavigationProp<any>
}

export interface FooterState {
    isModal: boolean
}

export type FooterProps = OwnProps

const FooterHOC = (WrappedComponent: any) => {
    class HeaderContent extends React.Component<FooterProps, FooterState> {
        constructor(props: FooterProps) {
            super(props)
            this.state = {
                isModal: false
            }
        }

        onClickModal = () => {
            this.setState({ isModal: !this.state.isModal })
        }

        render() {
            return (
                <View style={{ flex: 1 }}>
                    <ScrollView>
                        <WrappedComponent {...this.props} />
                    </ScrollView>
                    <View style={styles.container}>
                        <TouchableOpacity onPress={() => this.onClickModal()}>
                            <Text style={{ fontFamily: "'Montserrat', sans-serif", fontSize: 12 }}><FormattedMessage id='Footer.msg.terms' /></Text>
                        </TouchableOpacity>
                    </View>
                    <Terms showModal={this.state.isModal} closeModal={this.onClickModal} />
                </View>

            )
        }
    }

    return HeaderContent
}

export default FooterHOC
