import Form from './Form'
import Field from './Field'
import Submit from './Submit'
import Dropdown from './Dropdown'
import Upload from './Upload'
import CustomField from './CustomField'

const CpebForm = Object.assign(
    Form,
    {
        Field,
        Submit,
        Dropdown,
        Upload,
        CustomField
    }
)

export default CpebForm