import { Dispatch } from "redux"

export const UPDATE_LOGIN_MODAL = 'API/MODAL/UPDATE_LOGIN_MODAL'
export const CLEAR_MODAL = 'API/MODAL/CLEAR_MODAL'

export const showModalInitialState = {
    showModal: true
}

export interface ShowModalState {
    showModal: boolean
}

export interface ShowModalActions {
    type: string
    [item: string]: any
}

export const reducer = (newState: ShowModalState = showModalInitialState, action: ShowModalActions) => {
    switch (action.type) {
        case UPDATE_LOGIN_MODAL:
            return Object.assign({}, newState, {
                showModal: false
            })
        case CLEAR_MODAL:
            return showModalInitialState
        default:
            return newState
    }
}

export const updateShowModal = () => ({
    type: UPDATE_LOGIN_MODAL
})

export default reducer