import { Platform, StyleSheet } from 'react-native'
import cpebColors from '../../utils/colors'


export const signUpStyles = StyleSheet.create({
    title: {
        alignSelf: 'center',
        fontSize: Platform.OS === 'web' ? 40 : 30,
        marginTop: 15,
        marginBottom: 45
    },
    formStyle: {
        // alignItems: 'center',
        width: '100%',
        backgroundColor: '#fff',
        // padding: '4%',
        marginTop: 50
    },
    formShadow: {
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 5,
        },
        shadowOpacity: 0.34,
        shadowRadius: 6.27,
        elevation: 10,
        marginTop: 100,
    },
    formGroup: {
        marginBottom: 12,
        marginHorizontal: 20,
    },
    input: {
        alignSelf: 'flex-start',
        marginLeft: 5,
        color: '##003849',
        backgroundColor: 'white',
        borderColor: 'black',
        paddingHorizontal: 10,
        fontSize: 16,
        borderWidth: 1,
        borderRadius: 0,
        fontFamily: "'Montserrat', sans-serif",
        fontWeight: '800',
        width: 350,
        // width: '100%',
        marginBottom: 13,
        padding: 10
    },
    dropdown: {
        backgroundColor: '#D1D3D4',
        borderRadius: 10,
        borderWidth: 1,
        borderColor: '#fff',
        fontSize: 16,
        height: 50
        // width: 300
    },
    fields: {
        alignSelf: 'flex-start',
        marginLeft: 5,
        fontWeight: '900',
    },
    fields2: {
        marginBottom: 7,
        marginLeft: 10
    },
    greenBtn: {
        backgroundColor: '#AFCD37',
        borderRadius: 8,
        alignSelf: 'center',
        width: '90%',
        fontSize: 20,
        marginVertical: 15,
        borderWidth: 1,
        borderColor: 'black'
    },
    checkboxContainer: {
        flexDirection: "row"
    },
    checkbox: {
        flex: 1,
    },
    registerBtn: {
        // marginBottom: 20,
        marginTop: 20,
        marginLeft: 15,
        width: 200,
        backgroundColor: '#F7931E'
    },
    textWhite: {
        color: 'white',
        fontSize: 15
    },
    textBlack: {
        color: 'black'
    },
    rowContainer: {
        flexDirection: 'row',
        position: 'relative'
    },
    floatRight: {
        width: '30%',
        position: 'absolute',
        right: 0
    },
    policyAndTerms: {
        textAlignVertical: 'center',
        fontSize: 13
    },
    requirementsContainer: {
        marginLeft: 8
    },
    requirementText: {
        fontSize: 15,
        color: cpebColors.labelColor,
        fontWeight: '900'
    },
    errorColor: {
        color: 'red'
    },
    successColor: {
        color: 'green'
    },
    plainColor: {
        color: cpebColors.labelColor
    },
    fileName: {
        fontSize: 10,
        marginBottom: 10
    },
    alignSelfCenter: {
        alignSelf: 'center'
    },
    requiredIcon: {
        color: 'red'
    },
    errorBorder: {
        borderColor: 'red'
    },
    textCenter: {
        textAlign: 'center'
    },
    modalContent: {
        backgroundColor: 'white',
        padding: 20,
        alignContent: 'center',
        width: '100%',
    },
    loading: {
        position: 'absolute',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        alignItems: 'center',
        justifyContent: 'center'
    },
    errorMessage: {
        padding: 10,
        borderColor: 'red',
        borderWidth: 2,
        backgroundColor: 'white',
        color: 'black',
        alignSelf: 'center',
        width: '100%',
        alignItems: 'center',
        marginBottom: 25,
    },
    fieldLabelStyle: {
        fontSize: 19.5,
        // margin: '10%'
        fontWeight: 'bold',
        width: '30%'
    },
    fieldFormGroupStyle: {
        flexDirection: 'row',
        marginHorizontal: 10,
        // justifyContent: 'space-evenly',
        // justifyContent: 'space-between'
    }
})