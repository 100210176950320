import { StackNavigationProp } from '@react-navigation/stack'
import { ActivityIndicator, Button, Headline, Paragraph, Subheading, Title } from 'react-native-paper'
import { ReduxAppState } from '../../utils/types'
import { compose, Dispatch } from 'redux'
import { connect } from 'react-redux'
import { getItemList, GetItemListState } from '../../reducers/Items/getItems'
import { getItemInfo } from '../../reducers/Items/getItemInfo'
import { Items } from '../../reducers/Items'
import { AxiosPromise, AxiosResponse } from 'axios'
import React from 'react'
import { FormattedMessage, injectIntl, FormattedNumber } from 'react-intl'
import { Image, Text, View, TouchableOpacity } from 'react-native'
import { RouteName } from '../../navigation/types'
import { generateRandomId } from '../../utils'
import { deleteItem, DeleteItemState } from '../../reducers/Items/deleteItem'
import Buy from '../Buy/Buy'
import { activeList as styles } from './styles'
import * as _ from 'lodash'
import { ResponsiveView, Screen } from '../../ReusableComponents'
import { useMediaQuery } from 'react-responsive'

export interface ComponentProps {
    navigation: StackNavigationProp<any>
}

export interface DispatchProps {
    getItemList: (isPosted: number) => any
    getItemInfo: (id: string) => AxiosPromise
    deleteItem: (id: string) => AxiosPromise
}

export interface StateProps {
    isAddItemSuccess: boolean
    userType: string | number
    activeList: GetItemListState
    deleteItemResponse: DeleteItemState
}

export interface ActiveListPageState {
    items: any,
    isLoading: boolean,
    isBuy: boolean,
}

export type ActiveListPageProps = ComponentProps & DispatchProps & StateProps

const TableView = (props: any) => {
    const isDesktop = useMediaQuery({ query: '(min-width: 987px)' })
    return (
        <ResponsiveView width={isDesktop ? '75%' : '100%'}>
            {props.children}
        </ResponsiveView>
    )
}
class ActiveListComponent extends React.Component<ActiveListPageProps, ActiveListPageState>{
    constructor(props: ActiveListPageProps) {
        super(props)
        this.state = {
            items: [],
            isLoading: false,
            isBuy: false
        }
    }

    componentDidMount() {
        this.props.getItemList(1)
    }

    componentDidUpdate(prevProps: ActiveListPageProps) {
        if ((this.props.isAddItemSuccess !== prevProps.isAddItemSuccess && this.props.isAddItemSuccess) ||
            (!_.isEqual(this.props.deleteItemResponse?.response, prevProps.deleteItemResponse?.response))) {
            this.props.getItemList(1)
        }
    }

    onBuyItem = (item: string) => {
        if (this.props.userType === '0') {
            this.props.deleteItem(item);
        } else {
            this.props.getItemInfo(item);
            this.setState({ isBuy: true });
        }
    }

    closeModal = () => {
        this.setState({ isBuy: false })
    }

    navigateScreen = (isSoldOut: boolean) => {
        this.props.navigation.navigate(RouteName.ItemDetailPage, { isSoldOut })
    }

    getItemInfo = (item: Items, isSoldOut: boolean) => {
        this.props.getItemInfo(item.id)
        this.navigateScreen(isSoldOut)
        this.closeModal()
    }

    getImage = (id: string) => {
        this.props.getItemInfo(id)
    }

    getInitial = (name: string) => {
        if (!name) {
            return
        }
        const nameSplit = name.trim().split(' ')
        return nameSplit.map(a => a[0]).reduce((prev, curr) => prev += curr).toUpperCase()
    }

    render() {
        const { isBuy } = this.state
        const { activeList } = this.props
        return (
            <React.Fragment>
                {activeList?.statusText === 'error' && <Text><FormattedMessage id='ActiveList.msg.somethingWentWrong' /></Text>}
                <Screen minWidth={769} style={{ width: '100%' }}>
                    <TableView>
                        <View key={generateRandomId()} style={styles.tableHeader}>
                            <View style={[styles.rowHeader, { width: '35%', maxWidth: 400 }]}>
                                <View style={[styles.rowTextContainer]}>
                                    <Headline style={[styles.normalText, styles.boldText, styles.upperCaseText, { fontSize: 18 }]}><FormattedMessage id='ActiveList.msg.artwork' /></Headline>
                                </View>
                            </View>
                            <View style={styles.rowHeader}>
                                <View style={[styles.rowTextContainer]}>
                                    <Headline style={[styles.normalText, styles.boldText, styles.upperCaseText, { textAlign: 'center', fontSize: 18 }]}><FormattedMessage id='ActiveList.msg.listValue' /></Headline>
                                </View>
                            </View>
                            <View style={styles.rowHeader}>
                                <View style={[styles.rowTextContainer]}>
                                    <Headline style={[styles.normalText, styles.boldText, styles.upperCaseText, { textAlign: 'center', fontSize: 18 }]}><FormattedMessage id='ActiveList.msg.saleDate' /></Headline>
                                </View>
                            </View>
                            <View style={{ width: '25%' }}>

                            </View>
                        </View>
                    </TableView>

                    <TableView>
                        {activeList?.loading ? (
                            <ActivityIndicator animating={true} color='#AFCD37' size='large' />
                        ) : activeList?.response?.map((result: any) => {
                            const item = result?.data
                            return (
                                <TouchableOpacity onPress={() => this.getItemInfo(item, result?.isSoldOut)} key={generateRandomId()} style={styles.table}>
                                    <View style={[styles.row, { width: '35%', maxWidth: 400, height: 140 }]}>
                                        <View style={[styles.row, { width: '100%', height: '100%', position: 'relative' }]}>
                                            <View style={{ flexDirection: 'row' }}>
                                                <View style={{ height: 80, width: 80 }}>
                                                    <Image
                                                        source={{ uri: result?.imageUrl }}
                                                        style={{ height: '100%', width: '100%' }}
                                                    />
                                                    <Text style={[styles.normalText, styles.boldText, { textAlign: 'center', fontSize: 16, marginTop: 5 }]}>{this.getInitial(item?.artist ?? '')}</Text>
                                                </View>
                                                <View style={{ marginLeft: 8, width: 180 }}>
                                                    <Title numberOfLines={2} style={[styles.boldText, { fontSize: 18, flexShrink: 1, lineHeight: 25 }]}>{item?.title}</Title>
                                                    <Text numberOfLines={2} style={[styles.italicText, { fontSize: 16, flexShrink: 1 }]}>{`${item?.artist}, ${item?.year}`}</Text>
                                                </View>
                                            </View>
                                            {result?.isSoldOut && <View style={{ height: 100, width: 150, justifyContent: 'center', alignItems: 'center', position: 'absolute', right: 0 }}>
                                                <Image
                                                    source={require('../../assets/soldout.png')}
                                                    style={{ height: '100%', width: '100%', alignSelf: 'center' }}
                                                    resizeMode='stretch'
                                                />
                                            </View>}
                                        </View>
                                        <View style={{ borderRightWidth: 1, borderColor: '#9D9D9D' }} />
                                    </View>
                                    <View style={styles.row}>
                                        <View style={styles.rowTextContainer}>
                                            <Paragraph style={[styles.boldText, { textAlign: 'center', fontSize: 20 }]}><FormattedNumber format='USD' value={Number(item?.listedValue)} /></Paragraph>
                                        </View>
                                        <View style={{ borderRightWidth: 1, borderColor: '#9D9D9D' }} />
                                    </View>
                                    <View style={styles.row}>
                                        <View style={styles.rowTextContainer}>
                                            <Paragraph style={[styles.boldText, { textAlign: 'center', fontSize: 20 }]}>{item?.saleDate}</Paragraph>
                                        </View>
                                        <View style={{ borderRightWidth: 1, borderColor: '#9D9D9D' }} />
                                    </View>
                                    <View style={[styles.row, { width: '25%' }]}>
                                        <View style={{ alignSelf: 'center', flexDirection: 'row', flexWrap: 'wrap', width: '100%', alignItems: 'center', justifyContent: 'space-evenly' }}>
                                            <View style={{ width: 120 }}>
                                                <Button onPress={() => this.getItemInfo(item, result?.isSoldOut)} mode='outlined' labelStyle={{ fontSize: 10 }} color='black' >DETAILS</Button>
                                            </View>
                                            <View style={{ width: 120 }}>
                                                <Button disabled={this.props.userType !== 0 && result?.isSoldOut} onPress={() => this.onBuyItem(item?.id)} mode='contained' color='#F7931E' labelStyle={{ color: 'white', fontSize: 10 }}>{this.props.userType === '0' ? 'DELETE' : 'BUY'}</Button>
                                            </View>
                                        </View>
                                    </View>
                                </TouchableOpacity>
                            )
                        })}
                    </TableView>
                </Screen>
                <Screen maxWidth={768}>
                    <ResponsiveView width={'90%'}>
                        {activeList?.loading ? (
                            <ActivityIndicator animating={true} color='#AFCD37' size='large' />
                        ) : activeList?.response?.map((result: any) => {
                            const item = result?.data
                            return (
                                <TouchableOpacity key={generateRandomId()} onPress={() => this.getItemInfo(item?.id, false)}>
                                    <View style={[styles.row, { width: '100%', justifyContent: 'space-between', flexWrap: 'wrap', borderWidth: 1, marginBottom: 10, borderColor: '#9D9D9D' }]}>
                                        <View style={{ flexDirection: 'row' }}>
                                            <View style={{ height: 80, width: 80 }}>
                                                <View style={{ height: '90%', width: '90%' }}>
                                                    <Image
                                                        source={{ uri: result?.imageUrl }}
                                                        style={{ height: '100%', width: '100%' }}
                                                    />
                                                </View>
                                                <View><Text style={[styles.normalText, styles.boldText, { textAlign: 'center', fontSize: 16, marginTop: 5 }]}>{this.getInitial(item?.artist ?? '')}</Text></View>
                                            </View>
                                            <View style={{ marginLeft: 8, width: 150 }}>
                                                <Title numberOfLines={2} style={[styles.boldText, { fontSize: 18, flexShrink: 1, lineHeight: 25 }]}>{item?.title}</Title>
                                                <Text numberOfLines={2} style={[styles.italicText, { fontSize: 16, flexShrink: 1, lineHeight: 20 }]}>{`${item?.artist}, ${item?.year}`}</Text>
                                            </View>
                                            {result?.isSoldOut && <View style={{ height: 100, width: 120, justifyContent: 'center', alignItems: 'center', position: 'absolute', right: 0 }}>
                                                <Image
                                                    source={require('../../assets/soldout.png')}
                                                    style={{ height: '100%', width: '100%', alignSelf: 'center' }}
                                                    resizeMode='stretch'
                                                />
                                            </View>}
                                        </View>
                                        <Screen minWidth={375}>
                                            <Text style={{ fontWeight: 'bold' }}><FormattedMessage id='ActiveList.msg.listValue' /></Text>
                                            <Text><FormattedNumber format={item?.currency ?? 'USD'} value={item?.listedValue} /></Text>
                                            <Text style={{ fontWeight: 'bold' }}><FormattedMessage id='ActiveList.msg.saleDate' /></Text>
                                            <Text>{item?.saleDate}</Text>
                                        </Screen>
                                    </View>
                                </TouchableOpacity>
                            )
                        })}
                    </ResponsiveView>
                </Screen>
                <Buy showModal={isBuy} closeModal={this.closeModal} getItemInfo={this.getItemInfo} navigation={this.props.navigation} isDetailsPage={false} />
            </React.Fragment >
        )
    }
}


const mapStateToProps = (state: ReduxAppState) => {
    return ({
        activeList: state.api?.items?.getItemList,
        deleteItemResponse: state.api?.items?.deleteItem,
        isAddItemSuccess: state.api?.items?.addItem?.statusText === 'success',
        userType: state.api?.user?.authUser?.response?.hasOwnProperty('custom:user_type') ? state.api?.user?.authUser?.response['custom:user_type'] : '1'
    })
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
    getItemList: ((isPosted: number) => getItemList(dispatch, isPosted)),
    getItemInfo: ((id: string) => getItemInfo(dispatch, id)),
    deleteItem: ((id: string) => deleteItem(dispatch, id))
})

export default connect(mapStateToProps, mapDispatchToProps)(compose(injectIntl(ActiveListComponent as any)))
