import React, { useState } from 'react';
import { View, Text, TouchableOpacity, StyleSheet, Platform } from 'react-native'

import { IntlProvider as MessageProvider } from 'react-intl'

import { DefaultTheme as PaperDefaultTheme, Provider as PaperProvider, Colors } from 'react-native-paper'
import { DefaultTheme as NavigationDefaultTheme } from '@react-navigation/native'

import { applyMiddleware, combineReducers, createStore, compose } from 'redux'
import { Provider as StoreProvider, } from 'react-redux'

import AsyncStorage from '@react-native-async-storage/async-storage'
import thunkMiddleware from 'redux-thunk'
import merge from 'deepmerge'

import Routes from './navigation'

import { persistReducer, persistStore } from 'redux-persist'
import { PersistGate } from 'redux-persist/integration/react'

import amplify from '@aws-amplify/core'

import formats from './utils/formats'
import config from './config'

import Modal from 'react-modal'

import ApiReducer, { apiInitialState } from './reducers/api'

const messages = require('../dist/en-us.json')
const staticConfig = require('../static/default.json')
import sessionStorage from 'redux-persist/lib/storage/session'

Modal.setAppElement('#react-root');

amplify.configure({
  Auth: {
    mandatorySignIn: true,
    region: config.cognito.REGION,
    userPoolId: config.cognito.USER_POOL_ID,
    identityPoolId: config.cognito.IDENTITY_POOL_ID,
    userPoolWebClientId: config.cognito.APP_CLIENT_ID
  },
  API: {
    endpoints: [
      {
        name: 'testApi',
        endpoint: config.apiGateway.URL,
        region: config.apiGateway.REGION
      }
    ]
  }
});
// amplify.configure(awsConfig)

const App = () => {
  const reducer = combineReducers({
    api: ApiReducer,
    config: () => Object.assign({}, staticConfig)
  })
  const CombinedDefaultTheme = merge(PaperDefaultTheme, NavigationDefaultTheme);
  const persistConfig = {
    key: 'root',
    storage: Platform.OS === 'web' ? sessionStorage : AsyncStorage
  }
  const persistedReducer = persistReducer(persistConfig, reducer)

  const store = createStore(persistedReducer, {
    api: apiInitialState
  }, compose(applyMiddleware(thunkMiddleware)))
  const persistor = persistStore(store)
  const theme = {
    ...CombinedDefaultTheme,
    colors: {
      ...CombinedDefaultTheme.colors,
      background: Platform.OS === 'web' ? '#FFFFFF' : '#EAEAEA'
    }
  }

  return (
    <MessageProvider formats={formats} messages={messages} locale="en" defaultLocale='en'>
      <PaperProvider theme={theme}>
        <StoreProvider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <Routes theme={theme} />
          </PersistGate>
        </StoreProvider>
      </PaperProvider>
    </MessageProvider>
  )
}

export default App;