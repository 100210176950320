import React from 'react';
import { View, ToastAndroid, Platform, Image, Text } from 'react-native';
import { modalCustomStyles, navigationStyles as styles } from './styles'
import { connect } from 'react-redux';
import { compose, Dispatch } from 'redux';
import { logOutUser } from '../reducers/User/user'
import IdleTimer from 'react-idle-timer'
import { Button, IconButton } from 'react-native-paper';
import Modal from 'react-modal'

export interface ComponentProps {
}

export interface DispatchProps {
    logOutUser: () => any
}

export interface StateProps {
}

export interface IdleTimerContainerState {
    showModal: boolean
    startTimer: boolean
    seconds: number
}

export type IdleTimerContainerProps = ComponentProps & DispatchProps & StateProps

class IdleTimerContainer extends React.Component<IdleTimerContainerProps, IdleTimerContainerState> {
    idleTimer: any
    timer!: NodeJS.Timer;
    constructor(props: IdleTimerContainerProps) {
        super(props)
        this.state = {
            showModal: false,
            startTimer: false,
            seconds: 1
        }
        this.idleTimer = null
    }

    componentDidMount() {
        clearInterval(this.timer)
        this.setState({ startTimer: false })
    }

    componentWillUnmount() {
        clearInterval(this.timer)
    }

    handleOnAction = (event: Event) => {
        // console.log('user did something', event)
    }

    handleOnActive = (event: Event) => {
        // console.log('user is active', event)
        // console.log('time remaining', this.idleTimer.getRemainingTime())
    }

    handleOnIdle = (event: Event) => {
        if (this.state.seconds > 0 && !this.state.startTimer) {
            this.setState({
                showModal: true,
                startTimer: true,
                seconds: 30
            })
            this.timer = setInterval(this.tick, 1000)
        }
    }

    tick = () => {
        if (this.state.seconds > 0) {
            this.setState({ seconds: this.state.seconds - 1 })
        } else {
            if (this.state.startTimer) {
                this.props.logOutUser()
                this.setState({ startTimer: false })
            }
            clearInterval(this.timer)
        }
    }

    doLogout = () => {
        clearInterval(this.timer)
        this.props.logOutUser()
    }

    onClose = () => {
        clearInterval(this.timer)
        this.setState({ showModal: false, startTimer: false })
    }

    render() {
        const { showModal, seconds } = this.state
        return (
            <React.Fragment>
                <IdleTimer
                    crossTab={{
                        emitOnAllTabs: true
                    }}
                    ref={(ref: any) => { this.idleTimer = ref }}
                    timeout={1000 * 60 * 5}
                    onActive={this.handleOnActive}
                    onIdle={this.handleOnIdle}
                    onAction={this.handleOnAction}
                    debounce={250} />
                <Modal
                    isOpen={showModal}
                    onRequestClose={this.onClose}
                    style={modalCustomStyles}
                >
                    <View style={{ position: 'absolute', right: 0, top: 0, zIndex: 1 }}>
                        <IconButton
                            icon='close'
                            size={20}
                            onPress={this.onClose}
                        />
                    </View>
                    <View style={{ width: 400, height: 200, alignItems: 'center' }}>
                        <View style={{ flexDirection: 'column', marginVertical: 40, alignItems: 'center' }}>
                            <Text style={styles.sessionTimeoutText}>{`Your session is about to expire.`}</Text>
                            <Text style={styles.sessionTimeoutText}>{`You will be logged out in ${seconds} seconds.`}</Text>
                        </View>
                        <View style={{ flexDirection: 'row', marginTop: 30 }}>
                            <Button onPress={() => this.doLogout()} mode='contained' color='#000000' labelStyle={{ fontSize: 12, color: '#FFFFFF', fontFamily: "'Montserrat', sans-serif" }} style={{ margin: 5 }}>Log Out</Button>
                            <Button onPress={() => this.onClose()} mode='contained' color='#F7931E' labelStyle={{ fontSize: 12, color: '#FFFFFF', fontFamily: "'Montserrat', sans-serif" }} style={{ margin: 5 }}>Stay Logged In</Button>
                        </View>
                    </View>
                </Modal>
            </React.Fragment>

        )
    }
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
    logOutUser: () => logOutUser(dispatch)
})

export default connect(null, mapDispatchToProps)(IdleTimerContainer)
