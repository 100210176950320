import { Platform, StyleSheet } from 'react-native'
import cpebColors from '../../utils/colors'

export const dashboardStyles = StyleSheet.create({
    title: {
        textTransform: 'uppercase',
        color: '#808080',
        fontSize: 16,
        fontFamily: "'Montserrat', sans-serif",
        marginBottom: 10,
        marginHorizontal: 20
    },
    activeTitle: {
        textTransform: 'uppercase',
        color: '#F7931E',
        fontSize: 16,
        fontFamily: "'Montserrat', sans-serif",
        marginBottom: 10,
        marginHorizontal: 20
    },
    titleContainer: {
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    demoContainer: {
        marginHorizontal: 20
    },
    iconStyle: {
        height: 40,
        width: 40,
        tintColor: '#fff',
        marginBottom: 15
    },
    input: {
        backgroundColor: 'white',
        borderColor: '#D4DADF',
        paddingHorizontal: 5,
        fontSize: 12,
        borderWidth: 1,
        borderRadius: 8,
        fontFamily: "'Montserrat', sans-serif",
        fontWeight: 800,
        width: 290,
        marginBottom: 10
    },
    uploadButton: {
        backgroundColor: 'black',
        borderRadius: 0,
        alignSelf: 'flex-start',
        marginBottom: 0,
        marginTop: 0,
        textAlign: 'left',
        width: 'auto'
    },
    buttonLabel: {
        fontFamily: "'Montserrat', sans-serif",
        fontStyle: 'italic'
    },
    errorMessage: {
        padding: 10,
        borderColor: 'red',
        borderWidth: 2,
        backgroundColor: '#FF3030',
        color: 'white',
        alignSelf: 'center',
        width: '70%',
        alignItems: 'center',
        marginBottom: 15,
    },
    successMessage: {
        backgroundColor: '#55AA55',
        padding: 10,
        alignSelf: 'center',
        width: '50%',
        marginBottom: 20
    },
    successTxt: {
        color: '#ffff',
        textAlign: 'center',
        fontSize: 25,
        fontFamily: "'Montserrat', sans-serif"
    },
    addButton: {
        position: 'absolute',
        top: '30%',
        left: '30%',
        backgroundColor: '#F7931D',
        padding: '17%'
    },
    imageErrorMsg: {
        padding: 5,
        borderColor: 'red',
        borderWidth: 2,
        backgroundColor: '#FF3030',
        color: 'white',
        alignSelf: 'center',
        width: '70%',
        alignItems: 'center',
        marginTop: 15,
    },
    greenBtn: {
        backgroundColor: '#AFCD37',
        borderRadius: 5,
        alignSelf: 'center',
        width: '20%',
        fontSize: 20,
        marginVertical: 15,
        padding: 15,
        flexWrap: 'nowrap'
    },
    greenBtn2: {
        paddingVertical: 20,
        paddingHorizontal: 0,
        alignItems: 'center',
    },
    greenBtnLabel: {
        alignSelf: 'center',
        fontSize: 15,
        color: cpebColors.white
    },
    alertMessage: {
        padding: 10,
        borderWidth: 2,
        color: 'white',
        alignItems: 'center',
        marginTop: 20,
        marginBottom: 15
    },
})