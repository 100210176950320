import React, { useState } from 'react'
import { Button, Text, TextInput, View, KeyboardTypeOptions, Pressable, Platform, StyleProp } from 'react-native'
import { useController } from 'react-hook-form'
import { HelperText, IconButton, RadioButton } from 'react-native-paper'
import styles from './styles'
import { RequiredIcon } from './Form'
import * as _ from 'lodash'

import { TextInputMask } from 'react-native-masked-text'
export interface FieldProps {
    name: string
    label?: string
    control?: any
    type: string
    defaultValue?: string | undefined
    required?: boolean
    error?: string | undefined
    style?: StyleProp<any> | undefined
    labelStyle?: StyleProp<any> | undefined
    errorStyle?: StyleProp<any> | undefined
    formGroupStyle?: StyleProp<any> | undefined
    containerStyle?: StyleProp<any> | undefined
    keyboardType?: KeyboardTypeOptions | undefined
    onChangeValue?: (value: any) => any
    eyeIconSize?: number
    placeholder?: string
    placeholderTextColor?: string
    multiline?: boolean
    dateFormat?: string
    rules?: (value: any, ...args: any) => any
    disabled?: boolean
}

export interface SelectedFieldProps {
    type: string
}

export const SelectedField = (props: any) => {
    const { type, onChange } = props
    switch (type) {
        case 'button':
            return <Button {...props} />
        case 'radio-btn-group':
            return <RadioButton.Group {...props} />
        case 'radio-btn-item':
            return <RadioButton.Item {...props} />
        default:
            return <TextInput {...props} onChangeText={onChange} />
    }
}

const Field = (props: FieldProps) => {
    const { name,
        label,
        control,
        defaultValue,
        required,
        error,
        errorStyle,
        style,
        labelStyle,
        formGroupStyle,
        containerStyle,
        type,
        onChangeValue,
        keyboardType,
        placeholderTextColor,
        multiline,
        disabled
    } = props

    const { field, formState: { errors } } = useController({
        control,
        defaultValue,
        name,
        rules: {
            required
        },
    })

    const isPasswordField = type === 'password'

    const [isShowPassword, setShowPassword] = useState(isPasswordField)

    const _onChangeValue = (value: string) => {
        let _value = type === 'number' ? Number(value) : value
        if (type === 'number') {
            _value = isNaN(Number(_value)) ? '' : _value
        }
        if (props.rules) {
            _value = props.rules(_value)
        }
        field.onChange(_value)
        if (props.onChangeValue) props.onChangeValue(_value)
    }

    const isError = errors && errors.hasOwnProperty(name)
    const showError = (value: any) => {
        if (Array.isArray(value)) {
            return value.map(val => (
                <HelperText visible type='error' style={[styles.errorStyle, errorStyle]}>
                    {val}
                </HelperText>
            ))
        }
        return (
            <HelperText visible type='error' style={[styles.errorStyle, errorStyle]}>
                {value}
            </HelperText>
        )
    }

    const eyeIcon = isShowPassword ? 'eye-off' : 'eye'

    const showIcon = () => {
        if (!isPasswordField) {
            return;
        }
        const _element = (
            <IconButton
                icon={eyeIcon}
                onPress={() => setShowPassword(!isShowPassword)}
                style={{ position: 'absolute', right: 0 }}
                size={props.eyeIconSize || 17.5}
            />
        )
        if (Platform.OS === 'web') {
            return <div title={isShowPassword ? 'Show Password' : 'Hide Password'}>{_element}</div>
        }
        return _element

    }

    return control && (
        <View style={[styles.marginBottomSm, containerStyle]}>
            <View style={[styles.formGroup, formGroupStyle]}>
                {label && <Text style={[styles.fields, labelStyle]}>{label}{required && <RequiredIcon />}</Text>}
                <View>
                    <View style={{ flexDirection: 'row', position: 'relative' }}>
                        {type === 'date' ? (
                            <TextInputMask
                                placeholder={props.placeholder}
                                type={'datetime'}
                                options={{
                                    format: props.dateFormat ?? 'dd/mm/yyyy'
                                }}
                                value={field.value}
                                onChangeText={_onChangeValue}
                                style={[styles.input, style, { paddingRight: isPasswordField ? 40 : 0 }, isError && { borderColor: 'red' }]}
                                keyboardType={keyboardType}
                                placeholderTextColor={placeholderTextColor}
                                disabled={disabled}
                            />
                        ) :
                            <TextInput disabled={disabled} multiline={multiline} placeholderTextColor={placeholderTextColor} placeholder={props.placeholder} value={field.value} onChangeText={_onChangeValue} secureTextEntry={isShowPassword} style={[styles.input, style, { paddingRight: isPasswordField ? 40 : 0 }, isError && { borderColor: 'red' }]} keyboardType={keyboardType} />}
                        {showIcon()}
                    </View>
                    {isError ?
                        isPasswordField && errors[name].types ? (
                            Object.entries(errors[name].types).map(([key, value]) => showError(value))
                        ) : (
                            <HelperText visible type='error' style={[styles.errorStyle, errorStyle]}>
                                {errors[name].message}
                            </HelperText>
                        )
                        : null}
                </View>
            </View>
        </View>
    )
}

export default Field