import React from 'react'

import { createStackNavigator } from '@react-navigation/stack'

import LoginScreen from '../../components/Login/Login'
import Register from '../../components/SignUp/Register'
import WelcomeScreen from '../../components/Welcome/Welcome'

import { RouteName } from '../types'

const AuthStack = createStackNavigator();

export const AuthStackScreen = () => (
    <AuthStack.Navigator
        initialRouteName={RouteName.WelcomePage}
        screenOptions={{
            headerShown: false,
            animationEnabled: false
        }}
    >
        <AuthStack.Screen name={RouteName.WelcomePage} component={WelcomeScreen} />
        <AuthStack.Screen name={RouteName.LoginPage} component={LoginScreen} />
        <AuthStack.Screen name={RouteName.SignUpPage} component={Register} />
    </AuthStack.Navigator>
)