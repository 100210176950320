import React, { useRef } from 'react'
import { useController } from 'react-hook-form'
import { View, Text, ToastAndroid, Platform } from 'react-native'
import DocumentPicker, { DocumentPickerResponse } from 'react-native-document-picker'
import { Button, HelperText } from 'react-native-paper'
import styles from './styles'
import { RequiredIcon } from './Form'
export interface UploadProps {
    name: string
    label?: string
    control?: any
    type?: string
    defaultValue?: string | undefined
    required?: boolean
    error?: string | undefined
    style?: any
    labelStyle?: any
    errorStyle?: any
    formGroupStyle?: any
    containerStyle?: any
    showFile?: boolean
    buttonLabel?: string
    buttonLabelStyle?: any
    buttonStyles?: any
    ref?: any
    onChangeValue?: (value: any) => void
}


const Upload = (props: UploadProps) => {
    const { name,
        label,
        control,
        defaultValue,
        required,
        error,
        errorStyle,
        style,
        labelStyle,
        formGroupStyle,
        containerStyle,
        type,
        showFile,
        buttonLabel,
        buttonStyles,
        buttonLabelStyle,
        onChangeValue
    } = props

    const { field, formState: { errors } } = useController({
        control,
        defaultValue,
        name,
        rules: {
            required
        },
        shouldUnregister: true,
    })

    const webUploadRef: any = useRef(null)

    const onPickFile = (event?: any) => {
        if (Platform.OS === 'web') {
            if (event.target.files[0] !== undefined) field.onChange(event.target.files[0])
            if (onChangeValue) onChangeValue(event.target.files[0])
        } else {
            DocumentPicker.pick({
                type: [DocumentPicker.types.allFiles],
            }).then((response: DocumentPickerResponse) => {
                field.onChange(response)
                if (onChangeValue) onChangeValue(response)
            }).catch(err => {
                if (DocumentPicker.isCancel(err)) {
                    ToastAndroid.showWithGravity(
                        "Cancelled!",
                        ToastAndroid.SHORT,
                        ToastAndroid.BOTTOM
                    )
                }
            })
        }

    }

    const isError = errors && errors.hasOwnProperty(name)
    const isWeb = Platform.OS === 'web'
    return (
        <View style={[styles.marginBottomSm, containerStyle]}>
            <View style={[styles.formGroup, formGroupStyle]}>
                {label && <Text style={[styles.fields, labelStyle]}>{label}{required && <RequiredIcon />}</Text>}
                {isWeb ? (
                    <React.Fragment>
                        <input type="file" name="file" onChange={onPickFile} ref={webUploadRef} style={{ display: 'none' }} />
                        <Button style={[styles.greenBtn, buttonStyles]} onPress={() => webUploadRef?.current?.click()} ><Text style={[styles.textWhite, buttonLabelStyle]}>{buttonLabel ?? 'Upload'}</Text></Button>
                        <HelperText visible type='error' style={[styles.errorStyle, errorStyle]}>
                            {isError && errors[name].message}
                        </HelperText>
                    </React.Fragment>
                ): (
                    <Button style={[styles.greenBtn, buttonStyles]} onPress={onPickFile} ><Text style={[styles.textWhite, buttonLabelStyle]}>{buttonLabel ?? 'Upload'}</Text></Button>
                )}
                {showFile && <Text numberOfLines={1} style={[styles.alignSelfCenter, styles.fileName]}>{field.value?.name}</Text>}
            </View>
            {isError && !isWeb && (
                <HelperText visible type='error' style={[styles.errorStyle, errorStyle]}>
                    {isError && errors[name].message}
                </HelperText>
            )}
        </View>
    )
}

export default Upload