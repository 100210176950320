import React from 'react'
import { Text, View } from 'react-native'
import { SafeAreaView } from 'react-native-safe-area-context'
import styles from './styles'
import { Colors } from 'react-native-paper'
import { generateRandomId } from '../../../utils'

export interface TableProps {
    headerData: string[]
    tableData: any
    tableStyle?: any
    headerContainerStyle?: any
    rowContainerStyle?: any
    headerCellStyle?: any
    headerCellTextStyle?: any
    rowCellStyle?: any,
    userType?: any
}

const TableComponent = (props: TableProps) => {
    const { tableData, headerData } = props

    return (
        <SafeAreaView style={props.tableStyle}>
            <View style={[styles.header, props.headerContainerStyle]}>
                {headerData.map((data: string, i: number) =>
                    <View key={generateRandomId()} style={i === 0 ? [styles.headerCell, props.headerCellStyle, { backgroundColor: '#F7931E', borderLeftWidth: 1 }] : [styles.headerCell, props.headerCellStyle, { backgroundColor: '#F7931E' }]}>
                        <Text style={[props.headerCellTextStyle, { color: '#FFFFFF' }]}>{data}</Text>
                    </View>)}
            </View>
            {tableData?.length > 0 && tableData.map((table: any) => {
                const statusColor = table[8];
                // remove last 2 elements
                table.splice(8, 8)
                if (props.userType !== '0') {
                    delete table[3]
                }

                return (
                    <View style={[styles.rowCellContainer]} key={generateRandomId()}>
                        {table.map((value: string | JSX.Element, i: number) => (
                            <View key={generateRandomId()} style={i === 0 ? [styles.rowCell, { borderLeftWidth: 1, borderRightWidth: 1, borderColor: '#898989' }] : [styles.rowCell, styles.border]}>
                                {(typeof value === 'string' || typeof value === 'number') && i !== (table.length - 1) ? <Text style={{ color: Colors.black, textAlign: 'center', marginTop: 10, fontFamily: "'Montserrat', sans-serif", fontSize: 12 }}>{value}</Text>
                                    : (i === table.length - 1) ? <Text style={[styles.valueCellText, { color: statusColor }]}>{value}</Text>
                                        : value}
                            </View>))}
                    </View>
                )
            })}
        </SafeAreaView >
    )
}

export default TableComponent