import React, {useState, useRef, useEffect} from 'react'
import {
  View,
  Image,
  TouchableOpacity,
  Text,
  Dimensions,
  Platform,
} from 'react-native'
import {Avatar, Title} from 'react-native-paper'
import {ScrollView} from 'react-native-gesture-handler'
import {ResponsiveView, Screen} from '..'
import {useMediaQuery} from 'react-responsive'
import {FormattedMessage} from 'react-intl'
import styles from './styles'
import Terms from '../../components/Terms/Terms'

const BackgroundTemplate = (props: any) => {
  const [isModal, setIsModal] = useState(false)
  const [screenSize, setScreenSize] = useState(Dimensions.get('window').height)

  const viewElement = useRef(undefined)

  useEffect(() => {
    if (Platform.OS === 'web') {
      window.addEventListener('resize', () => {
        setScreenSize(viewElement?.current?.clientHeight)
      })
      if (screenSize !== viewElement?.current?.clientHeight) {
        setScreenSize(viewElement?.current?.clientHeight)
      }
      return () =>
        window.removeEventListener(
          'resize',
          setScreenSize(Dimensions.get('window').height),
        )
    }
  }, [])

  const onClickModal = () => {
    setIsModal(!isModal)
  }

  const isDesktop = useMediaQuery({query: '(min-width: 1100px)'})
  const isMediumScreen = useMediaQuery({query: '(max-width: 1259px)'})
  const isMobile = Screen.isMobile()
  return (
    <View style={{flex: 1, overflowY: 'hidden'}}>
      <View style={{margin: isDesktop ? 10 : 0, marginBottom: 0, flex: 8}}>
        <View style={{flexDirection: 'row'}}>
          {isDesktop && (
            <ResponsiveView height={'98%'} width={'50%'}>
              <Image
                style={{
                  backgroundColor: 'white',
                  width: '100%',
                  height: screenSize,
                }}
                source={require('../../assets/mutual-art.png')}
                resizeMode="stretch"
              />
            </ResponsiveView>
          )}
          <View ref={viewElement}>
            <ResponsiveView
              viewStyle={{
                marginTop: 50,
                alignItems: isDesktop ? undefined : 'center',
              }}
              width={isDesktop ? '50%' : '100%'}>
              <View style={{flexDirection: 'row'}}>
                {isDesktop && (
                  <View
                    style={{
                      height: 2.5,
                      backgroundColor: 'black',
                      width: '60%',
                      marginTop: '4%',
                    }}
                  />
                )}
                <Image
                  style={{width: 70, height: 70, marginHorizontal: 20}}
                  source={require('../../assets/mutual-art2.png')}
                  resizeMode="stretch"
                />
                <Title
                  numberOfLines={3}
                  style={{
                    marginTop: '2%',
                    fontWeight: 'bold',
                    fontSize: isMediumScreen ? 18 : 28,
                    marginRight: 20,
                    fontFamily: "'Montserrat', sans-serif",
                  }}>
                  ARTSHARE
                </Title>
              </View>
              <View style={{padding: isDesktop ? '4%' : 0}}>
                {props.children}
              </View>
            </ResponsiveView>
          </View>
        </View>
      </View>
      <View style={isDesktop ? styles.deskTopFooter : styles.mobileFooter}>
        <TouchableOpacity onPress={() => onClickModal()}>
          <Text style={{fontFamily: "'Montserrat', sans-serif", fontSize: 12}}>
            <FormattedMessage id="Footer.msg.terms" />
          </Text>
        </TouchableOpacity>
      </View>
      <Terms showModal={isModal} closeModal={onClickModal} />
    </View>
  )
}

export default BackgroundTemplate
