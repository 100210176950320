import awsInstance, { methods } from '../../core/axios.config'
import { AxiosError, AxiosResponse, AxiosRequestConfig } from 'axios'
import { Dispatch } from 'redux'
import { Audit } from '.'

export const BEGIN_GET_AUDIT_LOGS = 'API/ITEM/BEGIN_GET_AUDIT_LOGS'
export const SUCCESS_GET_AUDIT_LOGS = 'API/ITEM/SUCCESS_GET_AUDIT_LOGS'
export const ERROR_GET_AUDIT_LOGS = 'API/ITEM/ERROR_GET_AUDIT_LOGS'

export const getAuditLogInitialState = {
    response: [],
    loading: false,
}

export interface GetAuditLogState {
    response: Audit[]
    status?: number
    statusText?: string
    loading: boolean
    error?: any
}

export interface GetAuditLogActions {
    type: string
    [item: string]: any
}

export const reducer = (newState: GetAuditLogState = getAuditLogInitialState, action: GetAuditLogActions) => {
    switch (action.type) {
        case BEGIN_GET_AUDIT_LOGS:
            return Object.assign({}, newState, { loading: true })

        case SUCCESS_GET_AUDIT_LOGS:
            return Object.assign({}, newState, {
                response: action.data,
                loading: false,
                statusText: 'success',
                status: action.status
            })

        case ERROR_GET_AUDIT_LOGS:
            return Object.assign({}, newState, {
                statusText: 'error',
                loading: false,
                status: action.status
            })
        default:
            return newState
    }
}

export const beginGetAuditLog = () => ({
    type: BEGIN_GET_AUDIT_LOGS,
})

export const successGetAuditLog = (response: AxiosResponse) => ({
    type: SUCCESS_GET_AUDIT_LOGS,
    data: response.data.message,
    status: response.status
})

export const errorGetAuditLog = (error: AxiosError) => ({
    type: ERROR_GET_AUDIT_LOGS,
    status: error.response?.status,
})

export const getAuditLogs = (dispatch: Dispatch, userId: string, userType: number) => {
    let url = `/audit`
    if (userType !== 0) {
        url = `/audit?field=userId&value=${userId}`
    }

    const config: AxiosRequestConfig = {
        method: methods.GET,
        url
    }
    dispatch(beginGetAuditLog())
    return awsInstance(config)
        .then((response: AxiosResponse) => {
            dispatch(successGetAuditLog(response))
            return response
        })
        .catch((error: AxiosError) => {
            dispatch(errorGetAuditLog(error))
            return new Promise<AxiosResponse>((resolve, reject) => { reject(error) })
        })

}

export default reducer