import React from 'react'

import { createStackNavigator } from '@react-navigation/stack'

import DashboardScreen from '../../components/Dashboard/Dashboard'
import ResetPasswordScreen from '../../components/ResetPassword/ResetPassword'
import WalletScreen from '../../components/Wallet/Wallet'
import SettingsScreen from '../../components/Settings/Settings'
import ListingScreen from '../../components/Listing/Listing'
import ListScreen from '../../components/Listing/List'
import DetailScreen from '../../components/ItemDetails/ItemDetails'
import ActiveListScreen from '../../components/ActiveList/ActiveList'
import ProfileScreen from '../../components/Profile/Profile'
import { RouteName } from '../types'

const MainStack = createStackNavigator();

export const MainStackScreen = ({ isResetPwd, defaultPage = RouteName.DashboardPage }: { isResetPwd: string, defaultPage?: string }) => (
    <MainStack.Navigator
        initialRouteName={isResetPwd === '1' ? RouteName.ResetPwdPage : defaultPage}
        screenOptions={{
            headerShown: false,
            animationEnabled: false
        }}
    >
        {isResetPwd === '1' ? <MainStack.Screen name={RouteName.ResetPwdPage} component={ResetPasswordScreen} /> : <MainStack.Screen name={RouteName.DashboardPage} component={DashboardScreen} />}
        <MainStack.Screen name={RouteName.WalletPage} component={WalletScreen} />
        <MainStack.Screen name={RouteName.SettingsPage} component={SettingsScreen} />
        <MainStack.Screen name={RouteName.ListingPage} component={ListingScreen} />
        <MainStack.Screen name={RouteName.ListPage} component={ListScreen} />
        <MainStack.Screen name={RouteName.ItemDetailPage} component={DetailScreen} />
        <MainStack.Screen name={RouteName.ActiveListPage} component={ActiveListScreen} />
        <MainStack.Screen name={RouteName.ProfilePage} component={ProfileScreen} />
    </MainStack.Navigator>


)