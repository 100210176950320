import { CustomFormats } from 'react-intl'

const formattedNumber: CustomFormats = {
    number: {
        USD: {
            style: 'currency',
            currency: 'USD'
        },
        PHP: {
            style: 'currency',
            currency: 'PHP'
        }
    }
}

export default formattedNumber