import activeModalReducer, { showModalInitialState, ShowModalState } from './showModal'

import { combineReducers } from 'redux'

export interface Modal {
    showModal: string
}

export const modalInitialState: ModalInitialState = {
    activeModal: showModalInitialState
}

export interface ModalInitialState {
    activeModal: ShowModalState
}

const modalReducer = combineReducers({
    activeModal: activeModalReducer
})

export default modalReducer