import React from 'react'

import { View, Text, TextInput } from 'react-native'
import { StackNavigationProp } from '@react-navigation/stack'

import { RouteName } from '../../navigation/types'

import { Button, IconButton, Title } from 'react-native-paper'

import { resetPwdStyles as styles } from './styles'

import { connect } from 'react-redux'
import { compose, Dispatch } from 'redux'
import * as _ from 'lodash'

import { FormattedMessage } from 'react-intl'

import { AxiosPromise } from 'axios'
import { resetUserPassword, clearUserResetPassword } from '../../reducers/ResetPassword/resetPassword'
import { retrieveUser } from '../../reducers/User/user'

import * as yup from 'yup'

import { BackgroundTemplate, Screen } from '../../ReusableComponents/'

const FormSchema = yup.object({
    oldPassword: yup.string()
        .required('Password is required'),
    password: yup.string()
        .required('Password is required')
        .matches(/[A-Z]/, 'Password should have atleast 1 uppercase')
        .matches(/[a-z]/, 'Password should have atleast 1 lowercase')
        .matches(/[0-9]/, 'Password should have atleast 1 number')
        .min(8, 'Minimum length should be 8 character'),
    verifyPassword: yup.string().oneOf([yup.ref('password'), null], "Passwords don't match").required('Please verify your password'),

})

export interface ComponentProps {
    navigation: StackNavigationProp<any>
    isRefreshing: boolean,
    route: any
}

export interface StateProps {
    user: any,
    resetPwd: any,
    isError: boolean,
    isLoading: boolean
}

export interface DispatchProps {
    onResetPassword: (oldPassword: any, password: any) => AxiosPromise,
    onClearResetPwd: () => any,
    onRetrieveUser: () => any
}

export interface ResetPwdPageState {
    value: any,
    isSubmit: boolean,
    validationValue: any,
    isLoading: boolean,
    errMsg: string,
    isShowOldPassword: boolean
    isShowNewPassword: boolean
    isShowVerifyPassword: boolean
}
export const notRequiredFields = []

export type ResetPwdPageProps = ComponentProps & StateProps & DispatchProps

class ResetPwdPage extends React.Component<ResetPwdPageProps, ResetPwdPageState> {
    constructor(props: ResetPwdPageProps) {
        super(props)
        this.state = {
            isSubmit: false,
            isLoading: false,
            isShowOldPassword: false,
            isShowNewPassword: false,
            isShowVerifyPassword: false,
            value: {
                oldPassword: '',
                password: '',
                verifyPassword: '',
            },
            validationValue: {},
            errMsg: ''
        }
    }

    componentDidMount() {
        this.props.onClearResetPwd()
    }

    onChangeValue = (key: string, value: string) => {
        const _value = { ...this.state.value }
        _value[key] = value
        this.setState({ value: _value })
    }

    onSubmit = () => {
        const _value = { ...this.state.value }
        let isSuccessValidation: boolean = true
        _.forIn(_value, (value: any, key: any) => {
            if (!Boolean(value)) {
                isSuccessValidation = Boolean(notRequiredFields.find((item: string) => item === key))
            }
        })

        if (Boolean(this.state.value?.verifyPassword) && this.state.value.verifyPassword !== this.state.value.password) {
            isSuccessValidation = false
        }

        const oldPwd = _value?.oldPassword
        const pwd = _value?.password

        if (isSuccessValidation) {
            this.setState({ isSubmit: true })
            this.props.onResetPassword(oldPwd, pwd)
                .then(() => {
                    this.props.onRetrieveUser()
                        .then(() => {
                            this.props.navigation.push(RouteName.DashboardPage)
                        })
                }).catch(err => {
                    let msg = ''
                    if (err.code === 'NotAuthorizedException') {
                        msg = 'Invalid old password'
                    } else if (err.code === 'InvalidPasswordException' ||
                        err.code === 'InvalidParameterException') {
                        msg = 'Password did not conform with policy'
                    } else if (err.code === 'LimitExceededException') {
                        msg = 'Attempt limit exceeded, please try after some time.'
                    } else {
                        msg = 'Unknown Error'
                    }

                    this.setState({ errMsg: msg })
                })
        }
    }

    passwordResultColor = (key: string) => {
        const password = this.state.value?.password
        if (!password) {
            return styles.plainColor
        }
        let isSuccess = false
        if (key === 'lengthValidation') {
            isSuccess = password.length >= 8
        } else if (key === 'lowercaseValidation') {
            isSuccess = Boolean(password.match(/[a-z]/g))
        } else if (key === 'uppercaseValidation') {
            isSuccess = Boolean(password.match(/[A-Z]/g))
        } else if (key === 'specialcharValidation') {
            isSuccess = Boolean(password.match(/[!@#$%^&*]/g))
        } else {
            isSuccess = Boolean(password.match(/[0-9]/g))
        }

        if (isSuccess) {
            return styles.successColor
        }

        return styles.errorColor
    }

    fieldValidator = (inputValue: string, fieldName: string) => {
        if (!inputValue) return `${fieldName} can't be empty.`
        return ''
    }

    onValidateField = (key: string, value?: string | undefined) => {
        const _validationValue = { ...this.state.validationValue }

        if (key === 'verifyPassword') {
            _validationValue[key] = {
                error: Boolean(this.state.value?.verifyPassword) && this.state.value.verifyPassword !== this.state.value.password
            }
        } else {
            _validationValue[key] = {
                error: Boolean(value)
            }
        }

        this.setState({ validationValue: _validationValue })
    }


    render() {
        const { validationValue, isSubmit, isLoading, value, isShowOldPassword, isShowNewPassword, isShowVerifyPassword } = this.state
        const eyeIconOldPw = isShowOldPassword ? 'eye' : 'eye-off'
        const eyeIconNewPw = isShowNewPassword ? 'eye' : 'eye-off'
        const eyeIconVerifyPw = isShowVerifyPassword ? 'eye' : 'eye-off'
        return (
            <BackgroundTemplate>
                <View style={[styles.formStyle]} >
                    <Title style={styles.title}><FormattedMessage id='ResetPwd.msg.resetPwd' /></Title>
                    {this.props.isError && (
                        <View style={{ backgroundColor: 'red', padding: 10, maxWidth: 250, alignSelf: 'center', width: '100%', marginBottom: 10 }}>
                            <Text style={{ color: '#ffff', textAlign: 'center' }}>{this.state.errMsg}</Text>
                        </View>
                    )}
                    <View style={{ alignSelf: 'center' }}>
                        <Screen minWidth={400} style={styles.formGroup}>
                            <View style={{ flexDirection: 'row', position: 'relative' }}>
                                <TextInput
                                    style={isSubmit && !value?.oldPassword ? [styles.input, styles.errorBorder] : styles.input}
                                    secureTextEntry={!isShowOldPassword}
                                    onChangeText={(_value: string) => this.onChangeValue('oldPassword', _value)}
                                    placeholder="Old Password"
                                    placeholderTextColor='#C0C1C2'
                                    onSubmitEditing={this.onSubmit}
                                />
                                <IconButton
                                    icon={eyeIconOldPw}
                                    onPress={() => this.setState({ isShowOldPassword: !isShowOldPassword })}
                                    style={{ position: 'absolute', right: 0 }}
                                    size={24}
                                />
                            </View>

                        </Screen>
                        <Screen maxWidth={399} style={styles.formGroup}>
                            <View style={{ flexDirection: 'row', position: 'relative' }}>
                                <TextInput
                                    style={[styles.input, isSubmit && !value?.oldPassword && styles.errorBorder, { alignSelf: 'center', width: 250 }]}
                                    secureTextEntry={!isShowOldPassword}
                                    onChangeText={(_value: string) => this.onChangeValue('oldPassword', _value)}
                                    placeholder="Old Password"
                                    placeholderTextColor='#C0C1C2'
                                    onSubmitEditing={this.onSubmit}
                                />
                                <IconButton
                                    icon={eyeIconOldPw}
                                    onPress={() => this.setState({ isShowOldPassword: !isShowOldPassword })}
                                    style={{ position: 'absolute', right: 0 }}
                                    size={24}
                                />
                            </View>

                        </Screen>
                        <Screen minWidth={400} style={styles.formGroup}>
                            <View style={{ flexDirection: 'row', position: 'relative' }}>
                                <TextInput
                                    style={isSubmit && !value?.password ? [styles.input, styles.errorBorder] : styles.input}
                                    secureTextEntry={!isShowNewPassword}
                                    onChangeText={(_value: string) => this.onChangeValue('password', _value)}
                                    placeholder="New Password"
                                    placeholderTextColor='#C0C1C2'
                                    onSubmitEditing={this.onSubmit}
                                />
                                <IconButton
                                    icon={eyeIconNewPw}
                                    onPress={() => this.setState({ isShowNewPassword: !isShowNewPassword })}
                                    style={{ position: 'absolute', right: 0 }}
                                    size={24}
                                />
                            </View>

                        </Screen>
                        <Screen maxWidth={399} style={styles.formGroup}>
                            <View style={{ flexDirection: 'row', position: 'relative' }}>
                                <TextInput
                                    style={[styles.input, isSubmit && !value?.oldPassword && styles.errorBorder, { alignSelf: 'center', width: 250 }]}
                                    secureTextEntry={!isShowNewPassword}
                                    onChangeText={(_value: string) => this.onChangeValue('password', _value)}
                                    placeholder="New Password"
                                    placeholderTextColor='#C0C1C2'
                                    onSubmitEditing={this.onSubmit}
                                />
                                <IconButton
                                    icon={eyeIconNewPw}
                                    onPress={() => this.setState({ isShowNewPassword: !isShowNewPassword })}
                                    style={{ position: 'absolute', right: 0 }}
                                    size={24}
                                />
                            </View>
                        </Screen>
                        <Screen minWidth={400} style={styles.formGroup}>
                            <View style={{ flexDirection: 'row', position: 'relative' }}>
                                <TextInput
                                    secureTextEntry={!isShowVerifyPassword}
                                    style={isSubmit && !value?.verifyPassword ? [styles.input, styles.errorBorder] : styles.input}
                                    onBlur={() => this.onValidateField('verifyPassword')}
                                    onChangeText={(_value: string) => this.onChangeValue('verifyPassword', _value)}
                                    placeholder="Verify Password"
                                    placeholderTextColor='#C0C1C2'
                                    onSubmitEditing={this.onSubmit}
                                />
                                <IconButton
                                    icon={eyeIconVerifyPw}
                                    onPress={() => this.setState({ isShowVerifyPassword: !isShowVerifyPassword })}
                                    style={{ position: 'absolute', right: 0 }}
                                    size={24}
                                />
                            </View>

                            {validationValue?.verifyPassword?.error && (
                                <View style={styles.errorContainer}>
                                    <Text style={styles.errorColor}><FormattedMessage id='ResetPwd.msg.passwordNotMatch' /></Text>
                                </View>
                            )}
                        </Screen>
                        <Screen maxWidth={399} style={styles.formGroup}>
                            <View style={{ flexDirection: 'row', position: 'relative' }}>
                                <TextInput
                                    secureTextEntry={!isShowVerifyPassword}
                                    style={[styles.input, isSubmit && !value?.oldPassword && styles.errorBorder, { alignSelf: 'center', width: 250 }]}
                                    onBlur={() => this.onValidateField('verifyPassword')}
                                    onChangeText={(_value: string) => this.onChangeValue('verifyPassword', _value)}
                                    placeholder="Verify Password"
                                    placeholderTextColor='#C0C1C2'
                                    onSubmitEditing={this.onSubmit}
                                />
                                <IconButton
                                    icon={eyeIconVerifyPw}
                                    onPress={() => this.setState({ isShowVerifyPassword: !isShowVerifyPassword })}
                                    style={{ position: 'absolute', right: 0 }}
                                    size={24}
                                />
                            </View>

                            {validationValue?.verifyPassword?.error && (
                                <View style={styles.errorContainer}>
                                    <Text style={styles.errorColor}><FormattedMessage id='ResetPwd.msg.passwordNotMatch' /></Text>
                                </View>
                            )}
                        </Screen>
                        <View style={styles.requirementsContainer}>
                            <Text style={[styles.text, this.passwordResultColor('lengthValidation')]}><FormattedMessage id='ResetPwd.msg.minimunPasswordLengthValidation' /></Text>
                            <Text style={[styles.text, this.passwordResultColor('uppercaseValidation')]}><FormattedMessage id='ResetPwd.msg.uppercaseValidation' /></Text>
                            <Text style={[styles.text, this.passwordResultColor('lowercaseValidation')]}><FormattedMessage id='ResetPwd.msg.lowercaseValidation' /></Text>
                            <Text style={[styles.text, this.passwordResultColor('specialcharValidation')]}><FormattedMessage id='ResetPwd.msg.specialcharValidation' /></Text>
                            <Text style={[styles.text, this.passwordResultColor('numberValidation')]}><FormattedMessage id='ResetPwd.msg.numberValidation' /></Text>
                        </View>
                        <View style={[styles.formGroup2]}>
                            <Button loading={this.props.isLoading} onPress={this.onSubmit} style={styles.registerBtn} labelStyle={styles.textWhite}>Submit</Button>
                        </View>
                    </View>
                </View>
            </BackgroundTemplate>

        )
    }
}

const mapStateToProps = (state: any) => {
    return ({
        user: state.api?.user?.authUser,
        resetPwd: state.api?.resetPwd?.resetPassword,
        isLoading: state.api?.resetPwd.resetPassword.loading,
        isError: state.api?.resetPwd?.resetPassword.statusText === 'error'
    })
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
    onResetPassword: (oldPassword: any, password: any) => resetUserPassword(dispatch, { oldPassword, password }),
    onClearResetPwd: () => dispatch(clearUserResetPassword()),
    onRetrieveUser: () => retrieveUser(dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(compose(ResetPwdPage))