import { Platform, StyleSheet } from 'react-native'
import { black } from 'react-native-paper/lib/typescript/styles/colors'
import { theme } from '../../core/theme'
import cpebColors from '../../utils/colors'
import { Screen } from '../../ReusableComponents'

export const loginStyles = StyleSheet.create({
  formStyle: {
    alignSelf: 'center',
    width: '100%',
    backgroundColor: '#fff',
    // padding: '4%',
    marginTop: 70
  },
  scrollView: {
    backgroundColor: Platform.OS === 'web' ? '#F0F2F5' : '#fff'
  },
  title: {
    alignSelf: 'center',
    fontSize: 30,
    marginTop: 15,
    marginBottom: 15
  },
  loginContainer: {
    alignSelf: 'center',
    maxWidth: 500,
    width: '100%',
    backgroundColor: '#fff',
  },
  loginContainerShadow: {
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 5,
    },
    shadowOpacity: 0.34,
    shadowRadius: 6.27,
    elevation: 10,
    marginTop: 100,
  },
  formGroup: {
    marginHorizontal: '15%',
    // alignItems: 'center',
  },
  header: {
    color: cpebColors.labelColor,
    fontSize: 30
  },
  linkBtn: {
    width: '100%',
    alignItems: 'center',
    marginBottom: 10,
    marginTop: 10,
    color: cpebColors.labelColor
  },
  row: {
    flexDirection: 'row',
    marginTop: 4,
  },
  linkText: {
    fontSize: 13,
    color: theme.colors.secondary,
    marginTop: 1,
  },
  link: {
    fontWeight: 'bold',
    color: theme.colors.primary,
  },
  container: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
  },
  checkboxContainer: {
    // width: '100%',
    // paddingTop: 5,
    // maxWidth: 300,
    flexDirection: "row",
    marginBottom: 15,
    alignSelf: 'center',
    marginVertical: 15
  },
  checkbox: {
    flex: 1,
    padding: 10,
  },
  label: {
    margin: 8,
  },
  input: {
    backgroundColor: 'white',
    borderColor: 'black',
    paddingHorizontal: 10,
    fontSize: 13,
    borderWidth: 1,
    borderRadius: 0,
    fontFamily: "'Montserrat', sans-serif",
    fontWeight: '800',
    width: 400,
    marginBottom: 13
  },
  fieldFormGroupStyle: {
    flexDirection: 'row'
  },
  fieldLabelStyle: {
    fontSize: 20,
    width: '35%'
  },
  loginBtn: {
    marginBottom: '50%',
    marginTop: 20,
    marginLeft: 20,
    width: 200,
    backgroundColor: '#F7931E'
  },
  textWhite: {
    color: 'white'
  },
  button: {
    width: '88%',
    backgroundColor: '#1E5732',
    paddingVertical: 1,
    borderRadius: 20,
    borderWidth: 1,
    marginBottom: 10,
    alignSelf: 'center'
  },
})


export const backgroundStyles = StyleSheet.create({
  background: {
    flex: 1,
    width: '100%',
    backgroundColor: '#FFFFFF',
  },
  container: {
    flex: 1,
    padding: 20,
    width: '100%',
    maxWidth: 340,
    alignSelf: 'center',
    alignItems: 'center',
    justifyContent: 'center',
  },
})

export const buttonStyles = StyleSheet.create({
  button: {
    width: 300,
    backgroundColor: '#1E5732',
    paddingVertical: 1,
    borderRadius: 20,
    borderWidth: 1,
    marginBottom: 10
  },
  text: {
    fontWeight: 'bold',
    fontSize: 12,
    lineHeight: 18,
  },
})


export const logoStyles = StyleSheet.create({
  image: {
    width: '100%',
    maxWidth: 300,
    height: 120,
    marginBottom: 20,
    marginTop: 50,
    alignSelf: 'center'
  },
})

export const textInputStyles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    margin: 10,
    width: '100%',
    marginVertical: 8,
  },
  input: {
    backgroundColor: '#F2F2F2',
    borderRadius: 20,
    borderWidth: 1,
    borderColor: '#fff',
    width: 300,
    paddingLeft: 12,
    paddingVertical: 12,
  },
  description: {
    fontSize: 13,
    color: theme.colors.secondary,
    paddingTop: 8,
  },
  error: {
    fontSize: 13,
    color: theme.colors.error,
    paddingTop: 8,
  },
  // sectionStyle: {
  //   flexDirection: 'row',
  //   justifyContent: 'center',
  //   alignItems: 'center',
  //   backgroundColor: '#D1D3D4',
  // },
})
