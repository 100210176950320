import { StyleSheet } from 'react-native'
import { theme } from '../core/theme'
import { Colors } from 'react-native-paper'

export const navigationStyles = StyleSheet.create({
    drawerContent: {
        flex: 1,
    },
    userInfoSection: {
        paddingLeft: 20,
    },
    title: {
        fontSize: 16,
        marginTop: 3,
        fontWeight: 'bold',
    },
    caption: {
        fontSize: 14,
        lineHeight: 14,
    },
    row: {
        marginTop: 20,
        flexDirection: 'row',
        alignItems: 'center',
    },
    section: {
        flexDirection: 'row',
        alignItems: 'center',
        marginRight: 15,
    },
    paragraph: {
        fontWeight: 'bold',
        marginRight: 3,
    },
    drawerSection: {
        marginTop: 15,
    },
    bottomDrawerSection: {
        marginBottom: 15,
        borderTopColor: '#f4f4f4',
        borderTopWidth: 1
    },
    preference: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        paddingVertical: 12,
        paddingHorizontal: 16,
    },
    sideNavHeader: {
        backgroundColor: "#F7931E",
        height: 120
    },
    greetingsText: {
        fontSize: 14,
        fontFamily: "'Montserrat', sans-serif",
        fontWeight: '700',
        color: '#333333',
        marginTop: 40,
        marginLeft: 25
    },
    meterNoText: {
        color: "#F0F4F4",
        marginLeft: 25
    },
    sessionTimeoutText: {
        fontSize: 14,
        fontFamily: "'Montserrat', sans-serif"
    }
})

export const modalCustomStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
    }
}
