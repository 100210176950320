import React, {useState} from 'react'
import Modal from 'react-modal'
import {Text, View} from 'react-native'
import {Button} from 'react-native-paper'
import {Checkbox, Headline, Paragraph, Subheading} from 'react-native-paper'
import {useMediaQuery} from 'react-responsive'

export const KYCModal = (props: any) => {
  const {isOpen, onRequestClose, onHandleKYC, onSubmit, data, isLoading} = props
  const [checkboxes, setCheckboxes]: any = useState({
    checkbox1: 'unchecked',
    checkbox2: 'unchecked',
  })

  const isDesktop = useMediaQuery({query: '(min-width: 800px)'})

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      style={{
        content: {
          top: '50%',
          left: '50%',
          marginRight: '-50%',
          transform: 'translate(-50%, -50%)',
          width: isDesktop ? 700 : 'auto',
          right: 'auto',
          bottom: 'auto',
          height: isDesktop ? 'auto' : '100%',
        },
      }}>
      <View style={{flexDirection: 'column', width: '100%'}}>
        <Headline style={{textAlign: 'center', marginBottom: 20}}>
          Know Your Customer
        </Headline>
        <Paragraph style={{textAlign: 'justify', marginBottom: 15}}>
          Make sure to familiarize yourself with amendment 5 to the Directive
          (EU) 2018/843 of the European Parliament and of the Council. Due to
          AMLD 5 since January 1, 2020 verification is mandatory for all the
          Customers. ArtShare has adopted an online verification procedure which
          will require you to complete an online questionnaire and attach
          certain documents as support.
        </Paragraph>
        <Paragraph style={{textAlign: 'justify', marginBottom: 15}}>
          Please be noted that your information cannot be changed after the
          verification. Please provide complete and accurate information.
        </Paragraph>
        <Subheading style={{textAlign: 'justify', marginBottom: 5}}>
          Verification Requirements:
        </Subheading>
        <View style={{marginBottom: 15}}>
          <Paragraph style={{lineHeight: 16}}>Complete online form.</Paragraph>
          <Paragraph style={{lineHeight: 16}}>
            Age: Must be at least 18 years old.
          </Paragraph>
          <Paragraph style={{lineHeight: 16}}>
            Information: residential address.
          </Paragraph>
          <Paragraph style={{lineHeight: 16}}>
            Identification: valid government-issued identification with picture;
            i.e ., passport, national ID card, driving license, residency card,
            etc. Video with this document.
          </Paragraph>
        </View>
        <View style={{marginBottom: 15}}>
          <Subheading>Note:</Subheading>
          <Paragraph>
            1. All documents provided must be in the Roman/Latin alphabet or a
            certified English translation is required.
          </Paragraph>
          <Paragraph>
            2. All files uploaded must be in jpg, png or jpeg.
          </Paragraph>
          <Paragraph>
            3. All fields in the following verification form are mandatory (NA
            for Non-Applicable can be used).
          </Paragraph>
          <Paragraph>
            4. To avoid possible delays, please check the correctness before
            submitting documents, please double check you do not send the same
            document twice and possibly forget one
          </Paragraph>
        </View>
        <View>
          <View style={{flexDirection: 'row', marginBottom: 20}}>
            <Checkbox
              status={checkboxes['checkbox1']}
              onPress={() =>
                setCheckboxes({
                  ...checkboxes,
                  checkbox1:
                    checkboxes['checkbox1'] === 'unchecked'
                      ? 'checked'
                      : 'unchecked',
                })
              }
            />
            <Paragraph>
              I understand that due to high verification demand, processing may
              take up to 36 hours after all forms are submitted.
            </Paragraph>
          </View>
          <View style={{flexDirection: 'row', marginBottom: 20}}>
            <Checkbox
              status={checkboxes['checkbox2']}
              onPress={() =>
                setCheckboxes({
                  ...checkboxes,
                  checkbox2:
                    checkboxes['checkbox2'] === 'unchecked'
                      ? 'checked'
                      : 'unchecked',
                })
              }
            />
            <Paragraph style={{textAlignVertical: 'center', marginTop: 7.5}}>
              I agree to allow ArtMutual.io to store and process my personal
              data.
            </Paragraph>
          </View>
        </View>
        <View
          style={{
            flexDirection: 'row',
            flexWrap: 'wrap',
            marginTop: '5%',
            alignSelf: 'center',
          }}>
          <Button
            loading={isLoading}
            disabled={
              checkboxes['checkbox1'] === 'unchecked' &&
              checkboxes['checkbox2'] === 'unchecked'
            }
            onPress={() => onSubmit(data)}
            style={{
              backgroundColor: '#F7931E',
              padding: 5,
              borderRadius: 0,
              marginRight: 10,
              marginBottom: 10,
            }}
            labelStyle={{color: 'white', fontSize: 12}}>
            <Text>I Agree</Text>
          </Button>
          <Button
            onPress={onRequestClose}
            style={{
              backgroundColor: '#000000',
              padding: 5,
              borderRadius: 0,
              marginBottom: 10,
            }}>
            <Text style={{color: 'white', fontSize: 12}}>CANCEL</Text>
          </Button>
        </View>
      </View>
    </Modal>
  )
}

export const SuccessModal = (props: any) => {
  const isDesktop = useMediaQuery({query: '(min-width: 800px)'})
  return (
    <Modal
      isOpen={props?.isOpen}
      style={{
        content: {
          top: '50%',
          left: '50%',
          marginRight: '-50%',
          transform: 'translate(-50%, -50%)',
          width: isDesktop ? 500 : 'auto',
          right: 'auto',
          bottom: 'auto',
          height: 200,
        },
      }}
      onRequestClose={() => props.onHandleKYC(false)}>
      <View>
        <Headline style={{textAlign: 'center', marginBottom: 20}}>
          Thank you for providing all the required information.
        </Headline>
        <Paragraph style={{textAlign: 'center', marginBottom: 30}}>
          We’ll get back to you within 2-3 business days
        </Paragraph>
        <Button
          onPress={() => props.onHandleKYC(false)}
          style={{
            backgroundColor: '#F7931E',
            padding: 5,
            borderRadius: 0,
            marginRight: 10,
            marginBottom: 10,
            alignSelf: 'center',
          }}
          labelStyle={{color: 'white', fontSize: 12}}>
          Return to Dashboard
        </Button>
      </View>
    </Modal>
  )
}
