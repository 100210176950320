import { Platform, StyleSheet } from 'react-native'
import cpebColors from '../../utils/colors'

export const tranHistoryStyles = StyleSheet.create({
    normalText: {
        fontFamily: "'Montserrat', sans-serif",
    },
    boldText: {
        fontWeight: 'bold'
    },
    italicText: {
        fontStyle: 'italic'
    },
    tableCol: {
        flexDirection: 'row',
        height: 60,
        justifyContent: 'space-between',
        borderBottomColor: '#EDEDED',
        borderBottomWidth: 1,
        borderStyle: 'dashed'
    },
    tableHeader: {
        borderRightWidth: 1,
        borderColor: '#898989'
    },
    borderRight: {
        borderRightWidth: 2,
        borderColor: '#898989'
    },
    basicLabel: {
        fontSize: 13,
        textAlign: 'center'
    },
    orangeLabel: {
        color: '#FFFFFF',
        backgroundColor: '#F7931E'
    },
    input: {
        flex: 1,
        backgroundColor: '#fff',
        fontSize: 12,
        width: '100%',
        height: 35,
        fontFamily: "'Montserrat', sans-serif",
    },
})