import React from 'react'

import { View, ScrollView, Text, TouchableOpacity, Dimensions, Image, Platform } from 'react-native'
import { StackNavigationProp } from '@react-navigation/stack'

import { dashboardStyles as styles } from './styles'
import { IconButton, Title } from 'react-native-paper'

import { connect } from 'react-redux'
import { compose, Dispatch } from 'redux'
import { FormattedMessage } from 'react-intl'
import { ReduxAppState } from '../../utils/types'
import SideNavContentHOC from '../../hoc/SidenavContent'
import HeaderContentHOC from '../../hoc/HeaderContent'


export interface ComponentProps {
    navigation: StackNavigationProp<any>
    isRefreshing: boolean
}

export interface DispatchProps {
}

export interface StateProps {
    isLoading: boolean
    isError: boolean
}

export interface SettingsPageState { }

export type SettingsPageProps = ComponentProps & DispatchProps & StateProps

class SettingsPage extends React.Component<SettingsPageProps, SettingsPageState> {
    constructor(props: SettingsPageProps) {
        super(props)
    }

    render() {
        return (
            <ScrollView style={styles.demoContainer}>
                {/* Overview */}
                <View style={styles.titleContainer}>
                    <View>
                        {/* <Title numberOfLines={1} style={styles.title}>
                            <FormattedMessage id='Settings.msg.title' />
                        </Title> */}
                        <View>
                            <Title style={{ fontSize: 40, marginTop: '40%' }}>COMING SOON....</Title>
                        </View>
                    </View>
                </View>
            </ScrollView>
        )
    }
}

const mapStateToProps = (state: ReduxAppState) => {
    return ({

    })
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(compose(HeaderContentHOC, SideNavContentHOC)(SettingsPage as any))