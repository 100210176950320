export default {
    apiGateway: {
        REGION: 'ap-southeast-1',
        URL: 'https://3rvz0xg79a.execute-api.ap-southeast-1.amazonaws.com/prod'
    },
    cognito: {
        REGION: 'ap-southeast-1',
        USER_POOL_ID: 'ap-southeast-1_ANnVuc6M9',
        APP_CLIENT_ID: 'iuoovvsh2vd6fqopej7a46pt3',
        IDENTITY_POOL_ID: ''
    }
};