import { Dispatch } from 'redux'
import { User } from '.'
import { Auth } from '@aws-amplify/auth'

export const BEGIN_USER_REGISTER = 'API/REGISTER_USER/BEGIN_USER_REGISTER'
export const SUCCESS_USER_REGISTER = 'API/REGISTER_USER/SUCCESS_USER_REGISTER'
export const ERROR_USER_REGISTER = 'API/REGISTER_USER/ERROR_USER_REGISTER'
export const CLEAR_USER_REGISTER = 'API/REGISTER_USER/CLEAR_USER_REGISTER'

export interface RegisterUserState {
    response: User | undefined
    status?: number,
    statusText?: string,
    loading: boolean,
    error?: any,
}

export interface RegisterUserActions {
    type: string
    [item: string]: any
}

export const registerUserInitialState = {
    response: undefined,
    loading: false
}


export const reducer = (newState: RegisterUserState = registerUserInitialState, action: RegisterUserActions) => {
    switch (action.type) {
        case BEGIN_USER_REGISTER:
            return Object.assign({}, newState, {
                loading: true,
            })

        case SUCCESS_USER_REGISTER:
            return Object.assign({}, newState, {
                response: action.data,
                loading: false,
                statusText: 'success',
                status: action.status,
                error: ''
            })

        case ERROR_USER_REGISTER:
            return Object.assign({}, newState, {
                statusText: 'error',
                error: action.message,
                loading: false,
            })
        case CLEAR_USER_REGISTER:
            return registerUserInitialState
        default:
            return newState
    }
}

export const beginUserRegister = () => ({
    type: BEGIN_USER_REGISTER,
})

export const successUserRegister = (response: any) => ({
    type: SUCCESS_USER_REGISTER,
    data: response,
    status: response.status
})

export const errorUserRegister = (error: any) => ({
    type: ERROR_USER_REGISTER,
    message: error.message
})

export const clearUserRegister = () => ({
    type: CLEAR_USER_REGISTER
})

export const registerUser = (dispatch: Dispatch, data: any) => {
    dispatch(beginUserRegister())
    const name = data.name
    const capitalizedName = name.replace(/(^\w{1})|(\s+\w{1})/g, (letter: string) => letter.toUpperCase());
    const email = data.email.toLowerCase()
    const index = email.indexOf('@')

    // temporary password
    let password = `art${new Date().getFullYear()}@`
    if (index === 1) {
        password += email[0].toUpperCase()
    } else {
        password += `${email[0].toUpperCase()}${email[index - 1].toUpperCase()}`
    }

    return Auth.signUp({
        username: email,
        password,
        attributes: {
            email,
            name: capitalizedName,
            'custom:country': data.country,
            'custom:user_type': '1',
            'custom:force_change_pwd': '1'
        }
    }).then((resp: any) => {
        dispatch(successUserRegister(resp.user))
        return resp
    }).catch(err => {
        dispatch(errorUserRegister(err))
        return new Promise<any>((resolve, reject) => { reject(err) })
    })
}

export default reducer