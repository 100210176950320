import React from 'react'
import { useMediaQuery } from 'react-responsive'

export const isMobile = () => useMediaQuery({ query: '(max-width: 500px)' })

const AboutComponent = (props: any) => {
    return (
        <div id='AboutComponent'>
            {isMobile() ? (
                <>
                    <p style={{ margin: 0, padding: 0, marginTop: '3em', fontWeight: 700, fontSize: 18 }}>Building a profitable art collection
                        can be challenging, especially
                        for those new to the art
                        world. At ArtShare,</p>
                    <div className="content">
                        <p style={{ lineHeight: '1.5em', fontSize: 18 }}>
                            we understand these challenges and
                            are committed to simplifying the
                        process of</p>
                        <img src='landingpage/about-photo.png' className='opacity-1' width={150} height={150} />
                    </div>
                    <p style={{ fontWeight: 700, fontSize: 18 }}>simplifying the
                        process of owning
                        art, making it <span style={{ color: '#F69220' }}>affordable</span>, and <span style={{ color: '#F69220' }}>rewarding</span> for people in all walks of life.</p></>
            ) : (
                <div className="content">
                    <p>Building a profitable art collection
                        can be challenging, especially
                        for those new to the art
                        world. At ArtShare,
                        we understand these challenges and
                        are committed to
                        simplifying the
                        process of owning
                        art, making it <span style={{ color: '#F69220' }}>affordable</span>, and <span style={{ color: '#F69220' }}>rewarding</span> for people in all walks of life.</p>
                    <img src='landingpage/about-photo.png' className='opacity-1' />
                </div>
            )}
            <div className="sub-content">
                <p>To address these challenges, we made it our mission to simplify the process of owning art – by making it <strong>accessible</strong>, <strong>affordable</strong>, and <strong>rewarding</strong>. Through crowdfunding, new and young collectors can start participating in art collection, overcoming obstacles such as a lack of knowledge on where to start, uncertainty about which type of art to invest in, limited understanding of artists and different genres, doubts about the
                    authenticity of the artwork, and the unaffordability of art pieces created by masters with a history of price appreciation.</p>
            </div>
            <div className="sub-content">
                <p>More than just a platform, <span style={{ color: '#F69220' }}>ArtShare</span> is designed to create a safe space for the community of art enthusiasts who share a common passion for creativity, self-expression, and the power of art to become part of one’s diversified portfolio.</p>
            </div>
        </div>
    )
}

export default AboutComponent